/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { HotelList } from "../../components/organisms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToHotels } from "../../App/redux/data/hotel/hotelActions";
import { userFirestoreCollection } from "../../App/hooks/userFirestoreCollection";
import {
  deleteHotelInFirestore,
  fetchCitiesFromFirestore,
  fetchHotelsFromFirestore,
} from "../../App/firestore/firestoreService";
import { openModal } from "../../App/redux/data/modals/modalReducer";
import { listenToCities } from "../../App/redux/data/city/cityActions";
import { IQueryConfigArgs } from "../../App/common/typings";

const defaultSorting: IQueryConfigArgs = {
  type: "sorting",
  name: "name",
  order: "asc",
  opr: "asc",
};
const Hotels: React.FC = () => {
  const dataSou = useSelector((state: any) => state.hotels);
  const { cities } = useSelector((state: any) => state.cities);
  const loading = useSelector((state: any) => state.async);
  const [searchHotel, setSearchHotel] = React.useState("");
  const [cityId, setCityId] = React.useState("");
  const [hotelSorting, setHotelSorting] =
    React.useState<IQueryConfigArgs | null>(defaultSorting);
  const dispatch = useDispatch();

  userFirestoreCollection({
    query: () =>
      fetchHotelsFromFirestore([
        hotelSorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: ">=",
              value: searchHotel,
            }
          : null,
        hotelSorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: "<=",
              value: `${searchHotel}\uf8ff`,
            }
          : null,
        {
          type: "filter",
          name: "address.cityId",
          opr: "==",
          value: cityId,
        },
        hotelSorting,
      ]),
    data: listenToHotels,
    deps: [dispatch, searchHotel, cityId, hotelSorting],
  });
  const handelFilterCity = (value: string) => {
    value ? setCityId(value) : setCityId("");
  };
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  const handelSearchHotel = (search: string) => {
    setSearchHotel(search);
  };
  const handelAddHotel = () => {
    dispatch(
      openModal({
        modalType: "AddHotelForm",
        modalProps: { title: "Add Hotel" },
      })
    );
  };
  const handelDeleteHotel = async (id: string) => {
    await deleteHotelInFirestore(id);
  };
  const handelSortingChange = (name: string, order: any) => {
    setHotelSorting({
      type: "sorting",
      opr: order,
      name,
      order,
    });
  };
  return (
    <AdminPage pageTitle="Hotels">
      <HotelList
        loading={loading.loading}
        hotels={dataSou.hotels || []}
        cities={cities}
        filterCity={handelFilterCity}
        searchHotel={handelSearchHotel}
        addHotel={handelAddHotel}
        deleteHotel={handelDeleteHotel}
        sorting={handelSortingChange}
      />
    </AdminPage>
  );
};

export default Hotels;
