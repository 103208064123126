import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import bookingReducer from "../data/booking/bookingReducer";
import categoryReducer from "../data/category/categoryReducer";
import cityReducer from "../data/city/cityReducer";
import hotelReducer from "../data/hotel/hotelReducer";
import packageReducer from "../data/package/packageReducer";
import providerReducer from "../data/provider/providerReducer";
import userReducer from "../data/user/userReducer";
import asyncReducer from "../data/async/asyncReducer";
import modalReducer from "../data/modals/modalReducer";
import authReducer from "../data/auth/authReducer";
const rootReducer = (history: any) =>
  combineReducers({
    bookings: bookingReducer,
    categories: categoryReducer,
    cities: cityReducer,
    hotels: hotelReducer,
    packages: packageReducer,
    providers: providerReducer,
    users: userReducer,
    async: asyncReducer,
    modals: modalReducer,
    auth: authReducer,
    router: connectRouter(history),
  });

export default rootReducer;
