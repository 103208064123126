import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Input } from "../../atoms";
import { BookingContactInformationWrapper } from "./PackageBookingFrontEnd.style";

export const BookingComment = () => {
  const { formatMessage } = useIntl();
  return (
    <BookingContactInformationWrapper>
      <div className="booking-contact-title">
        <FormattedMessage
          description="COMMENTS"
          defaultMessage="COMMENTS"
          id="2G0xHA"
        />
      </div>
      <div className="booking-contact-des">
        <FormattedMessage
          description="COMMENTS description"
          defaultMessage="COMMENTS description"
          id="4PVbIV"
        />{" "}
      </div>
      <Form.Item name="comment">
        <Input.TextArea
          rows={4}
          placeholder={formatMessage({
            defaultMessage: "Comments",
            id: "wCgTu5",
          })}
        />
      </Form.Item>
    </BookingContactInformationWrapper>
  );
};
