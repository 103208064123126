import React from "react";
import { FormattedMessage } from "react-intl";
import { IPackageHotelPublic, IRoomInput } from "../../../App/common/typings";
import { getBookingTotal } from "../../../App/firestore/firestoreService";
import { Button } from "../../atoms";
import { CartTotalBtnWrapper } from "./Cart.style";
interface ICartTotalBtnProps {
  submit: () => void;
  hotels: IPackageHotelPublic[];
  rooms: IRoomInput[];
  loading: boolean;
  luggages: {
    numberOf10KGLuggage: number;
    numberOf20KGLuggage: number;
    price10KGLuggage: number;
    price20KGLuggage: number;
  };
}
export const CartTotalBtn: React.FC<ICartTotalBtnProps> = ({
  submit,
  hotels,
  rooms,
  loading,
  luggages,
}) => {
  const [finalPrice, setFinalPrice] = React.useState(0);
  React.useEffect(() => {
    const total = getBookingTotal(hotels || [], rooms || [], luggages);
    setFinalPrice(total);
  }, [hotels, rooms, luggages]);
  return (
    <CartTotalBtnWrapper>
      <div className="cart-total-total">
        <span>
          {" "}
          <FormattedMessage
            description="Total"
            defaultMessage="Total"
            id="XmkR3j"
          />
        </span>
        <span className="cart-total-total-price">€{finalPrice}</span>
      </div>
      <div style={{ padding: "10px 20px 20px" }}>
        <Button className="cart-total-btn" onClick={submit} loading={loading}>
          {" "}
          <FormattedMessage
            description="Book Now"
            defaultMessage="Book Now"
            id="1ZQWdS"
          />
        </Button>
      </div>
    </CartTotalBtnWrapper>
  );
};
