/* eslint-disable no-console */
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from "../async/asyncReducer";
// import {
//   // fetchCitysFromFirestore,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
import { ICity, IImageGroup } from "../../../common/typings";
import {
  CREATE_CITY,
  UPDATE_CITY,
  DELETE_CITY,
  FETCH_CITIES,
  LISTEN_TO_SELECTED_CITY,
  LISTEN_TO_CITY_IMAGES_GROUPS,
  CLEAR_CITIES,
  CLEAR_SELECTED_CITY,
} from "./cityConstants";

// export function fetchCities() {
//   return async function (dispatch: IDispatch<any>) {
//     dispatch(asyncActionStart());
//     try {
//       // const snapshot = await fetchCitysFromFirestore(
//       //   filter,
//       //   startDate,
//       //   limit,
//       //   lastDocSnapshot
//       // ).get();
//       // const lastVisible = snapshot.docs[snapshot.docs.length - 1];
//       // const moreCitys = snapshot.docs.length >= limit;
//       // const cities = snapshot.docs.map((doc) => dataFromSnapshot(doc));
//       // dispatch({
//       //   type: FETCH_CITIES,
//       //   payload: { cities, moreCitys, lastVisible },
//       // });
//       dispatch(asyncActionFinish());
//     } catch (error) {
//       dispatch(asyncActionError(error));
//     }
//   };
// }

export function listenToCities(cities: ICity[]) {
  return {
    type: FETCH_CITIES,
    payload: cities,
  };
}

export function listenToCityImagesGroups(imagesGroups: IImageGroup[]) {
  return {
    type: LISTEN_TO_CITY_IMAGES_GROUPS,
    payload: imagesGroups,
  };
}

// export function setFilter(value) {
//   return function (dispatch) {
//     dispatch(clearCitys());
//     dispatch({ type: SET_FILTER, payload: value });
//   };
// }

// export function setStartDate(date) {
//   return function (dispatch) {
//     dispatch(clearCitys());
//     dispatch({ type: SET_START_DATE, payload: date });
//   };
// }

export function listenToSelectedCity(city: ICity) {
  return {
    type: LISTEN_TO_SELECTED_CITY,
    payload: city,
  };
}

export function clearSelectedCity() {
  return {
    type: CLEAR_SELECTED_CITY,
  };
}

export function createCity(city: ICity) {
  return {
    type: CREATE_CITY,
    payload: city,
  };
}

export function updateCity(city: ICity) {
  return {
    type: UPDATE_CITY,
    payload: city,
  };
}

export function deleteCity(cityId: string) {
  return {
    type: DELETE_CITY,
    payload: cityId,
  };
}

export function clearCities() {
  return {
    type: CLEAR_CITIES,
  };
}
