import moment from "moment";
import { IBooking } from "../../../common/typings";
import {
  CREATE_BOOKING,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  FETCH_BOOKINGS,
  CLEAR_COMMENTS,
  LISTEN_TO_SELECTED_BOOKING,
  CLEAR_BOOKINGS,
  SET_FILTER,
  SET_START_DATE,
  RETAIN_STATE,
  CLEAR_SELECTED_BOOKING,
} from "./bookingConstants";

const initialState = {
  bookings: [],
  moreBookings: true,
  selectedBooking: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function bookingReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_BOOKING:
      return {
        ...state,
        bookings: [...state.bookings, payload],
      };
    case UPDATE_BOOKING:
      return {
        ...state,
        bookings: [
          ...state.bookings.filter((evt: IBooking) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_BOOKING:
      return {
        ...state,
        bookings: [
          ...state.bookings.filter((evt: IBooking) => evt.id !== payload),
        ],
      };
    case FETCH_BOOKINGS:
      return {
        ...state,
        bookings: payload,
        moreBookings: payload.moreBookings,
        lastVisible: payload.lastVisible,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_BOOKING:
      return {
        ...state,
        selectedBooking: {
          ...payload,
          passengers: payload.passengers.map((passenger: any) => ({
            ...passenger,
            dateOfBirth: passenger.dateOfBirth
              ? moment(passenger.dateOfBirth?.toDate())
              : "",
          })),
          flights:
            payload?.flights?.map((flight: any) => ({
              ...flight,
              departureDate: moment(flight.departureDate.toDate()),
              arrivalDate: moment(flight.arrivalDate.toDate()),
            })) || [],
        },
      };
    case CLEAR_SELECTED_BOOKING:
      return {
        ...state,
        selectedBooking: null,
      };
    case CLEAR_BOOKINGS:
      return {
        ...state,
        bookings: [],
        moreBookings: true,
        lastVisible: null,
      };
    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        moreBookings: true,
        filter: payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreBookings: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
