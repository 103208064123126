import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export interface IPrivateRouteProps {
  component: any;
  path: string;
  [x: string]: any;
}

export const PrivateRoute: React.FC<IPrivateRouteProps> = ({
  component,
  ...rest
}) => {
  const Component = component;
  const { authenticated, prevLocation } = useSelector(
    (state: any) => state.auth
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: prevLocation ? prevLocation : "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
