import { notification, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICity } from "../../../App/common/typings";
import { countriesOptions } from "../../../App/enums";
import {
  listenToCityFromFirestore,
  updateCityInFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { listenToSelectedCity } from "../../../App/redux/data/city/cityActions";
import { Button, Form, Input, Select, Space } from "../../atoms";
import { ItemNotFound, Loader, RichTextArea } from "../../molecules";

// "https://www.mocky.io/v2/5cc8019d300000980a055e76"
export interface ICityDetailsProps {
  cityId: string;
}

export const CityDetails: React.FC<ICityDetailsProps> = ({ cityId }) => {
  const dispatch = useDispatch();
  const { selectedCity } = useSelector((state: any) => state.cities);
  const { loading, error } = useSelector((state: any) => state.async);
  const description = React.useRef("");
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    try {
      const values: ICity = await form.validateFields();
      await updateCityInFirestore(cityId, {
        id: cityId,
        name: values.name,
        code: values.code || "",
        countryCode: values.countryCode || "",
        status: values.status,
        description: description.current || selectedCity?.description || "",
      });
      notification.success({
        message: "Update",
        description: "City Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  userFirestoreDoc({
    query: () => listenToCityFromFirestore(cityId),
    data: listenToSelectedCity,
    deps: [dispatch, cityId],
    shouldExecute: cityId !== "new",
  });
  React.useEffect(() => form.resetFields);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/admin/cities"
        redirectBtnText="Back to Cities"
        itemType="City"
        itemId={cityId}
      />
    );
  }
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={selectedCity}
        onFinish={handleOnFinish}
        autoComplete="off"
      >
        <Form.Item name="status" label="Active" valuePropName="checked">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="countryCode"
          rules={[{ required: true, message: "Missing Country" }]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={countriesOptions}
          ></Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Missing Name" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Code" max={4} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <RichTextArea
            description={description}
            html={selectedCity?.description || ""}
          />
        </Form.Item>
        <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
          <Button
            type="primary"
            // loading={}
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      </Form>
    </div>
  );
};
