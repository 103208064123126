/* eslint-disable no-console */
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from "../async/asyncReducer";
// import {
//   // fetchCategorysFromFirestore,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
import { ICategory } from "../../../common/typings";
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  FETCH_CATEGORIES,
  LISTEN_TO_SELECTED_CATEGORY,
  CLEAR_CATEGORIES,
  CLEAR_SELECTED_CATEGORY,
} from "./categoryConstants";

// export function fetchCategories() {
//   return async function (dispatch: IDispatch<any>) {
//     dispatch(asyncActionStart());
//     try {
//       // const snapshot = await fetchCategorysFromFirestore(
//       //   filter,
//       //   startDate,
//       //   limit,
//       //   lastDocSnapshot
//       // ).get();
//       // const lastVisible = snapshot.docs[snapshot.docs.length - 1];
//       // const moreCategorys = snapshot.docs.length >= limit;
//       // const categories = snapshot.docs.map((doc) => dataFromSnapshot(doc));
//       // dispatch({
//       //   type: FETCH_CATEGORIES,
//       //   payload: { categories, moreCategorys, lastVisible },
//       // });
//       dispatch(asyncActionFinish());
//     } catch (error) {
//       dispatch(asyncActionError(error));
//     }
//   };
// }

export function listenToCategories(categories: ICategory[]) {
  return {
    type: FETCH_CATEGORIES,
    payload: categories,
  };
}

// export function setFilter(value) {
//   return function (dispatch) {
//     dispatch(clearCategorys());
//     dispatch({ type: SET_FILTER, payload: value });
//   };
// }

// export function setStartDate(date) {
//   return function (dispatch) {
//     dispatch(clearCategorys());
//     dispatch({ type: SET_START_DATE, payload: date });
//   };
// }

export function listenToSelectedCategory(category: ICategory) {
  return {
    type: LISTEN_TO_SELECTED_CATEGORY,
    payload: category,
  };
}

export function clearSelectedCategory() {
  return {
    type: CLEAR_SELECTED_CATEGORY,
  };
}

export function createCategory(category: ICategory) {
  return {
    type: CREATE_CATEGORY,
    payload: category,
  };
}

export function updateCategory(category: ICategory) {
  return {
    type: UPDATE_CATEGORY,
    payload: category,
  };
}

export function deleteCategory(categoryId: string) {
  return {
    type: DELETE_CATEGORY,
    payload: categoryId,
  };
}

export function clearCategories() {
  return {
    type: CLEAR_CATEGORIES,
  };
}
