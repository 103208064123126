/* eslint-disable no-console */
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  ICategory,
  ICity,
  IPackageSearchValues,
  ISelectOption,
} from "../../../App/common/typings";
import { getYearMonthsOptions } from "../../../App/common/util/util";
import { Button, Form, Select } from "../../atoms";
import { SelectField } from "../commonFields/SelectField";
import { PackageSearchBoxWrapper } from "./PackageSearchBox.style";
export interface IPackageSearchBoxProps {
  cities: ICity[];
  categories: ICategory[];
}

export const PackageSearchBox: React.FC<IPackageSearchBoxProps> = ({
  cities,
  categories,
}) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = React.useState(false);
  const [dateOptions, setDateOptions] = React.useState<ISelectOption[]>([]);
  const [searchCities, setSearchCities] = React.useState<ISelectOption[]>([]);
  // const [searchCategories, setSearchCategories] = React.useState<
  //   ISelectOption[]
  // >([]);
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const values: IPackageSearchValues = await form.validateFields();
      const searchParams = new URLSearchParams({
        from: `${values.departureCity}`,
        to: `${values.city}`,
        when: `${values.date}`,
        category: `${values.category || "all"}`,
      });
      history.push(`/packages/${searchParams.toString()}`);
      setLoading(false);
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    setDateOptions([
      {
        value: "all",
        label: formatMessage({
          defaultMessage: "Any time",
          id: "wmdZZD",
        }),
      },
      ...getYearMonthsOptions(),
    ]);
  }, [getYearMonthsOptions]);

  React.useEffect(() => {
    const defaultCities = cities.map((city) => ({
      value: city.code,
      label: city.name,
    }));
    setSearchCities([
      {
        value: "all",
        label: formatMessage({
          defaultMessage: "Any Where",
          id: "d0ml+9",
        }),
      },
      ...defaultCities,
    ]);
  }, [cities]);
  // React.useEffect(() => {
  //   const defaultCategories = categories.map((category) => ({
  //     value: category.code,
  //     label: category.name,
  //   }));
  //   setSearchCategories([
  //     {
  //       value: "all",
  //       label: formatMessage({
  //         defaultMessage: "Any",
  //         id: "ToO3/h",
  //       }),
  //     },
  //     ...defaultCategories,
  //   ]);
  // }, [categories]);
  return (
    <PackageSearchBoxWrapper>
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          label={formatMessage({
            defaultMessage: "FROM",
            id: "upSHEn",
          })}
          name="departureCity"
          fieldKey="departureCity"
          initialValue="all"
        >
          <Select
            placeholder={formatMessage({
              defaultMessage: "FROM",
              id: "upSHEn",
            })}
          >
            <Select.Option value="all">
              <FormattedMessage
                description="From any where"
                defaultMessage="From any where"
                id="/jZ2rI"
              />
            </Select.Option>
            <Select.Option value="LCA">Λάρνακα</Select.Option>
            <Select.Option value="PFO">Πάφος</Select.Option>
          </Select>
        </Form.Item>

        <SelectField
          label={formatMessage({
            defaultMessage: "To",
            id: "9j3hXO",
          })}
          name="city"
          initialValue="all"
          options={searchCities}
          showSearch={false}
        />
        <SelectField
          label={formatMessage({
            defaultMessage: "When?",
            id: "b2yUQk",
          })}
          name="date"
          options={dateOptions}
          initialValue="all"
          showSearch={false}
        />
        {/* <SelectField
          label={formatMessage({
            defaultMessage: "Category",
            id: "ccXLVi",
          })}
          name="category"
          initialValue="all"
          options={searchCategories}
          showSearch={false}
        /> */}
        <Button
          loading={loading}
          onClick={handleSubmit}
          className="search-button"
        >
          {" "}
          <SearchOutlined />{" "}
          <FormattedMessage
            description="Search"
            defaultMessage="Search"
            id="2NXYYu"
          />
        </Button>
      </Form>
    </PackageSearchBoxWrapper>
  );
};
