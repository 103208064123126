import React from "react";
import { IMatch } from "../../../App/common/typings";
import { PackageBookingFrontEnd } from "../../../components/organisms";
interface IMatchParams {
  id: string;
}
export interface IPackageBookingProps {
  match: IMatch<IMatchParams>;
}
const PackageBooking: React.FC<IPackageBookingProps> = ({ match }) => {
  const bookingId = match.params.id;
  return (
    <div>
      <PackageBookingFrontEnd bookingId={bookingId} />
    </div>
  );
};

export default PackageBooking;
