/* eslint-disable react/display-name */
/* eslint-disable no-console */
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React from "react";
import { ISelectOption } from "../../../App/common/typings";
import { Checkbox } from "../../atoms";
import { CheckboxGroupWrapper } from "./CheckboxGroup.style";

interface ICheckboxGroupProps {
  defaultCheckedList: CheckboxValueType[];
  plainOptions: ISelectOption[];
  checkAllName?: string;
  onChange: (list: CheckboxValueType[]) => void;
}

const CheckboxGroupPer: React.FC<ICheckboxGroupProps> = ({
  defaultCheckedList,
  plainOptions,
  checkAllName,
  onChange,
}) => {
  const [checkedList, setCheckedList] = React.useState<CheckboxValueType[]>([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);
  const oldCheckedList = React.useRef<CheckboxValueType[]>();
  const handelOnChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
    onChange(list.length === plainOptions.length ? ["all"] : list);
  };

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions.map((v) => v.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    onChange([e.target.checked ? "all" : "none"]);
  };
  React.useEffect(() => {
    if (defaultCheckedList !== oldCheckedList.current) {
      if (defaultCheckedList[0] === "all") {
        setCheckAll(true);
        setIndeterminate(false);
        setCheckedList(plainOptions.map((v) => v.value));
        oldCheckedList.current = defaultCheckedList;
      } else {
        setCheckedList(defaultCheckedList);
        oldCheckedList.current = defaultCheckedList;
      }
    }
    // return () => {
    //   setCheckedList([]);
    // };
  }, [defaultCheckedList]);
  return (
    <CheckboxGroupWrapper>
      {checkAllName && (
        <div className="checkbox-group-all">
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            {checkAllName}
          </Checkbox>
        </div>
      )}
      <Checkbox.Group
        options={plainOptions}
        value={checkedList}
        onChange={handelOnChange}
      />
    </CheckboxGroupWrapper>
  );
};
export const CheckboxGroup = React.memo(
  CheckboxGroupPer,
  (prevProps, nextProps) => {
    if (prevProps.defaultCheckedList !== nextProps.defaultCheckedList) {
      return true;
    }
    return false;
  }
);
