/* eslint-disable no-console */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import moment from "moment";
import React from "react";
import { airlines, airlinesOptions, airportsOptions } from "../../../App/enums";
import { Button, DatePicker, Form, Input, Space } from "../../atoms";
import { SelectField } from "../../molecules";
import { FlightsFieldWrapper } from "./FlightsField.style";

export interface IFlightsFieldProps {
  name: string;
  form: any;
}
interface IDepartureDateMap {
  [key: number]: moment.Moment;
}
export const FlightsField: React.FC<IFlightsFieldProps> = ({
  name: itemName,
  form,
}) => {
  const [departureDate, setDepartureDate] = React.useState<IDepartureDateMap>(
    {}
  );
  const handelDepartureDateChange =
    (field: number) => (value: moment.Moment) => {
      departureDate[field] = value;
      setDepartureDate({ ...departureDate });
    };
  return (
    <FlightsFieldWrapper>
      <Form.List name={itemName} initialValue={[{}, {}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key} className="flights-field-leg">
                <Space align="baseline" className="flights-field-space">
                  <SelectField
                    name={[name, "departureAirportCode"]}
                    placeholder="Departure Airport"
                    label={index === 0 ? "Departure Airport" : ""}
                    rules={[
                      {
                        required: true,
                        message: "Missing Departure Airport",
                      },
                    ]}
                    options={airportsOptions}
                  />
                  <Form.Item
                    label={index === 0 ? "Departure Date Time" : ""}
                    name={[name, "departureDate"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Departure Date Time",
                      },
                    ]}
                  >
                    <DatePicker
                      showTime
                      placeholder="Select Departure Date/Time"
                      format="DD/MM/yyyy HH:mm"
                      minuteStep={5}
                      style={{ width: "100%" }}
                      disabledDate={(current) => current <= moment()}
                      onSelect={handelDepartureDateChange(name)}
                    />
                  </Form.Item>
                  <SelectField
                    placeholder="Arrival Airport"
                    label={index === 0 ? "Arrival Airport" : ""}
                    name={[name, "arrivalAirportCode"]}
                    rules={[
                      { required: true, message: "Missing ArrivalAirport" },
                    ]}
                    options={airportsOptions}
                  />

                  <Form.Item
                    label={index === 0 ? "Arrival Date Time" : ""}
                    name={[name, "arrivalDate"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Arrival Date Time",
                      },
                    ]}
                  >
                    <DatePicker
                      showTime
                      placeholder="Select Arrival Date/Time"
                      format="DD/MM/yyyy HH:mm"
                      minuteStep={5}
                      style={{ width: "100%" }}
                      disabledDate={(current) =>
                        current <= moment() || current < departureDate[name]
                      }
                    />
                  </Form.Item>

                  <SelectField
                    placeholder="Airline"
                    label={index === 0 ? "Airline" : ""}
                    name={[name, "airlineCode"]}
                    rules={[{ required: true, message: "Missing Airline" }]}
                    options={airlinesOptions}
                  />
                  <Form.Item
                    name={[name, "flightNumber"]}
                    label={index === 0 ? "Flight Number" : ""}
                    rules={[
                      {
                        required: true,
                        message: "Missing Flight Number",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        airlines[form.getFieldValue([name, "airlineCode"])]
                          ?.value || ""
                      }
                      placeholder="Flight Number"
                    />
                  </Form.Item>
                </Space>
                <div className="flights-field-action">
                  <Popconfirm
                    placement="top"
                    title="Are you sure？"
                    onConfirm={() => remove(name)}
                    disabled={fields.length <= 2}
                  >
                    <MinusCircleOutlined
                      style={{ color: fields.length <= 2 ? "#ccc" : "red" }}
                    />
                  </Popconfirm>
                </div>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add Flight Leg
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </FlightsFieldWrapper>
  );
};
