/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { find } from "lodash";
import moment from "moment";
import cuid from "cuid";
import { Switch } from "antd";
import { packageServiceOptions, partnerTypeOptions } from "../../../App/enums";
import { ModalWrapper } from "../../../App/common/modals/ModalWrapper";
import {
  Alert,
  Checkbox,
  Form,
  Input,
  DatePicker,
  Divider,
  Space,
} from "../../atoms";
import {
  IDestination,
  IDestinationInput,
  IPackageInput,
} from "../../../App/common/typings";
import { closeModal } from "../../../App/redux/data/modals/modalReducer";
import {
  addPackageToFirestore,
  fetchCitiesFromFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import { listenToCities } from "../../../App/redux/data/city/cityActions";
import { DestinationsField, SelectField } from "../../molecules";

export interface IPackageData extends IPackageInput {
  departureDateInput: moment.Moment;
  pasydy?: boolean;
}

export const AddPackageForm: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { cities } = useSelector((state: any) => state.cities);
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "sorting",
          name: "name",
          order: "asc",
          opr: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  const handleSubmit = async () => {
    try {
      const values: IPackageData = await form.validateFields();
      const city = find(cities, ["id", values.departureCityId]);
      const destinations =
        values.destinations?.map(
          (destination: IDestinationInput, index): IDestination => {
            const destinationCity = find(cities, ["id", destination.cityId]);
            const checkInDate =
              index === 0
                ? values.departureDateInput
                : values?.destinations[index - 1]?.checkOutDate;
            return {
              city: destinationCity,
              cityId: destination.cityId,
              checkInDate: moment(checkInDate).format("YYYY-MM-DD"),
              checkOutDate: moment(destination.checkOutDate).format(
                "YYYY-MM-DD"
              ),
              hotels: [],
              id: cuid(),
            };
          }
        ) || [];
      setLoading(true);
      const travelPackage = await addPackageToFirestore({
        name: values.name,
        serviceInclude: values.serviceInclude,
        departureDate: values.departureDateInput.format("YYYY-MM-DD"),
        departureCity: city,
        departureCityId: values.departureCityId,
        destinations,
        sellOnline: true,
        status: false,
        partnerId: values.partnerId || [],
        onlyPartner: values.onlyPartner || false,
      });
      setLoading(false);
      dispatch(closeModal());
      history.push(`/admin/package/${travelPackage.id}`);
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        initialValues={{ destinations: [{}], onlyPartner: !!props.partnerId }}
      >
        <Form.Item
          name="name"
          label="Package Name"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>

        <>
          <Form.Item name="partnerId" label="Partners">
            <Checkbox.Group options={partnerTypeOptions} />
          </Form.Item>
          <Form.Item
            name="onlyPartner"
            label="Only Partner"
            valuePropName="checked"
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </>

        <Form.Item
          name="serviceInclude"
          label="Package Includes"
          rules={[{ required: true, message: "Missing Package Includes" }]}
        >
          <Checkbox.Group options={packageServiceOptions} />
        </Form.Item>
        <Divider>Destinations</Divider>
        <div className="package-details-destination">
          <Space className="full-width">
            <Form.Item
              label="Departure"
              name="departureDateInput"
              rules={[{ required: true, message: "Missing Departure" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                format={"DD/MM/YYYY"}
                onChange={(current) =>
                  form.setFieldsValue({ currentDepartureDate: current })
                }
                disabledDate={(current) => current <= moment()}
              />
            </Form.Item>
            <SelectField
              label="Departure City"
              name="departureCityId"
              rules={[{ required: true, message: "Missing Departure City" }]}
              options={cities.map(({ name, id }: any) => ({
                value: id,
                label: name,
              }))}
            />
          </Space>
        </div>
        <DestinationsField cities={cities} form={form} />
      </Form>
    </ModalWrapper>
  );
};
