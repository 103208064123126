/* eslint-disable no-console */
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from "../async/asyncReducer";
// import {
//   // fetchBookingsFromFirestore,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
import { IBooking } from "../../../common/typings";
import {
  CREATE_BOOKING,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  FETCH_BOOKINGS,
  LISTEN_TO_SELECTED_BOOKING,
  CLEAR_BOOKINGS,
  CLEAR_SELECTED_BOOKING,
} from "./bookingConstants";

// export function fetchBookings() {
//   return async function (dispatch: IDispatch<any>) {
//     dispatch(asyncActionStart());
//     try {
//       // const snapshot = await fetchBookingsFromFirestore(
//       //   filter,
//       //   startDate,
//       //   limit,
//       //   lastDocSnapshot
//       // ).get();
//       // const lastVisible = snapshot.docs[snapshot.docs.length - 1];
//       // const moreBookings = snapshot.docs.length >= limit;
//       // const bookings = snapshot.docs.map((doc) => dataFromSnapshot(doc));
//       // dispatch({
//       //   type: FETCH_BOOKINGS,
//       //   payload: { bookings, moreBookings, lastVisible },
//       // });
//       dispatch(asyncActionFinish());
//     } catch (error) {
//       dispatch(asyncActionError(error));
//     }
//   };
// }

export function listenToBookings(bookings: IBooking[]) {
  return {
    type: FETCH_BOOKINGS,
    payload: bookings,
  };
}

// export function setFilter(value) {
//   return function (dispatch) {
//     dispatch(clearBookings());
//     dispatch({ type: SET_FILTER, payload: value });
//   };
// }

// export function setStartDate(date) {
//   return function (dispatch) {
//     dispatch(clearBookings());
//     dispatch({ type: SET_START_DATE, payload: date });
//   };
// }

export function listenToSelectedBooking(booking: IBooking) {
  return {
    type: LISTEN_TO_SELECTED_BOOKING,
    payload: booking,
  };
}

export function clearSelectedBooking() {
  return {
    type: CLEAR_SELECTED_BOOKING,
  };
}

export function createBooking(booking: IBooking) {
  return {
    type: CREATE_BOOKING,
    payload: booking,
  };
}

export function updateBooking(booking: IBooking) {
  return {
    type: UPDATE_BOOKING,
    payload: booking,
  };
}

export function deleteBooking(bookingId: string) {
  return {
    type: DELETE_BOOKING,
    payload: bookingId,
  };
}

export function clearBookings() {
  return {
    type: CLEAR_BOOKINGS,
  };
}
