import { pick, values } from "lodash";
import { ICountry } from "../common/typings";

/* eslint-disable prettier/prettier */
interface ICountryMap { [key: string]: ICountry; }
export const countries: ICountryMap = {
  AL: {value: 'AL', label: 'Αλβανία', callingCode: '355', alpha3Code: 'ALB'},
 DZ: {value: 'DZ', label: 'Αλγερία', callingCode: '213', alpha3Code: 'DZA'},
 AQ: {value: 'AQ', label: 'Ανταρτική', callingCode: '672', alpha3Code: 'ATA'},
  AR: {value: 'AR', label: 'Αργεντινή', callingCode: '54', alpha3Code: 'ARG'},
 AM: {value: 'AM', label: 'Αρμενία', callingCode: '374', alpha3Code: 'ARM'},
 AW: {value: 'AW', label: 'Αρούμπα', callingCode: '297', alpha3Code: 'ABW'},
 AU: {value: 'AU', label: 'Αυστραλία', callingCode: '61', alpha3Code: 'AUS'},
 AT: {value: 'AT', label: 'Αυστρία', callingCode: '43', alpha3Code: 'AUT'},
 AZ: {value: 'AZ', label: 'Αζερμπαϊτζαν', callingCode: '994', alpha3Code: 'AZE'},
 BS: {value: 'BS', label: 'Μπαχάμες', callingCode: '1242', alpha3Code: 'BHS'},
 BH: {value: 'BH', label: 'Μπαχρέϊν', callingCode: '973', alpha3Code: 'BHR'},
  BB: {value: 'BB', label: 'Νήσοι Μπαρμπεϊτος', callingCode: '1246', alpha3Code: 'BRB'},
 BY: {value: 'BY', label: 'Λευκορωσία', callingCode: '375', alpha3Code: 'BLR'},
 BE: {value: 'BE', label: 'Βέλγιο', callingCode: '32', alpha3Code: 'BEL'},
  BO: {value: 'BO', label: 'Βολιβία', callingCode: '591', alpha3Code: 'BOL'},
 BA: {value: 'BA', label: 'Βοσνία και Ερζεγοβίνη', callingCode: '387', alpha3Code: 'BIH'},
  BR: {value: 'BR', label: 'Βραζιλία', callingCode: '55', alpha3Code: 'BRA'},
  BG: {value: 'BG', label: 'Βουλγαρία', callingCode: '359', alpha3Code: 'BGR'},
 CM: {value: 'CM', label: 'Καμερούν', callingCode: '237', alpha3Code: 'CMR'},
 CA: {value: 'CA', label: 'Καναδάς', callingCode: '1', alpha3Code: 'CAN'},
 KY: {value: 'KY', label: 'Νησιά Κέιμαν', callingCode: ' 345', alpha3Code: 'CYM'},
 CL: {value: 'CL', label: 'Χιλή', callingCode: '56', alpha3Code: 'CHL'},
 CN: {value: 'CN', label: 'Κίνα', callingCode: '86', alpha3Code: 'CHN'},
  CO: {value: 'CO', label: 'Κολομβία', callingCode: '57', alpha3Code: 'COL'},
  CR: {value: 'CR', label: 'Κόστα Ρίκα', callingCode: '506', alpha3Code: 'CRI'},
 HR: {value: 'HR', label: 'Κροατία', callingCode: '385', alpha3Code: 'HRV'},
 CU: {value: 'CU', label: 'Κούβα', callingCode: '53', alpha3Code: 'CUB'},
 CY: {value: 'CY', label: 'Κύπρος', callingCode: '357', alpha3Code: 'CYP'},
 CZ: {value: 'CZ', label: 'Τσεχία', callingCode: '420', alpha3Code: 'CZE'},
 DK: {value: 'DK', label: 'Δανία', callingCode: '45', alpha3Code: 'DNK'},
  DO: {value: 'DO', label: 'Δομινικανή Δημοκρατία', callingCode: '1849', alpha3Code: 'DOM'},
 EC: {value: 'EC', label: 'Εκουαδόρ', callingCode: '593', alpha3Code: 'ECU'},
 EG: {value: 'EG', label: 'Αίγυπτος', callingCode: '20', alpha3Code: 'EGY'},
 SV: {value: 'SV', label: 'Ελ Σαλβαδόρ', callingCode: '503', alpha3Code: 'SLV'},
  EE: {value: 'EE', label: 'Εσθονία', callingCode: '372', alpha3Code: 'EST'},
 ET: {value: 'ET', label: 'Αιθιοποία', callingCode: '251', alpha3Code: 'ETH'},
  FO: {value: 'FO', label: 'Νήσοι Φαρόε', callingCode: '298', alpha3Code: 'FRO'},
 FJ: {value: 'FJ', label: 'Νήσοι Φίτζι', callingCode: '679', alpha3Code: 'FJI'},
 FI: {value: 'FI', label: 'Φινλανδία', callingCode: '358', alpha3Code: 'FIN'},
 FR: {value: 'FR', label: 'Γαλλία', callingCode: '33', alpha3Code: 'FRA'},
  PF: {value: 'PF', label: 'Γαλλική Πολυνησία', callingCode: '689', alpha3Code: 'PYF'},
  GE: {value: 'GE', label: 'Γεωργία', callingCode: '995', alpha3Code: 'GEO'},
 DE: {value: 'DE', label: 'Γερμανία', callingCode: '49', alpha3Code: 'DEU'},
 GH: {value: 'GH', label: 'Γκάνα', callingCode: '233', alpha3Code: 'GHA'},
 GI: {value: 'GI', label: 'Γιβραλτάρ', callingCode: '350', alpha3Code: 'GIB'},
 GR: {value: 'GR', label: 'Ελλάδα', callingCode: '30', alpha3Code: 'GRC'},
 HK: {value: 'HK', label: 'Χονγκ Κονγκ', callingCode: '852', alpha3Code: 'HKG'},
 HU: {value: 'HU', label: 'Ουγγαρία', callingCode: '36', alpha3Code: 'HUN'},
 IS: {value: 'IS', label: 'Ισλανδία', callingCode: '354', alpha3Code: 'ISL'},
 IN: {value: 'IN', label: 'Ινδία', callingCode: '91', alpha3Code: 'IND'},
 ID: {value: 'ID', label: 'Ινδονησία', callingCode: '62', alpha3Code: 'IDN'},
 IE: {value: 'IE', label: 'Ιρλανδία', callingCode: '353', alpha3Code: 'IRL'},
  IL: {value: 'IL', label: 'Ισραήλ', callingCode: '972', alpha3Code: 'ISR'},
 IT: {value: 'IT', label: 'Ιταλία', callingCode: '39', alpha3Code: 'ITA'},
 JM: {value: 'JM', label: 'Τζαμάϊκα', callingCode: '1876', alpha3Code: 'JAM'},
 JP: {value: 'JP', label: 'Ιαπωνία', callingCode: '81', alpha3Code: 'JPN'},
 JO: {value: 'JO', label: 'Ιορδανία', callingCode: '962', alpha3Code: 'JOR'},
 KZ: {value: 'KZ', label: 'Καζακστάν', callingCode: '7', alpha3Code: 'KAZ'},
 KE: {value: 'KE', label: 'Κένυα', callingCode: '254', alpha3Code: 'KEN'},
  KR: {value: 'KR', label: 'Βόρεια Κορέα', callingCode: '82', alpha3Code: 'PRK'},
  KW: {value: 'KW', label: 'Κουβέϊτt', callingCode: '965', alpha3Code: 'KWT'},
  LV: {value: 'LV', label: 'Λετονία', callingCode: '371', alpha3Code: 'LVA'},
 LB: {value: 'LB', label: 'Λίβανο', callingCode: '961', alpha3Code: 'LBN'},
 LI: {value: 'LI', label: 'Λίχτεσταϊν', callingCode: '423', alpha3Code: 'LIE'},
 LT: {value: 'LT', label: 'Λιθουανία', callingCode: '370', alpha3Code: 'LTU'},
 LU: {value: 'LU', label: 'Λουξεμβούργο', callingCode: '352', alpha3Code: 'LUX'},
 MO: {value: 'MO', label: 'Μακάο', callingCode: '853', alpha3Code: 'MAC'},
 MK: {value: 'MK', label: 'Μακεδονία', callingCode: '389', alpha3Code: 'MKD'},
 MG: {value: 'MG', label: 'Μαδαγασκάρη', callingCode: '261', alpha3Code: 'MDG'},
 MW: {value: 'MW', label: 'Μαλάουι', callingCode: '265', alpha3Code: 'MWI'},
 MY: {value: 'MY', label: 'Μαλαισία', callingCode: '60', alpha3Code: 'MYS'},
 MV: {value: 'MV', label: 'Μαλδίβες', callingCode: '960', alpha3Code: 'MDV'},
 ML: {value: 'ML', label: 'Μάλι', callingCode: '223', alpha3Code: 'MLI'},
 MT: {value: 'MT', label: 'Μάλτα', callingCode: '356', alpha3Code: 'MLT'},
 MU: {value: 'MU', label: 'Μαυρίκιος', callingCode: '230', alpha3Code: 'MUS'},
 MX: {value: 'MX', label: 'Μεξικό', callingCode: '52', alpha3Code: 'MEX'},
 MD: {value: 'MD', label: 'Μολδαβία', callingCode: '373', alpha3Code: 'MDA'},
 MC: {value: 'MC', label: 'Μονακό', callingCode: '377', alpha3Code: 'MCO'},
 ME: {value: 'ME', label: 'Μοντενέγκρο', callingCode: '382', alpha3Code: 'MNE'},
 MA: {value: 'MA', label: 'Μαρόκο', callingCode: '212', alpha3Code: 'MAR'},
 MM: {value: 'MM', label: 'Μιανμάρ', callingCode: '95', alpha3Code: 'MMR'},
  NP: {value: 'NP', label: 'Νεπάλ', callingCode: '977', alpha3Code: 'NPL'},
 NL: {value: 'NL', label: 'Ολλανδία', callingCode: '31', alpha3Code: 'NLD'},
 NZ: {value: 'NZ', label: 'Νέα Ζηλανδία', callingCode: '64', alpha3Code: 'NZL'},
 NO: {value: 'NO', label: 'Νορβηγία', callingCode: '47', alpha3Code: 'NOR'},
  PK: {value: 'PK', label: 'Πακιστάν', callingCode: '92', alpha3Code: 'PAK'},
 PA: {value: 'PA', label: 'Παναμάς', callingCode: '507', alpha3Code: 'PAN'},
  PY: {value: 'PY', label: 'Παραγουάη', callingCode: '595', alpha3Code: 'PRY'},
 PE: {value: 'PE', label: 'Περού', callingCode: '51', alpha3Code: 'PER'},
 PH: {value: 'PH', label: 'Φιλιππίνες', callingCode: '63', alpha3Code: 'PHL'},
  PL: {value: 'PL', label: 'Πολωνία', callingCode: '48', alpha3Code: 'POL'},
 PT: {value: 'PT', label: 'Πορτογαλλία', callingCode: '351', alpha3Code: 'PRT'},
 PR: {value: 'PR', label: 'Πουέρτο Ρίκο', callingCode: '1939', alpha3Code: 'PRI'},
 QA: {value: 'QA', label: 'Κατάρ', callingCode: '974', alpha3Code: 'QAT'},
  RO: {value: 'RO', label: 'Ρουμανία', callingCode: '40', alpha3Code: 'ROU'},
 RU: {value: 'RU', label: 'Ρωσσία', callingCode: '7', alpha3Code: 'RUS'},
  SM: {value: 'SM', label: 'Σαν Μαρίνο', callingCode: '378', alpha3Code: 'SMR'},
  SA: {value: 'SA', label: 'Σαουδική Αραβία', callingCode: '966', alpha3Code: 'SAU'},
 RS: {value: 'RS', label: 'Σερβία', callingCode: '381', alpha3Code: 'SRB'},
 SC: {value: 'SC', label: 'Σεϋχέλλες', callingCode: '248', alpha3Code: 'SYC'},
 SG: {value: 'SG', label: 'Σιγκαπούρη', callingCode: '65', alpha3Code: 'SGP'},
 SK: {value: 'SK', label: 'Σλοβακία', callingCode: '421', alpha3Code: 'SVK'},
 SI: {value: 'SI', label: 'Σλοβενία', callingCode: '386', alpha3Code: 'SVN'},
  ZA: {value: 'ZA', label: 'Νότια Αφρική', callingCode: '27', alpha3Code: 'ZAF'},
  ES: {value: 'ES', label: 'Ισπανία', callingCode: '34', alpha3Code: 'ESP'},
 LK: {value: 'LK', label: 'Σρι Λάνκα', callingCode: '94', alpha3Code: 'LKA'},
 SE: {value: 'SE', label: 'Σουηδία', callingCode: '46', alpha3Code: 'SWE'},
 CH: {value: 'CH', label: 'Ελβετία', callingCode: '41', alpha3Code: 'CHE'},
 SY: {value: 'SY', label: 'Συρία', callingCode: '963', alpha3Code: 'SYR'},
  TZ: {value: 'TZ', label: 'Τανζανία', callingCode: '255', alpha3Code: 'TZA'},
 TH: {value: 'TH', label: 'Ταϊλάνδη', callingCode: '66', alpha3Code: 'THA'},
  TN: {value: 'TN', label: 'Τυνησία', callingCode: '216', alpha3Code: 'TUN'},
 TR: {value: 'TR', label: 'Τουρκία', callingCode: '90', alpha3Code: 'TUR'},
  UG: {value: 'UG', label: 'Ουγκάντα', callingCode: '256', alpha3Code: 'UGA'},
 UA: {value: 'UA', label: 'Ουκρανία', callingCode: '380', alpha3Code: 'UKR'},
 AE: {value: 'AE', label: 'Ηνωμένα Αραβικά Εμιράτα', callingCode: '971', alpha3Code: 'ARE'},
 GB: {value: 'GB', label: 'Ηνωμένο Βασίλειο', callingCode: '44', alpha3Code: 'GBR'},
 US: {value: 'US', label: 'Ηνωμένες Πολιτείες Αμερικής', callingCode: '1', alpha3Code: 'USA'},
  UY: {value: 'UY', label: 'Ουρουγουάη', callingCode: '598', alpha3Code: 'URY'},
  VE: {value: 'VE', label: 'Βενεζουέλα', callingCode: '58', alpha3Code: 'VEN'},
  VN: {value: 'VN', label: 'Βιετναμ', callingCode: '84', alpha3Code: 'VNM'},

}

export const countriesOptions = values(countries).map(country => pick(country, ['value', 'label']));