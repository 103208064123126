import { values } from "lodash";
interface IPackageIncludesMap {
  [key: string]: { label: string; value: string };
}
export const sendToClientStatusOptions: IPackageIncludesMap = {
  NEW: { label: "New", value: "NEW" },
  IN_PROGRESS: { label: "In Progress", value: "IN_PROGRESS" },
  ISSUE: { label: "Issue", value: "ISSUE" },
  FINISHED: { label: "Finished", value: "FINISHED" },
  SEND_TO_CLIENTt: { label: "Send to Client", value: "SEND_TO_CLIENT" },
};

export const sendToClientOptions = values(sendToClientStatusOptions);
