export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const LISTEN_TO_CATEGORY_CHAT = "LISTEN_TO_CATEGORY_CHAT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_CATEGORY = "LISTEN_TO_SELECTED_CATEGORY";
export const CLEAR_SELECTED_CATEGORY = "CLEAR_SELECTED_CATEGORY";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";
export const SET_FILTER = "SET_FILTER";
export const SET_START_DATE = "SET_START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
