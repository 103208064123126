import { Result } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ICity, ILuggage, IPolicy } from "../../../App/common/typings";
import { listenToBookingFromFirestore } from "../../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { listenToSelectedBooking } from "../../../App/redux/data/booking/bookingActions";
import {
  Cart,
  ItemNotFound,
  Loader,
  PackageInfo,
  Passengers,
} from "../../molecules";
import { PackageBookingConfirmFrontendWrapper } from "./PackageBookingConfirmFrontend.style";

const defaultLuggages: ILuggage = {
  numberOf10KGLuggage: 0,
  numberOf20KGLuggage: 0,
  price20KGLuggage: 0,
  price10KGLuggage: 0,
};
export interface IPackageBProps {
  bookingId: string;
}
export const PackageBookingConfirmFrontend: React.FC<IPackageBProps> = ({
  bookingId,
}) => {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { loading, error } = useSelector((state: any) => state.async);

  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: bookingId !== "new",
  });
  const getCitiesNames = (cities: ICity[]) =>
    cities.map((c: ICity) => c.name).join(", ");
  const getServiceIncludes = (serviceIncludes: string[]) =>
    serviceIncludes.join(", ");

  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/packages"
        redirectBtnText="Back to packages"
        itemType="Booking"
        itemId={bookingId}
      />
    );
  }
  return (
    <PackageBookingConfirmFrontendWrapper>
      <div className="booking-confirm-body">
        <Result
          status="success"
          title={
            <FormattedMessage
              description="Successfully"
              defaultMessage="Successfully"
              id="VNBmrR"
            />
          }
          subTitle={
            <FormattedMessage
              description="An email with your booking details will arrive shortly"
              defaultMessage="An email with your booking details will arrive shortly"
              id="H5zQ4s"
            />
          }
        />
        <PackageInfo
          title={selectedBooking?.travelPackage?.name || "-"}
          departureCity={selectedBooking?.travelPackage.departureCity || {}}
          departureDate={selectedBooking?.travelPackage.departureDate || ""}
          returnDate={selectedBooking?.travelPackage.returnDate || ""}
          serviceInclude={selectedBooking?.travelPackage.serviceInclude || []}
          categories={selectedBooking?.travelPackage.categories || []}
        />
        <div className="booking-confirm-image">
          <img src={selectedBooking?.travelPackage?.image?.url} alt="" />
        </div>
        <div className="booking-confirm-total">
          <FormattedMessage
            description="Total Package Price Paid "
            defaultMessage="Total Package Price Paid"
            id="u+QVfG"
          />{" "}
          <span>€{selectedBooking?.total}</span>
        </div>
        <div className="booking-confirm-booking">
          <div className="booking-confirm-message">
            <div className="booking-confirm-message-title">
              <FormattedMessage
                description="Dear "
                defaultMessage="Dear "
                id="rkLJUY"
              />{" "}
              {selectedBooking?.customer?.name}!
            </div>
            <div>
              <div className="booking-confirm-message-des">
                <FormattedMessage
                  description="Thank you for choose Poupas for Holidays for your next trip to"
                  defaultMessage="Thank you for choose Poupas for Holidays for your next trip to"
                  id="tw9E0L"
                />{" "}
                <span className="booking-confirm-message-title">
                  {getCitiesNames(
                    selectedBooking?.travelPackage.destinationCities || []
                  )}{" "}
                </span>
                .
              </div>
              <div className="booking-confirm-message-des">
                <FormattedMessage
                  description="Please note that this only the proof of payment to our office
                  and not the confirmation of your booking. We will send you
                  another email within 24 working hours that will include all the
                  necessary documents for your trip:"
                  defaultMessage="Please note that this only the proof of payment to our office
                  and not the confirmation of your booking. We will send you
                  another email within 24 working hours that will include all the
                  necessary documents for your trip:"
                  id="fdUYtT"
                />

                <span className="booking-confirm-message-title">
                  {getServiceIncludes(
                    selectedBooking?.travelPackage.serviceIncludes || []
                  )}
                </span>
              </div>
              <div className="booking-confirm-message-des">
                <FormattedMessage
                  description="In the meantime we mention below the most important
                  sightseeing’s of the city in order to help you organize your
                  trip better. * You can also see below your booking details.
                  Please confirm that everything is correct in order to avoid not
                  necessary payments for correction on names etc."
                  defaultMessage="In the meantime we mention below the most important
                  sightseeing’s of the city in order to help you organize your
                  trip better. * You can also see below your booking details.
                  Please confirm that everything is correct in order to avoid not
                  necessary payments for correction on names etc."
                  id="i65EcQ"
                />
              </div>

              <div>
                {selectedBooking?.travelPackage.destinationCities.map(
                  (dis: ICity) => (
                    <div key={dis.id}>
                      <div className="booking-confirm-message-title">
                        {dis.name}
                      </div>
                      <div
                        key={dis.id}
                        dangerouslySetInnerHTML={{
                          __html: dis?.description || "",
                        }}
                        className="booking-confirm-message-des"
                      />
                    </div>
                  )
                )}
              </div>
              <div>
                {selectedBooking?.travelPackage.policies.map((dis: IPolicy) => (
                  <div key={dis.id}>
                    <div className="booking-confirm-message-title">
                      {dis.name}
                    </div>
                    <div
                      key={dis.id}
                      dangerouslySetInnerHTML={{
                        __html: dis?.description || "",
                      }}
                      className="booking-confirm-message-des"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <Passengers passengers={selectedBooking?.passengers || []} />
          <Cart
            hotels={selectedBooking?.packageHotels || []}
            rooms={selectedBooking?.rooms || []}
            loading={false}
            flights={selectedBooking?.flights || []}
            isTabletOrMobile={isTabletOrMobile}
            luggages={selectedBooking?.luggages || defaultLuggages}
          />
        </div>
      </div>
    </PackageBookingConfirmFrontendWrapper>
  );
};
