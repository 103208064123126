import styled from "styled-components";

// theme is now fully typed
export const PackageDetailsWrapper = styled.div`
  .package-details {
    &-destination {
      display: grid;
      grid-template-columns: 1fr 50px;
      justify-content: center;
      align-items: center;
    }
    &-space {
      display: flex;
      .ant-space {
        &-item {
          flex: 1;
        }
      }
    }
  }
`;
