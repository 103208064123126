import React from "react";
import { useSelector } from "react-redux";
import { CategoryDetails } from "../../components/organisms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { IMatch } from "../../App/common/typings";

interface IMatchParams {
  id: string;
}
export interface ICategoryViewProps {
  match: IMatch<IMatchParams>;
}

const CategoryView: React.FC<ICategoryViewProps> = ({ match }) => {
  const categoryId = match.params.id;
  const { selectedCategory } = useSelector((state: any) => state.categories);

  return (
    <AdminPage pageTitle={selectedCategory?.name || "New Category"}>
      <CategoryDetails key={categoryId} categoryId={categoryId} />
    </AdminPage>
  );
};

export default CategoryView;
