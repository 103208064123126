import { IUser } from "../../../common/typings";
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  FETCH_USERS,
  CLEAR_COMMENTS,
  LISTEN_TO_SELECTED_USER,
  CLEAR_USERS,
  SET_FILTER,
  SET_START_DATE,
  RETAIN_STATE,
  CLEAR_SELECTED_USER,
} from "./userConstants";

const initialState = {
  users: [],
  moreUsers: true,
  selectedUser: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function userReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_USER:
      return {
        ...state,
        users: [...state.users, payload],
      };
    case UPDATE_USER:
      return {
        ...state,
        users: [
          ...state.users.filter((evt: IUser) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_USER:
      return {
        ...state,
        users: [...state.users.filter((evt: IUser) => evt.id !== payload)],
      };
    case FETCH_USERS:
      return {
        ...state,
        users: payload,
        moreUsers: payload.moreUsers,
        lastVisible: payload.lastVisible,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_USER:
      return {
        ...state,
        selectedUser: payload,
      };
    case CLEAR_SELECTED_USER:
      return {
        ...state,
        selectedUser: null,
      };
    case CLEAR_USERS:
      return {
        ...state,
        users: [],
        moreUsers: true,
        lastVisible: null,
      };
    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        moreUsers: true,
        filter: payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreUsers: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
