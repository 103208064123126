import { IProvider } from "../../../common/typings";
import {
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  FETCH_PROVIDERS,
  CLEAR_COMMENTS,
  LISTEN_TO_SELECTED_PROVIDER,
  CLEAR_PROVIDERS,
  SET_FILTER,
  SET_START_DATE,
  RETAIN_STATE,
  CLEAR_SELECTED_PROVIDER,
} from "./providerConstants";

const initialState = {
  providers: [],
  moreProviders: true,
  selectedProvider: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function providerReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_PROVIDER:
      return {
        ...state,
        providers: [...state.providers, payload],
      };
    case UPDATE_PROVIDER:
      return {
        ...state,
        providers: [
          ...state.providers.filter((evt: IProvider) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_PROVIDER:
      return {
        ...state,
        providers: [
          ...state.providers.filter((evt: IProvider) => evt.id !== payload),
        ],
      };
    case FETCH_PROVIDERS:
      return {
        ...state,
        providers: payload,
        moreProviders: payload.moreProviders,
        lastVisible: payload.lastVisible,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_PROVIDER:
      return {
        ...state,
        selectedProvider: payload,
      };
    case CLEAR_SELECTED_PROVIDER:
      return {
        ...state,
        selectedProvider: null,
      };
    case CLEAR_PROVIDERS:
      return {
        ...state,
        providers: [],
        moreProviders: true,
        lastVisible: null,
      };
    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        moreProviders: true,
        filter: payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreProviders: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
