import React from "react";
import { BurgerMenuBtnWrapper } from "./BurgerMenuBtn.style";
interface IBurgerMenuBtnProps {
  onClick: (evt: any) => void;
  checked: boolean;
}
export const BurgerMenuBtn: React.FC<IBurgerMenuBtnProps> = ({
  onClick,
  checked,
}) => (
  <BurgerMenuBtnWrapper htmlFor="check">
    <input type="checkbox" id="check" onClick={onClick} checked={checked} />
    <span></span>
    <span></span>
    <span></span>
  </BurgerMenuBtnWrapper>
);
