import { Descriptions, notification, Switch } from "antd";
import moment from "moment";
import React from "react";
import { IBooking } from "../../../App/common/typings";
import { Button, Divider, Form, Input, Select, Space } from "../../atoms";
import PackageFlights from "../PackageViewFrontEnd/PackageFlights";
import {
  CartItem,
  HotelInfo,
  ItemNotFound,
  Loader,
  PackageInfo,
  Passengers,
  Person,
} from "../../molecules";
import {
  formatReadableAddress,
  getRoomCost,
} from "../../../App/common/util/util";
import { updateBookingAgentInFirestore } from "../../../App/firestore/firestoreService";
import { sendToClientOptions } from "../../../App/enums";
const roomTypes: { [key: string]: number } = {
  single: 1,
  double: 2,
  triple: 3,
  quadruple: 4,
};

export interface IBookingDetailsProps {
  booking: IBooking;
  updateBooking?: (id: number, data: IBooking) => void;
  bookingId: string;
  loading: boolean;
  error: boolean;
}
export const BookingDetails: React.FC<IBookingDetailsProps> = ({
  booking,
  bookingId,
  loading,
  error,
}) => {
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    try {
      const values: IBooking = await form.validateFields();

      await updateBookingAgentInFirestore(bookingId, {
        status: values.status,
        agentComment: values.agentComment || "",
        flightConfirm: !!values.flightConfirm,
        hotelConfirm: !!values.hotelConfirm,
      });
      notification.success({
        message: "Update",
        description: "Booking Update Successful",
      });
      // history.push(`/booking-confirm/${bookingId}`);
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/admin/bookings"
        redirectBtnText="Back to Cities"
        itemType="Booking"
        itemId={bookingId}
      />
    );
  }
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={booking}
        onFinish={handleOnFinish}
        autoComplete="off"
      >
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Sent to client Status">
            <Form.Item
              name="status"
              rules={[{ required: true, message: "Missing Country" }]}
            >
              <Select
                placeholder="Select a Status"
                options={sendToClientOptions}
              ></Select>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Date of Booking">
            {moment(booking?.bookingDate).format("DD/MM/YYYY HH:mm")}
          </Descriptions.Item>
          <Descriptions.Item label="Total">€{booking?.total}</Descriptions.Item>
          <Descriptions.Item label="Payment Status">
            {booking?.paymentStatus}
          </Descriptions.Item>

          <Descriptions.Item label="Customer">
            <Person {...booking?.customer} />
          </Descriptions.Item>
          <Descriptions.Item label="Package Details">
            <PackageInfo
              title={booking?.travelPackage?.name || "-"}
              departureCity={booking?.travelPackage.departureCity || {}}
              departureDate={booking?.travelPackage.departureDate || ""}
              returnDate={booking?.travelPackage.returnDate || ""}
              serviceInclude={booking?.travelPackage.serviceInclude || []}
              categories={booking?.travelPackage.categories || []}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Flight Details">
            <Descriptions layout="vertical" bordered column={24}>
              <Descriptions.Item label="Flights" span={21}>
                <PackageFlights
                  flights={booking?.flights || []}
                  isMobile
                  noHeader
                />
              </Descriptions.Item>
              <Descriptions.Item
                label="Flight Confirm"
                span={3}
                style={{ textAlign: "center" }}
              >
                <Form.Item name="flightConfirm" valuePropName="checked">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Hotel Details">
            <Descriptions layout="vertical" bordered column={24}>
              <Descriptions.Item label="Hotels Rooms" span={21}>
                {booking?.packageHotels?.map((packageHotel) => (
                  <div key={packageHotel.hotel?.id || ""}>
                    <HotelInfo
                      starRating={packageHotel.hotel?.starRating || 0}
                      hotelName={packageHotel.hotel?.name}
                      address={
                        formatReadableAddress(packageHotel.hotel?.address) || ""
                      }
                      imageUrl={packageHotel.hotel?.defaultImage?.url || ""}
                      mealType={packageHotel.mealsType}
                    />
                    <div className="cart-body">
                      {booking?.rooms?.map((room, index) => (
                        <CartItem
                          title={`1x ${room.roomType} room`}
                          adults={roomTypes[room.roomType]}
                          child={room.numberChilds || 0}
                          info={packageHotel.mealsType}
                          price={getRoomCost(room, packageHotel)}
                          currency="€"
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </Descriptions.Item>
              <Descriptions.Item
                label="Hotel Confirm"
                span={3}
                style={{ textAlign: "center" }}
              >
                <Form.Item name="hotelConfirm" valuePropName="checked">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Descriptions.Item>
          <Descriptions.Item label="Passengers">
            <Passengers passengers={booking?.passengers || []} />
          </Descriptions.Item>
          <Descriptions.Item label="Client Comments">
            {booking?.comment || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Jcc Response">
            {JSON.stringify(booking?.jccResponse)}
          </Descriptions.Item>
          <Descriptions.Item label="Agent Comment">
            <Form.Item name="agentComment">
              <Input.TextArea placeholder="Agent Comments" rows={4} />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
          <Button
            type="primary"
            // loading={}
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      </Form>
    </div>
  );
};
