import { notification, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICategoryInput } from "../../../App/common/typings";
import {
  listenToCategoryFromFirestore,
  updateCategoryInFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { listenToSelectedCategory } from "../../../App/redux/data/category/categoryActions";
import { Button, Form, Input, Space } from "../../atoms";
import { ItemNotFound, Loader } from "../../molecules";

export interface ICategoryDetailsProps {
  categoryId: string;
}

export const CategoryDetails: React.FC<ICategoryDetailsProps> = ({
  categoryId,
}) => {
  const dispatch = useDispatch();
  const { selectedCategory } = useSelector((state: any) => state.categories);
  const { loading, error } = useSelector((state: any) => state.async);
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    try {
      const values: ICategoryInput = await form.validateFields();
      await updateCategoryInFirestore(categoryId, {
        id: categoryId,
        name: values.name,
        code: values.code,
        status: values.status,
      });
      notification.success({
        message: "Update",
        description: "Category Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  userFirestoreDoc({
    query: () => listenToCategoryFromFirestore(categoryId),
    data: listenToSelectedCategory,
    deps: [dispatch, categoryId],
    shouldExecute: categoryId !== "new",
  });
  React.useEffect(() => form.resetFields);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/admin/categories"
        redirectBtnText="Back to Categories"
        itemType="Category"
        itemId={categoryId}
      />
    );
  }
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={selectedCategory}
        onFinish={handleOnFinish}
        autoComplete="off"
      >
        <Form.Item name="status" label="Active" valuePropName="checked">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Code" />
        </Form.Item>
        <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
          <Button
            type="primary"
            // loading={}
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      </Form>
    </div>
  );
};
