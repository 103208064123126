/* eslint-disable no-console */
import {
  IHotel,
  IHotelInput,
  IImage,
  IQueryConfigArgs,
} from "../../common/typings";
import firebase from "../../config/firebase";
import { db, collectionWithArgs } from "./firestoreService";

const COLLECTION_HOTELS = "hotels";
const COLLECTION_IMAGES = "images";
export function fetchHotelsFromFirestore(
  args?: Array<IQueryConfigArgs | null>
) {
  const ref = db.collection(COLLECTION_HOTELS);
  return collectionWithArgs(ref, args || []);
}
export function listenToHotelFromFirestore(id: string) {
  return db.collection(COLLECTION_HOTELS).doc(id);
}

export function addHotelToFirestore(doc: IHotelInput) {
  // const user = firebase.auth().currentHotel;
  return db.collection(COLLECTION_HOTELS).add({
    ...doc,
    images: [],
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateHotelInFirestore(docId: string, doc: IHotel) {
  console.log(doc);
  return db.collection(COLLECTION_HOTELS).doc(docId).update(doc);
}

export async function deleteHotelInFirestore(docId: string) {
  return db.collection(COLLECTION_HOTELS).doc(docId).delete();
}

export function activeToggleHotelInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_HOTELS).doc(docId).update({
    status,
  });
}

export function listenToHotelImagesFromFirestore(id: string) {
  return db.collection(COLLECTION_HOTELS).doc(id).collection(COLLECTION_IMAGES);
}

export async function addImageToHotelImagesInFirestore(
  docId: string,
  image: IImage
) {
  try {
    const currentHotel = await db
      .collection(COLLECTION_HOTELS)
      .doc(docId)
      .get();
    const doc = currentHotel.data();
    if (!doc) throw Error;
    if (!doc.images) doc.images = [];
    const currentImage = [
      ...(doc.images.filter((evt: IImage) => evt.uid !== image.uid) || []),
      image,
    ];
    return db.collection(COLLECTION_HOTELS).doc(docId).update({
      images: currentImage,
    });
  } catch (err) {
    throw err;
  }
}

export async function deleteImageFromHotelImagesInFirestore(
  docId: string,
  imageId: string
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_HOTELS)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc) throw Error;

    return db
      .collection(COLLECTION_HOTELS)
      .doc(docId)
      .update({
        images: [...doc.images.filter((evt: IImage) => evt.uid !== imageId)],
      });
  } catch (err) {
    throw err;
  }
}

export function uploadHotelImageToFirebaseStorage(
  hotelId: string,
  file: any,
  filename: string
) {
  const storageRef = firebase.storage().ref();
  return storageRef.child(`hotels/${hotelId}/images/${filename}`).put(file);
}

export function deleteHotelImageFromFirebaseStorage(
  hotelId: string,
  filename: string
) {
  const storageRef = firebase.storage().ref();
  const photoRef = storageRef.child(`hotels/${hotelId}/images/${filename}`);
  return photoRef.delete();
}
