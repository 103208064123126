import { notification, Switch } from "antd";
import React from "react";
import { IPolicy } from "../../../App/common/typings";
import { updatePackagePolicyInFirestore } from "../../../App/firestore/firestoreService";
import { Button, Form, Input, Space } from "../../atoms";
import { RichTextArea } from "../RichTextArea/RichTextArea";

export interface IPolicyFormProps {
  policy: IPolicy;
  packageId: string;
}
export const PolicyForm: React.FC<IPolicyFormProps> = ({
  policy,
  packageId,
}) => {
  const description = React.useRef("");
  const [form] = Form.useForm();
  const handleOnFinish = (id: string) => async () => {
    try {
      const values: IPolicy = await form.validateFields();
      await updatePackagePolicyInFirestore(packageId, {
        id,
        name: values.name,
        description: description.current || policy?.description || "",
        status: values.status,
      });
      notification.success({
        message: "Update",
        description: "Package Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  React.useEffect(() => form.resetFields());
  return (
    <Form
      form={form}
      name={`packagePolicy_${policy.id}`}
      key={`packagePolicy_${policy.id}`}
      layout="vertical"
      initialValues={policy || {}}
      onFinish={handleOnFinish(policy.id)}
    >
      <Space className="full-width">
        <Form.Item
          name="name"
          label="Policy Name"
          rules={[{ required: true, message: "Missing Policy Name" }]}
        >
          <Input placeholder="Policy Name" />
        </Form.Item>
        <Form.Item name="status" label="Active" valuePropName="checked">
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Space>
      <Form.Item name="description" label="Policy Description">
        <RichTextArea
          description={description}
          html={policy?.description || ""}
        />
      </Form.Item>
      <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Space>
    </Form>
  );
};
