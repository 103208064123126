import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router";
import { Button, Table } from "../../atoms";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";
import { IUser } from "../UserDetails/UserDetails";

export interface IUserListProps {
  users: IUser[];
  searchUser?: (search: string) => void;
  filterUser?: () => void;
  addUser?: () => void;
}
const columnsUserList = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Last login",
    dataIndex: "lastLogin",
    key: "lastLogin",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];
export const UserList: React.FC<IUserListProps> = ({ users }) => {
  const history = useHistory();
  const handelAdd = () => {
    history.push("/admin/user/new");
  };
  return (
    <div>
      <TableHeader>
        <Button
          type="primary"
          onClick={handelAdd}
          block
          icon={<PlusOutlined />}
        >
          Add User
        </Button>
      </TableHeader>
      <Table
        columns={columnsUserList}
        dataSource={users}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};
