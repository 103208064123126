import styled from "styled-components";
export const PackageSearchBoxWrapper = styled.div`
  width: 500px;
  margin: auto;
  text-align: left;
  font-size: 16px;
  background-color: rgba(15, 91, 153, 0.6);
  padding: 20px;
  border-radius: 0;
  @media (max-width: 500px) {
    width: 100%;
  }
  .ant-form-item {
    position: relative;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 7px 20px 7px;
    box-shadow: 0 8px 6px -6px rgb(0 0 0 / 20%);
    &-label > label {
      padding: 0 0 5px;
      padding-left: 5px;
      text-transform: uppercase;
      font-size: 12px;
      color: #22335d;
      font-family: arial, verdana, helvetica, sans-serif;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0px;
  }
  /* .ant-form-item {
    background-color: #fff;
    padding: 10px;
    &-label > label {
      font-size: 12px;
      z-index: 1;
      text-transform: uppercase;
      color: #212121;

      font-family: arial, verdana, helvetica, sans-serif;
    }
  } */
  /* .ant-select-selector {
    border-radius: 0;
    outline: 0;
    height: 40px;
    width: 200%;
    font-size: 1.3rem;
    margin: 20px 0px 2px;
    padding: 0;
    box-shadow: none;
    box-sizing: content-box;
    transition: all 0.3s;
    background-color: transparent;
    border: 0;
  } */
  .search-button {
    border-radius: 3px;
    background: transparent;
    -webkit-text-decoration: none;
    text-decoration: none;
    padding: 10px 12px;
    font-size: 18px;
    line-height: 21px;
    width: 100%;
    border: 1px solid #8db906;
    background-color: #8db906;
    color: #fff !important;
    margin: 6px 0px;
    font-weight: 700;
    transition: all 0.4s ease-in-out;
    height: 50px;
    &:hover {
      opacity: 1;
      background-color: darken(#8db906, 10%);
      border-color: darken(#8db906, 10%);
      transition: all 0.4s ease-in-out;
    }
  }
`;
