export const CREATE_CITY = "CREATE_CITY";
export const UPDATE_CITY = "UPDATE_CITY";
export const DELETE_CITY = "DELETE_CITY";
export const FETCH_CITIES = "FETCH_CITIES";
export const LISTEN_TO_CITY_IMAGES_GROUPS = "LISTEN_TO_CITY_IMAGES_GROUPS";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_CITY = "LISTEN_TO_SELECTED_CITY";
export const CLEAR_SELECTED_CITY = "CLEAR_SELECTED_CITY";
export const CLEAR_CITIES = "CLEAR_CITIES";
export const SET_FILTER = "SET_FILTER";
export const SET_START_DATE = "SET_START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
