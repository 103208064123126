/* eslint-disable no-console */
import { APP_LOADED } from "../async/asyncReducer";
import firebase from "../../../config/firebase";
// import {
//   getUserProfile,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
// import { listenToCurrentUserProfile } from "../user/userActions";
import { AppDispatch } from "../../store";
import { ICreds } from "./../../../common/typings";
import { SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";

export function logInUser(user: ICreds) {
  return {
    type: SIGN_IN_USER,
    payload: user,
  };
}

export function verifyAuth() {
  return function (dispatch: AppDispatch) {
    return firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        dispatch(logInUser(user));
        // const profileRef = getUserProfile(user.uid);
        // profileRef.onSnapshot((snapshot: any) => {
        //   // dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
        dispatch({ type: APP_LOADED });
        // });
      } else {
        dispatch(logOutUser());
        dispatch({ type: APP_LOADED });
      }
    });
  };
}

export function logOutUser() {
  return {
    type: SIGN_OUT_USER,
  };
}
