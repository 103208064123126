/* eslint-disable no-console */
import React from "react";
import ImageGall from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { IImage } from "../../../App/common/typings";
import { ImageGalleryWrapper } from "./ImageGallery.style";

interface IGalleryImages {
  original: string;
  thumbnail?: string;
}
export interface IImageGalleryProps {
  images: IImage[];
  showThumbnails?: boolean;
  showPlayButton?: boolean;
}
export const ImageGallery: React.FC<IImageGalleryProps> = ({
  images,
  showThumbnails,
  showPlayButton,
}) => {
  const [galleryImages, setGalleryImages] = React.useState<IGalleryImages[]>([
    { original: "/assets/no-image.png" },
  ]);

  React.useEffect(() => {
    const defaultImages = images.map((img) => ({
      original: img.url || "",
      thumbnail: img.url || "",
    }));
    setGalleryImages(defaultImages);
  }, [images]);
  return (
    <ImageGalleryWrapper>
      <ImageGall
        items={galleryImages}
        showFullscreenButton={false}
        showThumbnails={showThumbnails && images.length !== 0}
        showPlayButton={showPlayButton}
      ></ImageGall>
    </ImageGalleryWrapper>
  );
};
