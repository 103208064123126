/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/await-thenable */
import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "../../../components/atoms";
import { closeModal } from "../../redux/data/modals/modalReducer";
export interface IModalProps {
  children: React.ReactNode;
  title?: string;
  onSave: () => void;
  loading?: boolean;
}

export const ModalWrapper: React.FC<IModalProps> = ({
  children,
  title,
  onSave,
  loading,
}) => {
  const dispatch = useDispatch();
  const handleOnClose = () => {
    dispatch(closeModal());
  };
  const handleOk = async () => {
    try {
      await onSave();
      // dispatch(closeModal());
    } catch (err) {
      throw err;
    }

    // setModalText('The modal will be closed after two seconds');
    // setConfirmLoading(true);
    // setTimeout(() => {
    //   setVisible(false);
    //   setConfirmLoading(false);
    // }, 2000);
  };
  return (
    <Modal
      title={title}
      visible={true}
      onOk={handleOk}
      confirmLoading={loading}
      onCancel={handleOnClose}
    >
      {children}
    </Modal>
  );
};
