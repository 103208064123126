/* eslint-disable no-console */
import { Col, notification, Rate, Row, Switch } from "antd";
import cuid from "cuid";
import { find } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IHotel } from "../../../App/common/typings";
import { countriesOptions } from "../../../App/enums";
import {
  fetchCitiesFromFirestore,
  listenToHotelFromFirestore,
  updateHotelInFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import { listenToCities } from "../../../App/redux/data/city/cityActions";
import { listenToSelectedHotel } from "../../../App/redux/data/hotel/hotelActions";
import { Button, Divider, Form, Input, Select, Space } from "../../atoms";
import { ItemNotFound, Loader, RichTextArea } from "../../molecules";
import { HotelDetailsWrapper } from "./Hotel.style";

// "https://www.mocky.io/v2/5cc8019d300000980a055e76"
export interface IHotelDetailsProps {
  hotelId: string;
}

export const HotelDetails: React.FC<IHotelDetailsProps> = ({ hotelId }) => {
  const dispatch = useDispatch();
  const { selectedHotel } = useSelector((state: any) => state.hotels);
  const { cities } = useSelector((state: any) => state.cities);
  const { loading, error } = useSelector((state: any) => state.async);
  const description = React.useRef("");
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    try {
      const values: IHotel = await form.validateFields();
      console.log(values.address);
      const city = find(cities, ["id", values.address?.cityId]);
      console.log(city);
      await updateHotelInFirestore(hotelId, {
        id: hotelId,
        starRating: values.starRating,
        name: values.name,
        descriptions: description.current || selectedHotel?.descriptions || "",
        status: values.status,
        topHotel: values.topHotel,
        phoneNumber: values.phoneNumber || "",
        email: values.email || "",
        address: {
          id: cuid(),
          streetLine1: values?.address?.streetLine1,
          streetLine2: values?.address?.streetLine2 || "",
          city,
          cityId: values.address?.cityId,
          state: values?.address?.state || "",
          zipCode: values?.address?.zipCode || "",
          countryCode: values?.address?.countryCode,
          // geometry: values?.address?.geometry,
        },
      });
      notification.success({
        message: "Update",
        description: "Hotel Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  userFirestoreDoc({
    query: () => listenToHotelFromFirestore(hotelId),
    data: listenToSelectedHotel,
    deps: [dispatch, hotelId],
    shouldExecute: hotelId !== "new",
  });
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  React.useEffect(() => form.resetFields);
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/admin/hotels"
        redirectBtnText="Back to Hotels"
        itemType="Hotel"
        itemId={hotelId}
      />
    );
  }
  return (
    <HotelDetailsWrapper>
      <Form
        form={form}
        layout="vertical"
        initialValues={selectedHotel}
        onFinish={handleOnFinish}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col className="gutter-row" span={16}>
            <Divider> Details</Divider>
            <Space className="hotel-details-space">
              <Form.Item name="status" label="Active" valuePropName="checked">
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item
                name="topHotel"
                label="Top Hotel"
                valuePropName="checked"
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>
              <Form.Item
                name="starRating"
                label="Rating"
                rules={[{ required: true, message: "Missing Rating" }]}
              >
                <Rate />
              </Form.Item>
            </Space>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Missing Name" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="descriptions" label="Descriptions">
              <RichTextArea
                description={description}
                html={selectedHotel?.descriptions || ""}
              />
            </Form.Item>
            <Space style={{ display: "flex" }} className="hotel-details-space">
              <Form.Item name="phoneNumber" label="Phone Number">
                <Input placeholder="Phone Number" />
              </Form.Item>
              <Form.Item name="email" label="Email">
                <Input placeholder="Email" />
              </Form.Item>
            </Space>
          </Col>
          <Col className="gutter-row" span={8}>
            <Divider> Address</Divider>
            <Form.Item
              name={["address", "streetLine1"]}
              label="Street Line 1"
              rules={[{ required: true, message: "Missing Street Line 1" }]}
            >
              <Input placeholder="Street Line 1" />
            </Form.Item>
            <Form.Item name={["address", "streetLine2"]} label="Street Line 2">
              <Input placeholder="Street Line 2" />
            </Form.Item>
            <Form.Item
              label="Country"
              name={["address", "countryCode"]}
              rules={[{ required: true, message: "Missing Country" }]}
            >
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="label"
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={countriesOptions}
              ></Select>
            </Form.Item>
            <Form.Item
              label="City"
              name={["address", "cityId"]}
              rules={[{ required: true, message: "Missing City" }]}
            >
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="label"
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={cities.map(({ name, id }: any) => ({
                  value: id,
                  label: name,
                }))}
              ></Select>
            </Form.Item>
            {/* <Form.Item
              label="City"
              name="address.city"
              rules={[{ required: true, message: "Missing City" }]}
            >
              <Select
                showSearch
                placeholder="Select a country"
                optionFilterProp="label"
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={countriesOptions}
              ></Select>
            </Form.Item> */}
            <Space className="hotel-details-space">
              <Form.Item name={["address", "state"]} label="State">
                <Input placeholder="State" />
              </Form.Item>
              <Form.Item name={["address", "zipCode"]} label="ZipCode">
                <Input placeholder="ZipCode" />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Divider />
        <Space style={{ display: "flex", marginBottom: 8 }} align="end">
          <Button
            type="primary"
            // loading={}
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      </Form>
    </HotelDetailsWrapper>
  );
};
