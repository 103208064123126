/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CategoryList } from "../../components/organisms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToCategories } from "../../App/redux/data/category/categoryActions";
import { userFirestoreCollection } from "../../App/hooks/userFirestoreCollection";
import {
  deleteCategoryInFirestore,
  fetchCategoriesFromFirestore,
} from "../../App/firestore/firestoreService";
import { openModal } from "../../App/redux/data/modals/modalReducer";
import { IQueryConfigArgs } from "../../App/common/typings";

const defaultSorting: IQueryConfigArgs = {
  type: "sorting",
  name: "name",
  order: "asc",
  opr: "asc",
};

const Categories: React.FC = () => {
  const dataSou = useSelector((state: any) => state.categories);
  const loading = useSelector((state: any) => state.async);
  const [categorySorting, setCategorySorting] =
    React.useState<IQueryConfigArgs | null>(defaultSorting);
  const [searchCategory, setSearchCategory] = React.useState("");
  const dispatch = useDispatch();

  userFirestoreCollection({
    query: () =>
      fetchCategoriesFromFirestore([
        categorySorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: ">=",
              value: searchCategory,
            }
          : null,
        categorySorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: "<=",
              value: `${searchCategory}\uf8ff`,
            }
          : null,
        categorySorting,
      ]),
    data: listenToCategories,
    deps: [dispatch, categorySorting, searchCategory],
  });
  const handelFilterCategory = () => {
    console.log("filterCategories");
  };
  const handelSearchCategory = (search: string) => {
    if (search !== "") {
      setCategorySorting(defaultSorting);
    }
    setSearchCategory(search);
  };
  const handelAddCategory = () => {
    dispatch(
      openModal({
        modalType: "AddCategoryForm",
        modalProps: { title: "Add Category" },
      })
    );
  };
  const handelDeleteCategory = async (id: string) => {
    await deleteCategoryInFirestore(id);
  };

  const handelSortingChange = (name: string, order: any) => {
    setCategorySorting({
      type: "sorting",
      opr: order,
      name,
      order,
    });
  };

  return (
    <AdminPage pageTitle="Categories">
      <CategoryList
        loading={loading.loading}
        categories={dataSou.categories || []}
        filterCategory={handelFilterCategory}
        searchCategory={handelSearchCategory}
        addCategory={handelAddCategory}
        deleteCategory={handelDeleteCategory}
        sorting={handelSortingChange}
      />
    </AdminPage>
  );
};

export default Categories;
