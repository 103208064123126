import styled from "styled-components";

// theme is now fully typed
export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  height: 100%;
  .dashboard {
    &-info {
      display: grid;
      grid-template-columns: auto 1fr 1fr;
      grid-gap: 20px;
    }
  }
`;
