/* eslint-disable no-console */
import React from "react";
import { Divider, notification } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import cuid from "cuid";
import { Form, Space } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { ITour } from "../../../App/common/typings";
import { updatePackageToursInFirestore } from "../../../App/firestore/firestoreService";
import { TourField } from "../../molecules";

export interface IPackageTourListProps {
  packageId: string;
}

export const PackageTourList: React.FC<IPackageTourListProps> = ({
  packageId,
}) => {
  const { selectedPackage } = useSelector((state: any) => state.packages);
  // const { loading, error } = useSelector((state: any) => state.async);
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    try {
      const values: { tours: ITour[] } = await form.validateFields();
      const tours =
        values.tours?.map(
          (tour: ITour, index): ITour => ({
            ...tour,
            childPrice: tour.childPrice || 0,
            startDate: moment(tour.startDate).format("YYYY-MM-DD"),
            id: tour.id || cuid(),
          })
        ) || [];
      await updatePackageToursInFirestore(packageId, tours);
      notification.success({
        message: "Update",
        description: "Package Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        selectedPackage?.tours?.length === 0
          ? { ...selectedPackage, tours: [{}] }
          : selectedPackage
      }
      onFinish={handleOnFinish}
      autoComplete="off"
      name={`PackageTour${selectedPackage?.id}`}
      key={`PackageTour${selectedPackage?.id}`}
    >
      <Divider>Tours</Divider>
      <TourField name="tours" form={form} />
      <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Space>
    </Form>
  );
};
