/* eslint-disable no-console */
import React from "react";
import { Divider, notification } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import cuid from "cuid";
import { Form, Input, Space } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { IFlight, IPackageFlight } from "../../../App/common/typings";
import { updatePackageFlightInFirestore } from "../../../App/firestore/firestoreService";
import { FlightsField } from "../../molecules";
import { getFlightDuration } from "../../../App/common/util/util";

export interface IPackageFlightListProps {
  packageId: string;
}

export const PackageFlightList: React.FC<IPackageFlightListProps> = ({
  packageId,
}) => {
  const { selectedPackage } = useSelector((state: any) => state.packages);
  // const { loading, error } = useSelector((state: any) => state.async);
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    try {
      const values: IPackageFlight = await form.validateFields();
      const flights =
        values.flights?.map((flight: IFlight, index): IFlight => {
          const duration = getFlightDuration(
            moment(flight.departureDate),
            moment(flight.arrivalDate)
          );
          return {
            ...flight,
            departureDate: moment(flight.departureDate).format(
              "YYYY-MM-DD HH:mm"
            ),
            arrivalDate: moment(flight.arrivalDate).format("YYYY-MM-DD HH:mm"),
            id: flight.id || cuid(),
            duration,
          };
        }) || [];
      await updatePackageFlightInFirestore(packageId, {
        totalFlightPricePerAdult: values.totalFlightPricePerAdult || 0,
        totalFlightPricePerChild: values.totalFlightPricePerChild || 0,
        price10KGLuggage: values.price10KGLuggage || 0,
        price20KGLuggage: values.price20KGLuggage || 0,
        flights,
      });
      notification.success({
        message: "Update",
        description: "Package Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        selectedPackage?.flights?.length === 0
          ? { ...selectedPackage, flights: [{}, {}] }
          : selectedPackage
      }
      onFinish={handleOnFinish}
      autoComplete="off"
      name={`PackageFlight${selectedPackage?.id}`}
      key={`PackageFlight${selectedPackage?.id}`}
    >
      <Space align="baseline" className="full-width">
        <Form.Item
          label="Total flight price per adult"
          name="totalFlightPricePerAdult"
          rules={[
            {
              required: true,
              message: "Missing Total flight price per adult",
            },
          ]}
        >
          <Input prefix="€" placeholder="Total flight price per adult" />
        </Form.Item>
        <Form.Item
          label="Total flight price per Child"
          name="totalFlightPricePerChild"
          rules={[
            {
              required: true,
              message: "Missing Total flight price per Child",
            },
          ]}
        >
          <Input prefix="€" placeholder="Total flight price per Child" />
        </Form.Item>
      </Space>
      <Space align="baseline" className="full-width">
        <Form.Item label="Price for 10KG Luggage" name="price10KGLuggage">
          <Input prefix="€" placeholder="Price for 10Gk Luggage" />
        </Form.Item>
        <Form.Item label="Price for 20KG Luggage" name="price20KGLuggage">
          <Input prefix="€" placeholder="Price for 20KG Luggage" />
        </Form.Item>
      </Space>
      <Divider>Flights</Divider>
      <FlightsField name="flights" form={form} />
      <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Space>
    </Form>
  );
};
