/* eslint-disable no-console */
import React from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Popconfirm } from "antd";
import { Input, Space, Table } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { ICategory } from "../../../App/common/typings";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";

export interface ICategoryListProps {
  categories: ICategory[];
  loading: boolean;
  searchCategory: (search: string) => void;
  filterCategory: () => void;
  addCategory: () => void;
  deleteCategory: (id: string) => void;
  sorting: (name: string, order: any) => void;
}

export const CategoryList: React.FC<ICategoryListProps> = ({
  categories,
  addCategory,
  deleteCategory,
  loading,
  searchCategory,
  sorting,
}) => {
  const handelOnSearch = (value: string) => {
    searchCategory(value);
  };
  const columnsCategoryList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: boolean) => (v ? "Active" : "Not Active"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (v: any, record: ICategory) => (
        <Space size="middle">
          <Link to={`/admin/category/${record.id}`}>
            <EditOutlined /> Edit
          </Link>
          <Popconfirm
            title="Are you sure？"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => deleteCategory(record?.id || "0")}
          >
            <Button type="link">
              {" "}
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleTableChange = (pagination: any, filters: any, sort: any) => {
    if (sort.order === "descend") {
      sorting(sort.columnKey, "desc");
    }
    if (sort.order === "ascend") {
      sorting(sort.columnKey, "asc");
    }
  };
  return (
    <div>
      <TableHeader>
        <Button
          type="primary"
          onClick={addCategory}
          block
          icon={<PlusOutlined />}
        >
          Add Category
        </Button>
        <Input.Search
          placeholder="Search Category"
          onSearch={handelOnSearch}
          style={{ flex: 1 }}
          allowClear
        />
      </TableHeader>
      <Table
        columns={columnsCategoryList}
        dataSource={categories}
        loading={loading}
        rowKey="id"
        scroll={{ x: 500 }}
        onChange={handleTableChange}
      />
    </div>
  );
};
