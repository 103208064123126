import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { ModalWrapper } from "../../../App/common/modals/ModalWrapper";
import { Alert, Form, Input } from "../../atoms";
import { ICategoryInput } from "../../../App/common/typings";
import { closeModal } from "../../../App/redux/data/modals/modalReducer";
import { addCategoryToFirestore } from "../../../App/firestore/firestoreService";

export const AddCategoryForm: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleSubmit = async () => {
    try {
      const values: ICategoryInput = await form.validateFields();
      setLoading(true);
      const category = await addCategoryToFirestore({
        name: values.name,
        code: values.code,
        status: true,
      });
      setLoading(false);
      dispatch(closeModal());
      history.push(`/admin/category/${category.id}`);
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Code" />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
