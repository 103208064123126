import React from "react";
import { CartItemWrapper } from "./CartItem.style";

export interface ICartLuggageItemProps {
  title: string;
  info: string;
  price: number;
  currency: string;
}

export const CartLuggageItem: React.FC<ICartLuggageItemProps> = ({
  title,
  info,
  price,
  currency,
}) => (
  <CartItemWrapper>
    <div className="cart-item-info">
      <span className="cart-item-title">{title}</span>
      <span className="cart-item-info">{info}</span>
    </div>
    <div className="cart-item-price">
      {currency}
      {price}
    </div>
  </CartItemWrapper>
);
