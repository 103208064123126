/* eslint-disable no-console */
import { AppstoreOutlined, SettingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu, PageHeader } from "../../../components/atoms";
import { AdminPageWrapper } from "./AdminPage.style";

const { SubMenu } = Menu;
export interface IAdminPageProps {
  children: React.ReactNode;
  pageTitle: string;
  route?: any[];
}
const defaultRoutes = [
  {
    path: "index",
    breadcrumbName: "Home",
  },
];
export const AdminPage: React.FC<IAdminPageProps> = ({
  children,
  route,
  pageTitle,
}) => {
  const { currentLocation } = useSelector((state: any) => state.auth);
  const [selectedKey, setSelectedKey] = useState("");
  const handelOnChange = ({ key }: any) => {
    setSelectedKey(key);
  };
  React.useEffect(() => {
    setSelectedKey(currentLocation.pathname);
  }, [currentLocation]);
  return (
    <AdminPageWrapper>
      <div className="admin-page-container">
        <div className="admin-page-menu">
          <Menu
            style={{ width: 250 }}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub2", "sub4"]}
            mode="inline"
            onSelect={handelOnChange}
            selectedKeys={[selectedKey]}
          >
            <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Main">
              <Menu.Item key="/admin/">
                <Link to="/admin/">DashBoard</Link>
              </Menu.Item>
              <Menu.Item key="/admin/packages">
                <Link to="/admin/packages">Packages</Link>
              </Menu.Item>
              <Menu.Item key="/admin/packages-pasydy">
                <Link to="/admin/packages-pasydy">Pasydy Packages</Link>
              </Menu.Item>
              <Menu.Item key="/admin/hotels">
                <Link to="/admin/hotels">Hotels</Link>
              </Menu.Item>
              <Menu.Item key="/admin/bookings">
                <Link to="/admin/bookings">Booking</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="sub4" icon={<SettingOutlined />} title="Setting">
              <Menu.Item key="/admin/user/my-profile">
                <Link to="/admin/user/my-profile"> My Profile</Link>
              </Menu.Item>
              <Menu.Item key="/admin/users">
                <Link to="/admin/users"> Users</Link>
              </Menu.Item>
              <Menu.Item key="/admin/providers">
                <Link to="/admin/providers">Companies</Link>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item key="/admin/cities">
                <Link to="/admin/cities"> Cities</Link>
              </Menu.Item>
              <Menu.Item key="/admin/categories">
                <Link to="/admin/categories"> Categories</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </div>
        <div className="admin-page">
          <PageHeader
            className="site-page-header"
            title={pageTitle}
            breadcrumb={{ routes: [...defaultRoutes] }}
          />
          <div className="admin-page-body">
            <div className="admin-page-body-wrapper">
              <div className="admin-page-body-content">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </AdminPageWrapper>
  );
};
