import moment from "moment";
import React from "react";
import { airlines, airports } from "../../../App/enums";
import { Tooltip } from "../../atoms";
import { FlightLegWrapper } from "./FlightLeg.style";

export interface IFlightLegProps {
  departureDate: string;
  departureAirportCode: string;
  arrivalAirportCode: string;
  duration: string;
  arrivalDate: string;
  airlineCode: string;
  isMobile?: boolean;
}
export const FlightLeg: React.FC<IFlightLegProps> = ({
  departureDate,
  departureAirportCode,
  arrivalAirportCode,
  duration,
  arrivalDate,
  airlineCode,
  isMobile,
}) => (
  <FlightLegWrapper isMobile={isMobile || false}>
    <div className="flight-leg-airline-logo">
      <Tooltip title={airlines[airlineCode]?.label || ""}>
        <img src={`/assets/airlines/${airlineCode}.png`} />
      </Tooltip>
    </div>
    <div className="flight-leg-info">
      <div className="flight-leg-airport">
        <span className="flight-leg-airport-day">
          {moment(departureDate).format("DD/MM/YYYY")}
        </span>
        <span className="flight-leg-airport-time">
          {moment(departureDate).format("HH:mm")}
        </span>
        <span className="flight-leg-airport-name">
          {airports[departureAirportCode]?.label || ""}
        </span>
      </div>
      <div className="flight-leg-duration">
        <span className="flight-leg-duration-time"></span>
        <div className="flight-leg-duration-line"></div>
      </div>
      <div className="flight-leg-airport">
        <span className="flight-leg-airport-day">
          {moment(arrivalDate).format("DD/MM/YYYY")}
        </span>
        <span className="flight-leg-airport-time">
          {moment(arrivalDate).format("HH:mm")}
        </span>
        <span className="flight-leg-airport-name">
          {airports[arrivalAirportCode]?.label || ""}
        </span>
      </div>
    </div>
  </FlightLegWrapper>
);
