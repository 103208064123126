/* eslint-disable no-console */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import React from "react";
import { Button, Form, Input, Space } from "../../atoms";
import { TransferFieldWrapper } from "./TransferField.style";

export interface ITransferFieldProps {
  name: string;
  form: any;
}

export const TransferField: React.FC<ITransferFieldProps> = ({
  name: itemName,
  form,
}) => (
  <TransferFieldWrapper>
    <Form.List name={itemName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <div key={key} className="transfers-field-leg">
              <Space align="baseline" className="transfers-field-space">
                <Form.Item
                  name={[name, "name"]}
                  label={index === 0 ? "Name" : ""}
                  rules={[
                    {
                      required: true,
                      message: "Missing Name",
                    },
                  ]}
                >
                  <Input placeholder="Name" style={{ width: 300 }} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label={index === 0 ? "Price per adult" : ""}
                  name={[name, "adultPrice"]}
                >
                  <Input prefix="€" placeholder="Price Per adult" />
                </Form.Item>
                <Form.Item
                  {...restField}
                  label={index === 0 ? "Price per child" : ""}
                  name={[name, "childPrice"]}
                >
                  <Input prefix="€" placeholder="Price Per Child" />
                </Form.Item>
                <div className="transfers-field-action">
                  <Popconfirm
                    placement="top"
                    title="Are you sure？"
                    onConfirm={() => remove(name)}
                  >
                    <MinusCircleOutlined style={{ color: "red" }} />
                  </Popconfirm>
                </div>
              </Space>
            </div>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add Transfer
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </TransferFieldWrapper>
);
