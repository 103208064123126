/* eslint-disable no-console */
import React from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { notification, Popconfirm, Switch } from "antd";
import { Input, Select, Space, Table } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { ICity } from "../../../App/common/typings";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";
import { countries, countriesOptions } from "../../../App/enums";
import { activeToggleCityFrontEndFilterInFirestore } from "../../../App/firestore/firestoreService";
import { CityPackagesTotal } from "../../molecules";

export interface ICityListProps {
  cities: ICity[];
  loading: boolean;
  searchCity: (search: string) => void;
  filterCity: (countryCode: string) => void;
  addCity: () => void;
  deleteCity: (id: string) => void;
  sorting: (name: string, order: any) => void;
}

export const CityList: React.FC<ICityListProps> = ({
  cities,
  addCity,
  deleteCity,
  loading,
  searchCity,
  sorting,
  filterCity,
}) => {
  const handelOnSearch = (value: string) => {
    searchCity(value);
  };
  const handelFrontEndFilterChange =
    (id: string) => async (status: boolean) => {
      try {
        await activeToggleCityFrontEndFilterInFirestore(id, status);
        notification.success({
          message: "Update",
          description: "City status update successfully",
        });
      } catch (error: any) {
        notification.error({
          message: "Update City Status",
          description: error.message,
        });
      }
    };
  const columnsCityList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
    },
    {
      title: "Country",
      dataIndex: "countryCode",
      key: "countryCode",
      sorter: true,
      render: (v: string) => countries[v]?.label || "-",
    },
    {
      title: "Total Packages",
      dataIndex: "id",
      key: "id",
      render: (v: string) => <CityPackagesTotal destinationId={v} />,
    },
    {
      title: "FrontEnd Filter",
      dataIndex: "showFrontEndFilter",
      key: "showFrontEndFilter",
      sorter: (a: ICity, b: ICity) =>
        a.status === b.status ? 0 : a.status ? -1 : 1,
      render: (v: boolean, record: ICity) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={v}
          onChange={handelFrontEndFilterChange(record.id)}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: boolean) => (v ? "Active" : "Not Active"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (v: any, record: ICity) => (
        <Space size="middle">
          <Link to={`/admin/city/${record.id}`}>
            <EditOutlined /> Edit
          </Link>
          <Popconfirm
            title="Are you sure？"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => deleteCity(record?.id || "0")}
          >
            <Button type="link">
              {" "}
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleTableChange = (pagination: any, filters: any, sort: any) => {
    if (sort.order === "descend") {
      sorting(sort.columnKey, "desc");
    }
    if (sort.order === "ascend") {
      sorting(sort.columnKey, "asc");
    }
  };
  return (
    <div>
      <TableHeader>
        <Button type="primary" onClick={addCity} block icon={<PlusOutlined />}>
          Add City
        </Button>
        <Input.Search
          placeholder="Search City"
          onSearch={handelOnSearch}
          style={{ flex: 1 }}
          allowClear
        />
        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder="Select Country"
          optionFilterProp="children"
          onChange={filterCity}
          filterOption={(input, option: any) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={countriesOptions}
        />
      </TableHeader>
      <Table
        columns={columnsCityList}
        dataSource={cities}
        loading={loading}
        rowKey="id"
        scroll={{ x: 800 }}
        onChange={handleTableChange}
      />
    </div>
  );
};
