/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { List, Image, Row, Col, Popconfirm } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Collapse } from "../../atoms";
import { IDestination, IImage, IImageGroup } from "../../../App/common/typings";
import {
  getImagesFromCityImagesGroupInFirestore,
  updatePackageImagesInFirestore,
} from "../../../App/firestore/firestoreService";

export interface IPackageImagesProps {
  packageId: string;
}
export const PackageImages: React.FC<IPackageImagesProps> = ({ packageId }) => {
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const [selectedImages, setSelectedImages] = React.useState<IImage[]>([]);
  // const [previewTitle, setPreviewTitle] = React.useState("");
  const [imagesGroup, setImagesGroups] = React.useState<any>([]);
  const handelToggleImages = (image: IImage) => (e: any) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setSelectedImages([...selectedImages, image]);
    } else {
      setSelectedImages(selectedImages.filter((img) => img.uid !== image.uid));
    }
  };
  const handelDeleteImages = (image: IImage) => (e: any) => {
    setSelectedImages(selectedImages.filter((img) => img.uid !== image.uid));
  };
  const handelSaveImagesToPackages = () => {
    updatePackageImagesInFirestore(packageId, selectedImages);
  };

  React.useEffect(() => {
    const citiesIds = selectedPackage.destinations.map(
      (destination: IDestination) => destination.cityId
    );
    setSelectedImages(selectedPackage.images || []);
    getImagesFromCityImagesGroupInFirestore(citiesIds, setImagesGroups);
  }, [selectedPackage]);

  console.log(imagesGroup);
  return (
    <div>
      <Row gutter={10}>
        <Col span="15">
          <div
            style={{
              height: 600,
              overflow: "auto",
              padding: "0 16px",
              // border: "1px solid rgba(140, 140, 140, 0.35)",
            }}
          >
            <Collapse defaultActiveKey={[]}>
              {imagesGroup.map((group: IImageGroup) => (
                <Collapse.Panel
                  header={`${group?.cityName || ""}\\${group.name}`}
                  key={group.id}
                >
                  <List
                    bordered
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 4,
                      lg: 4,
                      xl: 6,
                      xxl: 3,
                    }}
                    dataSource={group.images}
                    key={group.id}
                    renderItem={(item) => (
                      <List.Item>
                        <div>
                          <Checkbox
                            checked={!!find(selectedImages, ["uid", item.uid])}
                            onClick={handelToggleImages(item)}
                          />
                          <Image width={150} height={150} src={item.url} />
                        </div>
                      </List.Item>
                    )}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </div>
        </Col>
        <Col span="9">
          {" "}
          <List
            header={"Selected Images"}
            bordered
            dataSource={selectedImages}
            renderItem={(item: IImage) => (
              <List.Item>
                <Image width={150} height={150} src={item.url} />
                <Popconfirm
                  placement="top"
                  title="Are you sure you delete ？"
                  onConfirm={handelDeleteImages(item)}
                >
                  <DeleteOutlined style={{ color: "red" }} />
                </Popconfirm>
              </List.Item>
            )}
          />
          <Button type="primary" onClick={handelSaveImagesToPackages}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};
