import React from "react";
import { useDispatch, useSelector } from "react-redux";
import cuid from "cuid";
import { find } from "lodash";
import { mealTypesOptions } from "../../../App/enums";
import { ModalWrapper } from "../../../App/common/modals/ModalWrapper";
import { Alert, Form, Input, Select, Space } from "../../atoms";
import { IHotelPackageInput } from "../../../App/common/typings";
import { closeModal } from "../../../App/redux/data/modals/modalReducer";
import {
  fetchHotelsFromFirestore,
  updatePackageHotelInFirestore,
  addPackageHotelInFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import { listenToHotels } from "../../../App/redux/data/hotel/hotelActions";

// export interface ICityData extends ICity {
//   countryId: string;
// }

export const AddPackageHotelForm: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { hotels } = useSelector((state: any) => state.hotels);
  userFirestoreCollection({
    query: () =>
      fetchHotelsFromFirestore([
        {
          type: "filter",
          name: "address.cityId",
          opr: "==",
          value: props.cityId,
        },
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToHotels,
    deps: [dispatch, props.cityId],
  });
  const handleSubmit = async () => {
    try {
      const values: IHotelPackageInput = await form.validateFields();
      const hotel = find(hotels, ["id", values.hotelId]);
      setLoading(true);
      if (props.isEdit) {
        await updatePackageHotelInFirestore(props.packageId, {
          ...props.hotelPackage,
          hotelId: values.hotelId,
          hotel,
          singleRoomPrice: values.singleRoomPrice || 0,
          doubleRoomPrice: values.doubleRoomPrice,
          tripleRoomPrice: values.tripleRoomPrice || 0,
          quadrupleRoomPrice: values.quadrupleRoomPrice || 0,
          firstChildPrice: values.firstChildPrice || 0,
          secondChildPrice: values.secondChildPrice || 0,
          mealsType: values.mealsType,
        });
      } else {
        await addPackageHotelInFirestore(props.packageId, {
          hotelId: values.hotelId,
          hotel,
          cityId: props.cityId,
          singleRoomPrice: values.singleRoomPrice || 0,
          doubleRoomPrice: values.doubleRoomPrice,
          tripleRoomPrice: values.tripleRoomPrice || 0,
          quadrupleRoomPrice: values.quadrupleRoomPrice || 0,
          firstChildPrice: values.firstChildPrice || 0,
          secondChildPrice: values.secondChildPrice || 0,
          mealsType: values.mealsType,
          status: true,
          id: cuid(),
        });
      }
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        initialValues={props.hotelPackage || {}}
      >
        <Form.Item
          label="Hotel"
          name="hotelId"
          rules={[{ required: true, message: "Missing Country" }]}
        >
          <Select
            showSearch
            placeholder="Select a Hotel"
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={hotels.map(({ name, id }: any) => ({
              value: id,
              label: name,
            }))}
          ></Select>
        </Form.Item>
        <Space className="full-width">
          <Form.Item
            name="singleRoomPrice"
            label="Single Room (Price per Adult)"
          >
            <Input placeholder="Single Room " />
          </Form.Item>
          <Form.Item
            name="doubleRoomPrice"
            label="Double Room (Price per Adult)"
            rules={[{ required: true, message: "Missing Double Room Price" }]}
          >
            <Input placeholder="Double Room " />
          </Form.Item>
        </Space>
        <Space className="full-width">
          <Form.Item
            name="tripleRoomPrice"
            label="Triple Room (Price per Adult)"
          >
            <Input placeholder="Triple Room" />
          </Form.Item>

          <Form.Item
            name="quadrupleRoomPrice"
            label="Quadruple Room (Price per Adult)"
          >
            <Input placeholder="Quadruple Room" />
          </Form.Item>
        </Space>
        <Space className="full-width">
          <Form.Item name="firstChildPrice" label="First Child">
            <Input placeholder="First Child" />
          </Form.Item>
          <Form.Item name="secondChildPrice" label="Second Child">
            <Input placeholder="Second Child" />
          </Form.Item>
        </Space>
        <Form.Item
          name="mealsType"
          label="Meals Type"
          rules={[{ required: true, message: "Missing Meals Type" }]}
        >
          <Select
            placeholder="Meals Type"
            optionFilterProp="label"
            options={mealTypesOptions}
          ></Select>
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
