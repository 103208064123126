/* eslint-disable no-console */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import moment from "moment";
import React from "react";
import { ICity } from "../../../App/common/typings";
import { Button, DatePicker, Form, Select, Space } from "../../atoms";
import { DestinationsFieldWrapper } from "./DestinationsField.style";

export interface IDestinationsFieldProps {
  cities: ICity[];
  form: any;
}

export const DestinationsField: React.FC<IDestinationsFieldProps> = ({
  cities,
  form,
}) => (
  <DestinationsFieldWrapper>
    <Form.List name="destinations">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => {
            console.log(key, name);
            return (
              <div key={key} className="destinations-field-destination">
                <Space className="full-width">
                  <Form.Item
                    label={
                      fields.length === index + 1
                        ? "Returning Date"
                        : `Departure Date City ${index + 1}`
                    }
                    name={[name, "checkOutDate"]}
                    rules={[{ required: true, message: "Missing Date" }]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={"DD/MM/YYYY"}
                      disabledDate={(current) =>
                        current <= form.getFieldValue("currentDepartureDate") ||
                        current <= moment()
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      fields.length === index + 1
                        ? "Returning City"
                        : `City ${index + 1}`
                    }
                    name={[name, "cityId"]}
                    rules={[{ required: true, message: "Missing City" }]}
                  >
                    <Select
                      showSearch
                      placeholder="City"
                      optionFilterProp="label"
                      filterOption={(input, option: any) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      options={cities.map(({ name: cityName, id }: any) => ({
                        value: id,
                        label: cityName,
                      }))}
                    ></Select>
                  </Form.Item>
                </Space>

                {fields.length !== 1 && (
                  <Popconfirm
                    placement="top"
                    title="Are you sure？"
                    onConfirm={() => remove(name)}
                  >
                    <MinusCircleOutlined style={{ color: "red" }} />
                  </Popconfirm>
                )}
              </div>
            );
          })}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add Destination
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </DestinationsFieldWrapper>
);
