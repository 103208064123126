import React from "react";
import { Drawer } from "antd";
import { HotelInfo, ImageGallery } from "../../molecules";
import { IPackageHotelPublic } from "../../../App/common/typings";
import { formatReadableAddress } from "../../../App/common/util/util";
export interface IPackageHotelViewProps {
  viewedHotel?: IPackageHotelPublic;
  mealsType: string;
  visible: boolean;
  onClose: () => void;
  isMobile: boolean;
}
export const PackageHotelView: React.FC<IPackageHotelViewProps> = ({
  viewedHotel,
  mealsType,
  visible,
  onClose,
  isMobile,
}) => {
  if (!viewedHotel) {
    return <div></div>;
  }
  const { hotel } = viewedHotel;
  return (
    <div>
      <Drawer
        title={hotel.name}
        placement={"right"}
        width={isMobile ? "100%" : 500}
        visible={visible}
        onClose={onClose}
      >
        <HotelInfo
          starRating={hotel.starRating}
          hotelName={hotel.name}
          address={formatReadableAddress(hotel?.address) || ""}
          mealType={mealsType}
        />
        <ImageGallery images={hotel.images || []} />
        <div
          style={{ marginTop: 20 }}
          dangerouslySetInnerHTML={{ __html: hotel.descriptions || "" }}
        />
      </Drawer>
    </div>
  );
};
