import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { history } from "../redux/store";
import { Loader } from "../../components/molecules";
import { Header, MobileMenu } from "../../components/organisms";
import ModalManager from "../common/modals/ModalManager";
import AppRouter from "./AppRouter";
import { AppWrapper } from "./App.style";

const App: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const [showMenu, setShowMenu] = React.useState(false);
  const { initialized } = useSelector((state: any) => state.async);
  if (!initialized) return <Loader color="red" />;
  return (
    <ConnectedRouter history={history}>
      <ModalManager />
      <AppWrapper>
        <Header
          isMobile={isMobile}
          setShowMenu={setShowMenu}
          showMenu={showMenu}
        />
        {isMobile && (
          <MobileMenu showMenu={showMenu} setShowMenu={setShowMenu} />
        )}
        <div className="app-body">
          <AppRouter childProps="fdfd"></AppRouter>
        </div>
      </AppWrapper>
    </ConnectedRouter>
  );
};

export default App;
