/* eslint-disable no-console */
import React from "react";
import { IMatch } from "../../../App/common/typings";
import { urlStringToOBject } from "../../../App/common/util/util";
import { PackageListFrontEnd } from "../../../components/organisms";

interface IMatchParams {
  params: string;
}
export interface IPackageListProps {
  match: IMatch<IMatchParams>;
}

const PackageList: React.FC<IPackageListProps> = ({ match }) => {
  const params = urlStringToOBject(match.params.params);
  return (
    <div>
      <PackageListFrontEnd params={params || {}} />
    </div>
  );
};

export default PackageList;
