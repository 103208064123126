import React from "react";
import { FormattedMessage } from "react-intl";
import { mealTypes } from "../../../App/enums";
import { Tooltip } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { PriceBoxWrapper } from "./PriceBox.style";
export interface IPriceBoxProps {
  title: string;
  titleColor?: string;
  price: number;
  descriptions: string;
  currency: string;
}
export const PriceBox: React.FC<IPriceBoxProps> = ({
  title,
  price,
  descriptions,
  titleColor,
  currency,
}) => (
  <PriceBoxWrapper>
    <div className="price-box-title">
      <Tooltip title={title}>{title}</Tooltip>
    </div>
    <div className="price-box-body">
      <span className="price-box-price">
        {currency}
        {price}
      </span>
      <span className="price-box-des">{mealTypes[descriptions]?.label}</span>
    </div>

    <Button className="price-box-select" type="link">
      <FormattedMessage
        description="Per Person"
        defaultMessage="Ανά Άτομο"
        id="p9665E"
      />
    </Button>
  </PriceBoxWrapper>
);
