import React from "react";
import { useSelector } from "react-redux";
import { IPackage } from "../../../App/common/typings";

interface IProps {
  destinationId: string;
}
export const CityPackagesTotal: React.FC<IProps> = ({ destinationId }) => {
  const { packages } = useSelector((state: any) => state.packages) || [];

  return (
    <div>
      {
        packages.filter(
          (pack: IPackage) =>
            pack.destinationIds?.includes(destinationId) && pack.status
        ).length
      }
    </div>
  );
};
