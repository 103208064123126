/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Input, Select, Space } from "../../atoms";
import { DatePicker } from "../../atoms/DatePicker/DatePicker";
import { BookingContactInformationWrapper } from "./PackageBookingFrontEnd.style";
const REGEX = /[A-z\u00C0-\u00ff]+/g;
export interface IBookingPassengerProps {
  name: number;
  type: string;
  departureDated: string;
  roomName: string;
}
const BookingPassenger: React.FC<IBookingPassengerProps> = ({
  name,
  type,
  departureDated,
  roomName,
}) => {
  const { formatMessage } = useIntl();
  return (
    <BookingContactInformationWrapper>
      <div className="booking-contact-title">
        <FormattedMessage
          description="Passenger"
          defaultMessage="Passenger"
          id="00QkWc"
        />{" "}
        {name + 1} ({type}) - {roomName}
      </div>
      <div className="booking-contact-des">
        <FormattedMessage
          description="Passenger description"
          defaultMessage="Passenger description"
          id="Y4J7fq"
        />
      </div>
      <Space className="full-width" style={{ alignItems: "baseline" }}>
        <Form.Item
          name={[name, "title"]}
          rules={[{ required: true, message: "Missing Title" }]}
          label={formatMessage({
            description: "Title",
            defaultMessage: "Title",
            id: "tlM/CR",
          })}
          initialValue="mr"
        >
          <Select
            placeholder={formatMessage({
              description: "Title",
              defaultMessage: "Title",
              id: "tlM/CR",
            })}
          >
            <Select.Option value="mr">
              {/* <FormattedMessage
                description="Mr."
                defaultMessage="Mr."
                id="3cQbgO"
              /> */}
              Mr.
            </Select.Option>
            <Select.Option value="mrs">
              {/* <FormattedMessage
                description="Mrs. / Miss / Ms."
                defaultMessage="Mrs. / Miss / Ms."
                id="GJE2C0"
              /> */}
              Mrs. / Miss / Ms.
            </Select.Option>
          </Select>
        </Form.Item>

        <div>
          <Form.Item
            name={[name, "firstName"]}
            rules={[
              {
                required: true,
                message: formatMessage({
                  description: "Missing First Name",
                  defaultMessage: "Missing First Name",
                  id: "N4SCrb",
                }),
              },

              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value.match(REGEX)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Παρακαλώ πληκτρολογήσετε το ονοματεπώνυμο σας με Λατινικούς χαρακτήρες.`
                    )
                  );
                },
              }),
            ]}
            label={formatMessage({
              description: "First Name",
              defaultMessage: "First Name",
              id: "91ZDEd",
            })}
          >
            <Input
              placeholder={formatMessage({
                description: "First Name",
                defaultMessage: "First Name",
                id: "91ZDEd",
              })}
            />
          </Form.Item>
          <div style={{ marginTop: -5, color: "#444" }}>
            {" "}
            {formatMessage({
              description:
                "Please use the first name in your travel document (Id, Passport)",
              defaultMessage:
                "Please use the first name in your travel document (Id, Passport)",
              id: "fE6Y8i",
            })}
          </div>
        </div>
        <div>
          <Form.Item
            name={[name, "lastName"]}
            rules={[
              {
                required: true,
                message: formatMessage({
                  description: "Missing Last Name",
                  defaultMessage: "Missing Last Name",
                  id: "j/3qlw",
                }),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value.match(REGEX)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      `Παρακαλώ πληκτρολογήσετε το ονοματεπώνυμο σας με Λατινικούς χαρακτήρες.`
                    )
                  );
                },
              }),
            ]}
            label={formatMessage({
              description: "Last Name",
              defaultMessage: "Last Name",
              id: "Lw2rFO",
            })}
          >
            <Input
              placeholder={formatMessage({
                description: "Last Name",
                defaultMessage: "Last Name",
                id: "Lw2rFO",
              })}
            />
          </Form.Item>
          <div style={{ marginTop: -5, color: "#444" }}>
            {" "}
            {formatMessage({
              description:
                "Please use the last name in your travel document (Id, Passport)",
              defaultMessage:
                "Please use the last name in your travel document (Id, Passport)",
              id: "6hNU0P",
            })}
          </div>
        </div>
      </Space>
      <Space className="full-width">
        <Form.Item
          name={[name, "dateOfBirth"]}
          rules={[
            {
              required: true,
              message: formatMessage({
                description: "Missing Date of birth",
                defaultMessage: "Missing Date of birth",
                id: "nQ3aL+",
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  value > moment(departureDated).add(-18, "year") &&
                  name === 0
                ) {
                  return Promise.reject(
                    new Error(
                      `Όχι ενήλικας: Η ηλικία που εισαγάγατε είναι πολύ μικρή. Η ηλικία ενός ενήλικα
                      δεν μπορεί να είναι μικρότερη των 18 ετών στο τέλος του ταξιδιού`
                    )
                  );
                } else if (
                  value < moment(departureDated).add(-12, "year") &&
                  type === "CHILD"
                ) {
                  return Promise.reject(
                    new Error(
                      `Όχι παιδί: Η ηλικία που εισαγάγατε είναι πολύ μεγάλη.
                      Η ηλικία ενός παιδιού δεν μπορεί να είναι μεγαλύτερη
                      των 11 ετών στο τέλος του ταξιδιού.`
                    )
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          label={formatMessage({
            description: "Date of birth",
            defaultMessage: "Date of birth",
            id: "45h/lp",
          })}
        >
          <DatePicker
            placeholder={formatMessage({
              description: "Date of birth",
              defaultMessage: "Date of birth",
              id: "45h/lp",
            })}
            style={{ width: "100%" }}
            disabledDate={(current) => current >= moment()}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </Space>
    </BookingContactInformationWrapper>
  );
};

export default BookingPassenger;
