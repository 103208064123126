import { ICity } from "../../../common/typings";
import {
  CREATE_CITY,
  UPDATE_CITY,
  DELETE_CITY,
  FETCH_CITIES,
  LISTEN_TO_CITY_IMAGES_GROUPS,
  CLEAR_COMMENTS,
  LISTEN_TO_SELECTED_CITY,
  CLEAR_CITIES,
  SET_FILTER,
  SET_START_DATE,
  RETAIN_STATE,
  CLEAR_SELECTED_CITY,
} from "./cityConstants";

const initialState = {
  cities: [],
  citiesDropDown: [],
  imagesGroups: [],
  moreCities: true,
  selectedCity: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function cityReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_CITY:
      return {
        ...state,
        cities: [...state.cities, payload],
      };
    case UPDATE_CITY:
      return {
        ...state,
        cities: [
          ...state.cities.filter((evt: ICity) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_CITY:
      return {
        ...state,
        cities: [...state.cities.filter((evt: ICity) => evt.id !== payload)],
      };
    case FETCH_CITIES:
      return {
        ...state,
        cities: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_CITY_IMAGES_GROUPS:
      return {
        ...state,
        imagesGroups: payload,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_CITY:
      return {
        ...state,
        selectedCity: payload,
      };
    case CLEAR_SELECTED_CITY:
      return {
        ...state,
        selectedCity: null,
      };
    case CLEAR_CITIES:
      return {
        ...state,
        cities: [],
        moreCities: true,
        lastVisible: null,
      };
    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        moreCities: true,
        filter: payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreCities: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
