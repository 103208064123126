export const CREATE_PROVIDER = "CREATE_PROVIDER";
export const UPDATE_PROVIDER = "UPDATE_PROVIDER";
export const DELETE_PROVIDER = "DELETE_PROVIDER";
export const FETCH_PROVIDERS = "FETCH_PROVIDERS";
export const LISTEN_TO_PROVIDER_CHAT = "LISTEN_TO_PROVIDER_CHAT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_PROVIDER = "LISTEN_TO_SELECTED_PROVIDER";
export const CLEAR_SELECTED_PROVIDER = "CLEAR_SELECTED_PROVIDER";
export const CLEAR_PROVIDERS = "CLEAR_PROVIDERS";
export const SET_FILTER = "SET_FILTER";
export const SET_START_DATE = "SET_START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
