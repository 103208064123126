import React from "react";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { Dashboard } from "../../components/organisms";

const DashBoardPage: React.FC = () => (
  <AdminPage pageTitle="Dashboard">
    <Dashboard
      currency="€"
      lifetimeBookings={10000.01}
      lifetimeSales={2023.34}
      lifetimeCommissions={2323.78}
      topSellingProviders={[]}
      topSellingDestinations={[]}
      latestBooking={[]}
    ></Dashboard>
  </AdminPage>
);

export default DashBoardPage;
