import { Switch } from "antd";
import React from "react";
import { IProvider } from "../../../App/common/typings";
import { Form, Space, Input, Button } from "../../atoms";
// export interface IEmployee{
//     id: number;
//     companyName: string;
//     firstName: string;
//     lastName: string;
//     phoneNumber: string;
//     email: string;
//     cityId: number;
//     countryId: number;
//     address: string;
//     descriptions:string;

//     createDate: string;
//     status: boolean;
//     sorting: number;
//   }

export interface IProviderDetailsProps {
  provider: IProvider;
  addProvider?: (data: IProvider) => void;
  updateProvider?: (id: number, data: IProvider) => void;
  changePassword?: () => void;
}
export const ProviderDetails: React.FC<IProviderDetailsProps> = ({
  provider,
}) => (
  <div>
    <Form labelCol={{ span: 3, offset: 0 }}>
      <Form.Item label="Active">
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked
          checked={provider.status}
        />
      </Form.Item>
      <Form.Item label="Company Name" initialValue={provider.companyName}>
        <Input value={provider.companyName} placeholder="Company Name" />
      </Form.Item>
      <Form.Item label="First Name" initialValue={provider.firstName}>
        <Input value={provider.firstName} placeholder="First Name" />
      </Form.Item>
      <Form.Item label="Last Name" initialValue={provider.lastName}>
        <Input value={provider.lastName} />
      </Form.Item>
      <Form.Item label="Email" initialValue={provider.email}>
        <Input value={provider.email} />
      </Form.Item>
      <Form.Item label="Phone" initialValue={provider.phoneNumber}>
        <Input value={provider.phoneNumber} />
      </Form.Item>
      <Form.Item label="Website" initialValue={provider.website}>
        <Input value={provider.website} />
      </Form.Item>
      <Form.Item label="Country" initialValue={provider.country}>
        <Input value={provider.country} />
      </Form.Item>
      <Form.Item label="City" initialValue={provider.city}>
        <Input value={provider.city} />
      </Form.Item>
      <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
        <Button type="primary">Close</Button>
        <Button type="primary">Save</Button>
      </Space>
    </Form>
  </div>
);
