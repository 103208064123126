import styled from "styled-components";

// theme is now fully typed
export const HeaderWrapper = styled.div`
  display: flex;
  padding: 0 10px;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  z-index: 1;
  background-color: #37a7cd;
  align-items: center;
  position: relative;
  .header {
    &-menu {
      display: flex;
      gap: 8px;
      justify-content: center;
      font-size: 18px;
      flex: 1;
      a {
        color: #ffffff;
        font-weight: 200;
        &:hover {
          color: #f8f8f8;
        }
      }
    }
    &-pasydy-logo {
      height: 40px;
      &-name {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #ffffff;
        margin-left: 10px;
      }
    }
    &-mobile-icon {
      position: absolute;
      right: 10px;
      top: 6px;
    }
    &-phone-number {
      display: flex;
      align-items: center;
      &-icon {
        font-size: 30px;
        color: #fff;
        margin-right: 5px;
        margin-top: -7px;
      }
      &-text {
        color: #fff;
        margin-right: 20px;
        text-align: center;
      }
      &-first {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
`;
