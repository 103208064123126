import styled from "styled-components";
export const PackageBookingFrontEndWrapper = styled.div`
  background-color: #f6f8f9;
  padding: 40px 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .package-view-frontend {
    &-body {
      display: flex;
      margin: auto;
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
    &-left {
      overflow-y: auto;
      flex: 1;
      padding: 30px;
      @media only screen and (max-width: 600px) {
        padding: 0px;
      }
    }
    &-right {
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      padding: 20px;
      width: 400px;
      display: flex;
      flex-direction: column;
      transform: translateX(0);
      transition: all 0.35s ease;
      @media only screen and (max-width: 1200px) {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
      }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
    &-booking-details {
      background-color: #fff;
      position: absolute;
      bottom: 0;
      padding: 20px;
      left: 0;
      right: 0;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
      z-index: 80;
      &-btn {
        background-color: #1d3bf8;
        height: 50px;
        width: 100%;
        color: #fff;
        font-size: 18px;
        font-family: "Avenir";
        font-weight: 900;
      }
    }
  }
  .hide {
    transform: translateX(100%);
    width: 0;
  }
`;

export const BookingContactInformationWrapper = styled.div`
  margin-bottom: 40px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  padding: 40px 25px;
  .booking-contact {
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
    }
    &-des {
      color: #485b7c;
      font-size: 16px;
      font-weight: 300;
      font-family: "Avenir";
    }
  }
`;
