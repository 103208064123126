/* eslint-disable no-console */
import { notification } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPolicy } from "../../../App/common/typings";
import { deletePackagePolicyInFirestore } from "../../../App/firestore/firestoreService";
import { openModal } from "../../../App/redux/data/modals/modalReducer";
import { Tabs } from "../../atoms";
import { PolicyForm } from "../../molecules/PolicyForm/PolicyForm";
const { TabPane } = Tabs;
export interface IPackagePoliciesProps {
  packageId: string;
}
export const PackagePolicies: React.FC<IPackagePoliciesProps> = ({
  packageId,
}) => {
  const dispatch = useDispatch();
  const { selectedPackage } = useSelector((state: any) => state.packages);

  const handelAddPackagePolicy = () => {
    dispatch(
      openModal({
        modalType: "AddPackagePolicyForm",
        modalProps: { title: "Add Policy To Package", packageId },
      })
    );
  };

  const handelDeletePackagePolicy = async (policyId: string) => {
    try {
      await deletePackagePolicyInFirestore(packageId, policyId);
      notification.success({
        message: "Delete",
        description: "Policy Delete Successful",
      });
    } catch (error: any) {
      notification.error({
        message: "Update Failed",
        description: error.message,
      });
    }
  };
  const handleOnEdit = async (targetKey: any, action: any) => {
    if (action === "add") {
      handelAddPackagePolicy();
    }
    if (action === "remove") {
      await handelDeletePackagePolicy(targetKey);
    }
    // this[action](targetKey);
  };

  return (
    <div>
      <Tabs type="editable-card" onEdit={handleOnEdit}>
        {selectedPackage.policies?.map((policy: IPolicy) => (
          <TabPane tab={policy.name} key={policy.id} forceRender>
            <PolicyForm policy={policy} packageId={packageId} key={policy.id} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
