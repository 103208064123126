/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { ModalWrapper } from "../../../App/common/modals/ModalWrapper";
import { Alert, Form, Input } from "../../atoms";
import { IImageGroupInput } from "../../../App/common/typings";
import { closeModal } from "../../../App/redux/data/modals/modalReducer";
import { addCityImagesGroupToFirestore } from "../../../App/firestore/firestoreService";

export const CityImageGroupForm: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleSubmit = async () => {
    try {
      const values: IImageGroupInput = await form.validateFields();
      setLoading(true);
      await addCityImagesGroupToFirestore(props.cityId, {
        name: values.name,
        images: [],
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo: any) {
      console.log({ errorInfo });
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
