/* eslint-disable no-console */
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  fetchCategoriesFromFirestore,
  fetchCitiesFromFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import { listenToCategories } from "../../../App/redux/data/category/categoryActions";
import { listenToCities } from "../../../App/redux/data/city/cityActions";
import { PackageSearchBox } from "../../../components/molecules";
import { HomePageWrapper } from "../HotelPage/HotelPage.style";

const HomePage: React.FC = () => {
  const { cities } = useSelector((state: any) => state.cities);
  const { categories } = useSelector((state: any) => state.categories);
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [],
  });
  userFirestoreCollection({
    query: () =>
      fetchCategoriesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCategories,
    deps: [],
  });
  return (
    <HomePageWrapper>
      <div className="scroll-full">
        <div className="standard-home-container">
          <div className="standard-full">
            <div className="">
              <div className=" full-height keep-scroll">
                <div className="bgimg bg-hotel"></div>
                <div className="heading">
                  <div className="flights-search-header">
                    <FormattedMessage
                      description="Choose your ideal package for you and your family"
                      defaultMessage="Choose your ideal package for you and your family"
                      id="j66BeF"
                    />
                    <br />
                    <span className="or-color">
                      <FormattedMessage
                        description="with one simple search!"
                        defaultMessage="with one simple search!"
                        id="64CsYY"
                      />
                    </span>
                  </div>
                  <div>
                    <PackageSearchBox cities={cities} categories={categories} />
                  </div>
                </div>
                <div className="space-footer"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <img src="/assets/gov_logos.jpg" alt="" />
        </div>
      </div>
    </HomePageWrapper>
  );
};

export default HomePage;
