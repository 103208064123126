import React from "react";
import { Card, Divider, Space, Statistic, Table } from "../../atoms";
import { DashboardWrapper } from "./Dashboard.style";

export interface ITopSellingProviders {
  provider: string;
  bookings: number;
  sales: number;
  commission: number;
}
export interface ITopSellingDestinations {
  destination: string;
  bookings: number;
  sales: number;
  commission: number;
}
export interface ILatestBooking {
  bookingId: number;
  packageName: string;
  provider: string;
  customer: string;
  dates: string;
}
export interface IDashboardProps {
  lifetimeBookings: number;
  lifetimeSales: number;
  lifetimeCommissions: number;
  currency: string;
  topSellingProviders: ITopSellingProviders[];
  topSellingDestinations: ITopSellingDestinations[];
  latestBooking: ILatestBooking[];
}
const columnsTopSelling = (type: "provider" | "destination") => [
  type === "provider"
    ? {
        title: "Provider",
        dataIndex: "provider",
        key: "provider",
      }
    : {
        title: "Destination",
        dataIndex: "destination",
        key: "destination",
      },
  {
    title: "Bookings",
    dataIndex: "bookings",
    key: "bookings",
  },
  {
    title: "Sales",
    dataIndex: "sales",
    key: "sales",
  },
  {
    title: "Commission",
    dataIndex: "commission",
    key: "commission",
  },
];
const columnsLatestBooking = [
  {
    title: "Booking ID",
    dataIndex: "bookingId",
    key: "bookingId",
  },
  {
    title: "Package",
    dataIndex: "packageName",
    key: "packageName",
  },
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
  },
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
  },
  {
    title: "Dates",
    dataIndex: "dates",
    key: "dates",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];

export const Dashboard: React.FC<IDashboardProps> = ({
  lifetimeBookings,
  lifetimeSales,
  lifetimeCommissions,
  currency,
  topSellingProviders,
  topSellingDestinations,
  latestBooking,
}) => (
  <DashboardWrapper>
    <div className="dashboard-info">
      <Space direction="vertical">
        <Card>
          <Statistic
            title="Lifetime number of bookings"
            value={lifetimeBookings}
            precision={2}
            valueStyle={{ color: "#3f8600" }}
            prefix={currency}
          />
        </Card>
        <Card>
          <Statistic
            title="Lifetime Sales"
            value={lifetimeSales}
            precision={2}
            valueStyle={{ color: "#3f8600" }}
            prefix={currency}
          />
        </Card>
        <Card>
          <Statistic
            title="Lifetime Commissions"
            value={lifetimeCommissions}
            precision={2}
            valueStyle={{ color: "#3f8600" }}
            prefix={currency}
          />
        </Card>
      </Space>
      <div>
        <Divider orientation="left">Top Selling Provider</Divider>
        <Table
          dataSource={topSellingProviders}
          columns={columnsTopSelling("provider")}
          scroll={{ x: 1300 }}
        />
      </div>
      <div>
        <Divider orientation="left">Top Selling Destination</Divider>
        <Table
          scroll={{ x: 1300 }}
          dataSource={topSellingDestinations}
          columns={columnsTopSelling("destination")}
        />
      </div>
    </div>
    <div>
      <Divider>Latest Booking</Divider>

      <Table
        dataSource={latestBooking}
        columns={columnsLatestBooking}
        scroll={{ x: 1300 }}
      />
    </div>
  </DashboardWrapper>
);
