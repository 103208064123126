import styled from "styled-components";
export interface IFlightLegWrapperProps {
  readonly isMobile: boolean;
}
export const FlightLegWrapper = styled.div<IFlightLegWrapperProps>`
  display: flex;
  position: relative;
  padding: 10px 0px;
  font-family: "Avenir";
  gap: ${({ isMobile }) => (isMobile ? "10px" : "30px")};
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  &:not(:last-child) {
    border-bottom: 1px solid #e9eef2;
  }
  .flight-leg {
    &-duration {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 10px;
      gap: 5px;
      &-time {
        color: #313e55;
        font-size: 13px;
        height: 25px;
      }
      &-line {
        border-top: 1px dashed #229dff;
        flex: 1;
      }
    }
    &-airline-logo {
      margin-top: 10px;
      text-align: center;
      img {
        height: 40px;
      }
    }
    &-airport {
      display: flex;
      flex-direction: column;
      text-align: center;
      &-time {
        color: #22335d;
        font-size: 18px;
        font-weight: 900;
      }
      &-day {
        color: #22335d;
        font-size: 14px;
      }
      &-name {
        color: #84868b;
        font-size: 18px;
      }
    }
    &-info {
      display: flex;
      gap: 30px;
      flex: 1;
    }
  }
`;
