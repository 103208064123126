/* eslint-disable no-console */
import moment from "moment";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ICategory, ICity } from "../../../App/common/typings";
import { getPackageDuration } from "../../../App/common/util/util";
import { packageIncludesOptions } from "../../../App/enums";
import { Tag } from "../../atoms";
import { PackageInfoWrapper } from "./PackageInfo.style";

export interface IPackageInfoProps {
  title: string;
  serviceInclude: string[];
  departureDate: string;
  returnDate: string;
  departureCity: ICity;
  categories: ICategory[];
}
export const PackageInfo: React.FC<IPackageInfoProps> = ({
  title,
  serviceInclude,
  departureDate,
  returnDate,
  departureCity,
  categories,
}) => {
  const { daysNights } = getPackageDuration(
    moment(departureDate),
    moment(returnDate)
  );
  return (
    <PackageInfoWrapper>
      <div className="package-info-header">
        <div>
          <div className="package-info-title">{title}</div>
          <Tag className="package-info-days" color="#108ee9">
            {daysNights}
          </Tag>
        </div>
      </div>
      <div className="package-info-days-types">
        <span className="package-info-type">
          {serviceInclude
            .map((f: string) => packageIncludesOptions[f]?.label || "")
            .join(" + ")}
        </span>
      </div>
      <div className="package-info-dates">
        <div className="package-info-box">
          <span className="package-info-box-title">
            <FormattedMessage
              description="Depart"
              defaultMessage="Depart"
              id="WBSwHh"
            />
          </span>
          <span className="package-info-box-value">
            {moment(departureDate).format("DD MMM YYYY")}
          </span>
        </div>
        <div className="package-info-box">
          <span className="package-info-box-title">
            <FormattedMessage
              description="Return"
              defaultMessage="Return"
              id="jZXpMi"
            />
          </span>
          <span className="package-info-box-value">
            {moment(returnDate).format("DD MMM YYYY")}
          </span>
        </div>
      </div>
      <div>
        <div className="package-info-box">
          <span className="package-info-box-title">
            <FormattedMessage
              description="Departure City"
              defaultMessage="Departure City"
              id="C1ui1J"
            />
          </span>
          <span className="package-info-box-date">{departureCity.name}</span>
        </div>
      </div>
      <div className="package-info-category">
        {categories.map(
          (cat: ICategory) =>
            (
              <Tag className="package-info-category-name" key={cat.id}>
                {" "}
                {cat.name}{" "}
              </Tag>
            ) || ""
        )}
      </div>
    </PackageInfoWrapper>
  );
};
