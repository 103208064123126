import styled from "styled-components";

// theme is now fully typed
export const CityImagesGroupsWrapper = styled.div`
  .ant-collapse-header {
    display: flex;
    align-items: center;
    .ant-typography {
      flex: 1;
      margin: 0px 20px;
    }
  }
`;
