/* eslint-disable no-console */
/* eslint-disable react/prop-types */
// import { Input, DatePicker, Select } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Popconfirm, Space } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
  IBooking,
  ICustomer,
  IPackageBooking,
} from "../../../App/common/typings";
import { Button, Input } from "../../atoms";
import { Table } from "../../atoms/Table/Table";
import { Person } from "../../molecules";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";

// const { Search } = Input;
// const { RangePicker } = DatePicker;
// const { Option } = Select;

export interface IBookingListProps {
  bookings: IBooking[] | any[];
  loading: boolean;
  searchBooking: (search: string) => void;
  filterBooking: () => void;
  addBooking: () => void;
  deleteBooking: (id: string) => void;
  sorting: (name: string, order: any) => void;
}

const BookingList: React.FC<IBookingListProps> = ({
  bookings,
  loading,
  searchBooking,
  filterBooking,
  addBooking,
  deleteBooking,
  sorting,
}) => {
  const handelOnSearch = () => {
    console.log(bookings);
  };
  const columnsBookingList = [
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      sorter: true,
      render: (v: number, record: IBooking) => (
        <Link to={`/admin/booking/${record.id}`}>PFH-{v}</Link>
      ),
      fixed: true,
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      sorter: true,
      render: (v: moment.Moment) => moment(v).format("DD/MM/YYYY"),
    },
    {
      title: "Package",
      dataIndex: "travelPackage",
      key: "travelPackage",
      render: (v: IPackageBooking) => v?.name || "-",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      sorter: true,
      key: "provider",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      sorter: true,
      render: (v: ICustomer) => <Person {...v} />,
    },
    {
      title: "Package Dates",
      dataIndex: "dates",
      sorter: true,
      key: "dates",
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      key: "status",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (v: any, record: IBooking) => (
        <Space size="middle">
          <Link to={`/admin/booking/${record.id}`}>
            <EditOutlined /> Edit
          </Link>
          <Popconfirm
            title="Are you sure？"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => deleteBooking(record?.id || "0")}
          >
            <Button type="link">
              {" "}
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleTableChange = (pagination: any, filters: any, sort: any) => {
    console.log(sort);
    if (sort.order === "descend") {
      sorting(sort.columnKey, "desc");
    }
    if (sort.order === "ascend") {
      sorting(sort.columnKey, "asc");
    }
  };
  return (
    <div>
      <TableHeader>
        <Button
          type="primary"
          onClick={addBooking}
          block
          icon={<PlusOutlined />}
        >
          Add Booking
        </Button>
        <Input.Search
          placeholder="Search"
          onSearch={handelOnSearch}
          style={{ flex: 1 }}
        />
      </TableHeader>
      <Table
        scroll={{ x: 1300 }}
        columns={columnsBookingList}
        dataSource={bookings}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default BookingList;
