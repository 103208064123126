/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { CityDetails } from "../../components/organisms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { IMatch } from "../../App/common/typings";
import { Tabs } from "../../components/atoms";
import { CityImagesGroups } from "../../components/organisms/City/CityImagesGroups";

interface IMatchParams {
  id: string;
}
export interface ICityViewProps {
  match: IMatch<IMatchParams>;
}

const CityView: React.FC<ICityViewProps> = ({ match }) => {
  const cityId = match.params.id;
  const { selectedCity } = useSelector((state: any) => state.cities);

  return (
    <AdminPage pageTitle={selectedCity?.name || "New City"}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Details" key="1">
          <CityDetails key={cityId} cityId={cityId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Images Groups" key="2">
          <CityImagesGroups key={cityId} cityId={cityId} />
        </Tabs.TabPane>
      </Tabs>
    </AdminPage>
  );
};

export default CityView;
