import React from "react";
import { PersonWrapper } from "./Person.style";
interface IPersonProp {
  name?: string;
  email?: string;
  telephone?: string;
}
export const Person: React.FC<IPersonProp> = ({ name, email, telephone }) => (
  <PersonWrapper>
    {name && <span className="person-name">{name}</span>}
    {email && <span className="person-email">{email}</span>}
    {telephone && <span className="person-phone">{telephone}</span>}
  </PersonWrapper>
);

export default Person;
