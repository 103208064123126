import { values } from "lodash";
interface IPackageIncludesMap {
  [key: string]: { label: string; value: string };
}
export const packageIncludesOptions: IPackageIncludesMap = {
  FLIGHTS: { label: "Πτήσεις", value: "FLIGHTS" },
  HOTELS: { label: "Ξενοδοχείο", value: "HOTELS" },
  CRUISES: { label: "Κρουαζιέρα", value: "CRUISES" },
  TRAINS: { label: "Τρένο", value: "TRAINS" },
  TOURS: { label: "Εκδρομές", value: "TOURS" },
  TRANSFERS: { label: "Μεταφορές", value: "TRANSFERS" },
};

export const packageServiceOptions = values(packageIncludesOptions);
