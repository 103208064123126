import styled from "styled-components";
export const PackageCruiseCabinWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 24px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  padding: 15px 15px;
  .package-cruise-cabin {
    &-cat {
      background-color: #0c3d70;
      width: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #fff;
      &-name {
        font-size: 14px;
      }
      &-char {
        font-size: 24px;
        font-weight: 900;
      }
    }
    &-body {
      width: 100%;
    }
    &-select {
      flex: 1;
      text-align: right;
      button {
        background-color: #0027ca;
        color: #fff;
        width: 90px;
        height: 32px;
        font-size: 14px;
      }
    }
  }
`;
