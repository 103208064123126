/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { HotelDetails } from "../../components/organisms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { IMatch } from "../../App/common/typings";
import { Tabs } from "../../components/atoms";
import { HotelImagesGroups } from "../../components/organisms/Hotel/HotelImages";

interface IMatchParams {
  id: string;
}
export interface IHotelViewProps {
  match: IMatch<IMatchParams>;
}

const HotelView: React.FC<IHotelViewProps> = ({ match }) => {
  const hotelId = match.params.id;
  const { selectedHotel } = useSelector((state: any) => state.hotels);

  return (
    <AdminPage pageTitle={selectedHotel?.name || "New Hotel"}>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Details" key="1">
          <HotelDetails key={hotelId} hotelId={hotelId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Images" key="2">
          <HotelImagesGroups key={hotelId} hotelId={hotelId} />
        </Tabs.TabPane>
      </Tabs>
    </AdminPage>
  );
};

export default HotelView;
