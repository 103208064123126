/* eslint-disable no-console */
import moment from "moment";
import cuid from "cuid";
import { chain, sortBy, minBy, maxBy } from "lodash";
import firebase from "../../config/firebase";
import {
  IPackage,
  IPackageInput,
  IPackageFlight,
  IPackageHotel,
  IImage,
  IPolicy,
  IQueryConfigArgs,
  ITour,
  ITransfer,
} from "../../common/typings";
import { defaultPackagePolicies } from "../../enums";
import { collectionWithArgs, db } from "./firestoreService";

const COLLECTION_PACKAGES = "packages";

export function fetchPackagesFromFirestore(
  args: Array<IQueryConfigArgs | null>
) {
  const ref = db.collection(COLLECTION_PACKAGES);
  return collectionWithArgs(ref, args || []);
}
export function listenToPackageFromFirestore(id: string) {
  return db.collection(COLLECTION_PACKAGES).doc(id);
}

export function addPackageToFirestore(doc: IPackageInput) {
  // const user = firebase.auth().currentPackage;
  const returnDestination = maxBy(doc.destinations || [], "checkOutDate");
  return db.collection(COLLECTION_PACKAGES).add({
    ...doc,
    packageProfit: doc.partnerId !== "" ? 10 : 20,
    returnDate: moment(returnDestination?.checkOutDate).toDate(),
    createDate: firebase.firestore.FieldValue.serverTimestamp(),
    departureDate: moment(doc.departureDate).toDate(),
    destinations: doc.destinations.map((destination) => ({
      ...destination,
      checkInDate: moment(destination.checkInDate).toDate(),
      checkOutDate: moment(destination.checkOutDate).toDate(),
    })),
    destinationIds: doc.destinations.map((destination) => destination.cityId),
    destinationCodes: doc.destinations.map(
      (destination) => destination.city?.code
    ),
    flights: [],
    hotels: [],
    policies: defaultPackagePolicies.map((policy) => ({
      ...policy,
      id: cuid(),
    })),
    // createBy: user,
  });
}

export async function updatePackageInFirestore(docId: string, doc: IPackage) {
  const hotels = await getPackageHotelInFirestore(docId);
  const returnDestination = maxBy(doc.destinations || [], "checkOutDate");
  return db
    .collection(COLLECTION_PACKAGES)
    .doc(docId)
    .update({
      ...doc,
      returnDate: moment(returnDestination?.checkOutDate).toDate(),
      departureDate: moment(doc.departureDate).toDate(),
      destinations: doc.destinations.map((destination) => ({
        ...destination,
        checkInDate: moment(destination.checkInDate).toDate(),
        checkOutDate: moment(destination.checkOutDate).toDate(),
      })),
      destinationIds: doc.destinations.map((destination) => destination.cityId),
      destinationCodes: doc.destinations.map(
        (destination) => destination.city?.code
      ),
      ...hotels,
    });
}

export async function duplicatePackageInFirestore(docId: string) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc) throw Error;

    return db.collection(COLLECTION_PACKAGES).add({
      ...doc,
      name: `${doc.name} Copy`,
      status: false,
    });
  } catch (err) {
    throw err;
  }
}
export async function updatePackageFlightInFirestore(
  docId: string,
  packageFlight: IPackageFlight
) {
  const hotels = await getPackageHotelInFirestore(
    docId,
    undefined,
    packageFlight.totalFlightPricePerAdult,
    packageFlight.totalFlightPricePerChild
  );
  return db
    .collection(COLLECTION_PACKAGES)
    .doc(docId)
    .update({
      ...packageFlight,
      flights: packageFlight.flights.map((flight) => ({
        ...flight,
        departureDate: moment(flight.departureDate).toDate(),
        arrivalDate: moment(flight.arrivalDate).toDate(),
      })),
      ...hotels,
    });
}

export function updatePackageImagesInFirestore(
  docId: string,
  images: IImage[]
) {
  return db.collection(COLLECTION_PACKAGES).doc(docId).update({
    images,
  });
}

export async function addPackageHotelInFirestore(
  docId: string,
  hotelPackage: IPackageHotel
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc) throw Error;
    if (!doc.hotels) doc.hotels = [];
    console.log({ doc });

    const currentHotels = [
      ...(doc.hotels.filter(
        (evt: IPackageHotel) => evt.id !== hotelPackage.id
      ) || []),
      hotelPackage,
    ].filter((evt: IPackageHotel) => doc.destinationIds.includes(evt.cityId));

    const hotels = await getPackageHotelInFirestore(docId, currentHotels);
    return db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .update({
        ...hotels,
      });
  } catch (err) {
    throw err;
  }
}

export async function updatePackageHotelInFirestore(
  docId: string,
  hotelPackage: IPackageHotel
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc || !doc.hotels) throw Error;

    const currentHotels = doc.hotels?.map((hotel: IPackageHotel) => {
      if (hotel.id === hotelPackage.id) {
        return hotelPackage;
      }
      return hotel;
    });
    const hotels = await getPackageHotelInFirestore(docId, currentHotels);
    return db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .update({
        ...hotels,
      });
  } catch (err) {
    throw err;
  }
}
export async function deletePackageHotelInFirestore(
  docId: string,
  hotelPackageId: string
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc) throw Error;

    return db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .update({
        hotels: [
          ...doc.hotels.filter(
            (evt: IPackageHotel) => evt.id !== hotelPackageId
          ),
        ],
      });
  } catch (err) {
    throw err;
  }
}

export async function addPackagePolicyInFirestore(
  docId: string,
  policy: IPolicy
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc) throw Error;
    if (!doc.policies) doc.policies = [];
    return db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .update({
        policies: [
          ...(doc.policies.filter((evt: IPolicy) => evt.id !== policy.id) ||
            []),
          policy,
        ],
      });
  } catch (err) {
    throw err;
  }
}
export async function updatePackagePolicyInFirestore(
  docId: string,
  policy: IPolicy
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc || !doc.policies) throw Error;

    const policies = doc.policies?.map((currentPolicy: IPolicy) => {
      if (currentPolicy.id === policy.id) {
        return policy;
      }
      return currentPolicy;
    });
    return db.collection(COLLECTION_PACKAGES).doc(docId).update({
      policies,
    });
  } catch (err) {
    throw err;
  }
}
export async function deletePackagePolicyInFirestore(
  docId: string,
  policyId: string
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc) throw Error;

    return db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .update({
        policies: [
          ...doc.policies.filter((evt: IPolicy) => evt.id !== policyId),
        ],
      });
  } catch (err) {
    throw err;
  }
}

export async function updatePackageToursInFirestore(
  docId: string,
  tours: ITour[]
) {
  try {
    const hotels = await getPackageHotelInFirestore(
      docId,
      undefined,
      undefined,
      undefined,
      tours
    );
    return db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .update({
        tours,
        ...hotels,
      });
  } catch (err) {
    throw err;
  }
}
export async function updatePackageTransfersInFirestore(
  docId: string,
  transfers: ITransfer[]
) {
  try {
    const hotels = await getPackageHotelInFirestore(
      docId,
      undefined,
      undefined,
      undefined,
      undefined,
      transfers
    );
    return db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .update({
        transfers,
        ...hotels,
      });
  } catch (err) {
    throw err;
  }
}
export async function deletePackageInFirestore(docId: string) {
  return db.collection(COLLECTION_PACKAGES).doc(docId).delete();
}

export function activeTogglePackageInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_PACKAGES).doc(docId).update({
    status,
  });
}

export async function getPackageHotelInFirestore(
  docId: string,
  currentHotels?: IPackageHotel[],
  pricePerAdult?: number,
  PricePerChild?: number,
  toursPrices?: ITour[],
  transfersPrices?: ITransfer[]
) {
  try {
    const currentPackage = await db
      .collection(COLLECTION_PACKAGES)
      .doc(docId)
      .get();
    const doc = currentPackage.data();
    if (!doc) throw Error;

    const totalFlightPricePerAdult: number =
      pricePerAdult || doc.totalFlightPricePerAdult || 0;
    const totalFlightPricePerChild: number =
      PricePerChild || doc.totalFlightPricePerChild || 0;
    const numberOfDestinations: number = doc.destinations.length || 1;
    // eslint-disable-next-line prettier/prettier
    const profit: number  = 1 - ((doc.packageProfit / numberOfDestinations) / 100);

    const flightPricePerAdult = totalFlightPricePerAdult / numberOfDestinations;
    const flightPricePerChild = totalFlightPricePerChild / numberOfDestinations;
    const totalCostToursPerAdult = chain(toursPrices || doc.tours || [])
      .reduce((sum, n: ITour) => sum + +n.adultPrice, 0)
      .value();
    const totalCostToursPerChild = chain(toursPrices || doc.tours || [])
      .reduce((sum, n: ITour) => sum + +n.childPrice, 0)
      .value();
    const totalCostTransfersPerAdult = chain(
      transfersPrices || doc.transfers || []
    )
      .reduce((sum, n: ITour) => sum + +n.adultPrice, 0)
      .value();
    const totalCostTransfersPerChild = chain(
      transfersPrices || doc.transfers || []
    )
      .reduce((sum, n: ITour) => sum + +n.childPrice, 0)
      .value();
    const hotels = (currentHotels || doc.hotels)?.map(
      (hotel: IPackageHotel) => ({
        ...hotel,
        singleRoomPricePublic:
          hotel.singleRoomPrice > 0
            ? Math.ceil(
                (Number(hotel.singleRoomPrice) +
                  +flightPricePerAdult +
                  +totalCostToursPerAdult +
                  +totalCostTransfersPerAdult) /
                  profit
              )
            : 0,
        doubleRoomPricePublic: Math.ceil(
          (+hotel.doubleRoomPrice +
            Number(flightPricePerAdult) +
            +totalCostToursPerAdult +
            +totalCostTransfersPerAdult) /
            profit
        ),
        tripleRoomPricePublic:
          hotel.tripleRoomPrice > 0
            ? Math.ceil(
                (Number(hotel.tripleRoomPrice) +
                  flightPricePerAdult +
                  +totalCostToursPerAdult +
                  +totalCostTransfersPerAdult) /
                  profit
              )
            : 0,
        quadrupleRoomPricePublic:
          hotel.quadrupleRoomPrice > 0
            ? Math.ceil(
                (Number(hotel.quadrupleRoomPrice) +
                  flightPricePerAdult +
                  +totalCostToursPerAdult +
                  +totalCostTransfersPerAdult) /
                  profit
              )
            : 0,
        firstChildPricePublic:
          hotel.firstChildPrice > 0
            ? Math.ceil(
                (Number(hotel.firstChildPrice) +
                  +flightPricePerChild +
                  +totalCostToursPerChild +
                  +totalCostTransfersPerChild) /
                  profit
              )
            : 0,
        secondChildPricePublic:
          hotel.secondChildPrice > 0
            ? Math.ceil(
                (Number(hotel.secondChildPrice) +
                  flightPricePerChild +
                  +totalCostToursPerChild +
                  +totalCostTransfersPerChild) /
                  profit
              )
            : 0,
      })
    );

    console.log(
      hotels,
      flightPricePerAdult,
      totalCostToursPerAdult,
      profit,
      totalCostTransfersPerAdult,
      doc.hotels
    );
    console.log(
      Math.ceil(
        (281 +
          Number(flightPricePerAdult) +
          +totalCostToursPerAdult +
          +totalCostTransfersPerAdult) /
          profit
      ),
      Number(flightPricePerAdult),
      +totalCostToursPerAdult,
      +totalCostTransfersPerAdult,
      profit
    );
    const cheapestHotelsSort = sortBy(hotels, "doubleRoomPricePublic");
    const minPricePerAdult =
      chain(cheapestHotelsSort)
        .groupBy("cityId")
        .map((hotel: any) => minBy(hotel, "doubleRoomPricePublic"))
        .reduce((sum, n: any) => sum + Number(n.doubleRoomPricePublic), 0)
        .value() || [];

    return {
      hotels: cheapestHotelsSort,
      minPricePerAdult,
    };
  } catch (err) {
    throw err;
  }
}

// export async function migrateFieldToArrays() {
//   const snapshot = await db.collection(COLLECTION_PACKAGES).get();
//   const batch = db.batch();
//   let batchCount = 0;
//   const batchSize = 500; // Firestore batch limit

//   // eslint-disable-next-line @typescript-eslint/no-misused-promises
//   snapshot.forEach(async (doc) => {
//     const data = doc.data();
//     const partnerId = data.partnerId;

//     if (typeof partnerId === "string") {
//       const docRef = db.collection(COLLECTION_PACKAGES).doc(doc.id);
//       batch.update(docRef, {
//         partnerId: [partnerId],
//       });
//       batchCount++;

//       // Commit batch every 500 updates
//       if (batchCount === batchSize) {
//         await batch.commit();
//         batchCount = 0;
//       }
//     }
//   });

//   // Commit any remaining updates
//   if (batchCount > 0) {
//     await batch.commit();
//   }

//   console.log("Migration completed.");
// }

// migrateFieldToArrays().catch((error) => {
//   console.error("Error during migration:", error);
// });
