import { values } from "lodash";

export const packagePolicies = {
  INCLUDE: {
    name: "Συμπεριλαμβάνεται",
    description: `<ul><li>Πτήσεις όπως αναφέρονται στη προσφορά.</li>
    <li>Διαμονή σε ξενοδοχείο της επιλογής σας.</li>
    <li>Φόροι πτήσεων και ξενοδοχείων.</li>
    <li>Σακίδιο πλάτης 40x30x20 εκατοστά ανά άτομο.</li></ul>`,
    status: false,
  },
  NO_INCLUDE: {
    name: "Δεν Συμπεριλαμβάνεται",
    description: `<ul><li>Ταξιδιωτική Ασφάλιση.&nbsp;</li>
    <li>Βαλίτσα καμπίνας 10kg, €25 ανά διαδρομή.</li>
    <li>Βαλίτσα 20kg, €36 ανά διαδρομή.</li>
    <li>Μεταφορές από και προς αεροδρόμιο.</li>
    <li>Δημοτικός φόρος (όπου επιβάλλεται).</li>
    <li>Προαιρετικές Εκδρομές.</li>
    <li>Οτιδήποτε δεν αναφέρεται σαν συμπεριλαμβανόμενο.</li>
    </ul>`,
    status: false,
  },
  TERMS_CONDITION: {
    name: "Όροι και προϋποθέσεις",
    description: `<ul><li>Οι τιμές των πακέτων υπόκεινται σε διαθεσιμότητα κι ενδέχεται αν αλλάξουν την ώρα της κράτησης.&nbsp;</li>
    <li>&nbsp;Η πληρωμή ολοκληρώνεται την ώρα της κράτησης με κάρτα μέσω πληρωμών διαδυκτίου της JCC.&nbsp;</li>
    <li>Βεβαιωθείτε πως θα χρησιμοποιήσετε τις υπηρεσίες τις οποίες αγοράζετε καθώς το πακέτο ενδέχεται να μην ακυρώνεται ή να τροποποιείται.&nbsp;</li></ul>`,
    status: false,
  },
};

export const defaultPackagePolicies = values(packagePolicies);
