/* eslint-disable no-console */
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from "../async/asyncReducer";
// import {
//   // fetchProvidersFromFirestore,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
import { IProvider } from "../../../common/typings";
import {
  CREATE_PROVIDER,
  UPDATE_PROVIDER,
  DELETE_PROVIDER,
  FETCH_PROVIDERS,
  LISTEN_TO_SELECTED_PROVIDER,
  CLEAR_PROVIDERS,
  CLEAR_SELECTED_PROVIDER,
} from "./providerConstants";

// export function fetchProviders() {
//   return async function (dispatch: IDispatch<any>) {
//     dispatch(asyncActionStart());
//     try {
//       // const snapshot = await fetchProvidersFromFirestore(
//       //   filter,
//       //   startDate,
//       //   limit,
//       //   lastDocSnapshot
//       // ).get();
//       // const lastVisible = snapshot.docs[snapshot.docs.length - 1];
//       // const moreProviders = snapshot.docs.length >= limit;
//       // const providers = snapshot.docs.map((doc) => dataFromSnapshot(doc));
//       // dispatch({
//       //   type: FETCH_PROVIDERS,
//       //   payload: { providers, moreProviders, lastVisible },
//       // });
//       dispatch(asyncActionFinish());
//     } catch (error) {
//       dispatch(asyncActionError(error));
//     }
//   };
// }

export function listenToProviders(providers: IProvider[]) {
  return {
    type: FETCH_PROVIDERS,
    payload: providers,
  };
}

// export function setFilter(value) {
//   return function (dispatch) {
//     dispatch(clearProviders());
//     dispatch({ type: SET_FILTER, payload: value });
//   };
// }

// export function setStartDate(date) {
//   return function (dispatch) {
//     dispatch(clearProviders());
//     dispatch({ type: SET_START_DATE, payload: date });
//   };
// }

export function listenToSelectedProvider(provider: IProvider) {
  return {
    type: LISTEN_TO_SELECTED_PROVIDER,
    payload: provider,
  };
}

export function clearSelectedProvider() {
  return {
    type: CLEAR_SELECTED_PROVIDER,
  };
}

export function createProvider(provider: IProvider) {
  return {
    type: CREATE_PROVIDER,
    payload: provider,
  };
}

export function updateProvider(provider: IProvider) {
  return {
    type: UPDATE_PROVIDER,
    payload: provider,
  };
}

export function deleteProvider(providerId: string) {
  return {
    type: DELETE_PROVIDER,
    payload: providerId,
  };
}

export function clearProviders() {
  return {
    type: CLEAR_PROVIDERS,
  };
}
