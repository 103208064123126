import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import ReactGA from "react-ga4";
// import English from "./lang/en.json";
import Greek from "./lang/el.json";
import DashApp from "./DashApp";
import reportWebVitals from "./reportWebVitals";

const locale = "el"; // navigator.language;
// Hot Module Replacement API

const lang = Greek;
// if (locale === "en") {
//   lang = English;
// } else {
//   if (locale === "el") {
//     lang = Greek;
//   } else {
//     lang = English;
//   }
// }
const rootEl = document.getElementById("root");
ReactGA.initialize("G-K7S090S8BB");
const render = () => {
  ReactDOM.render(
    <IntlProvider locale={locale} messages={lang}>
      <DashApp />
    </IntlProvider>,
    rootEl
  );
};

if (module.hot) {
  module.hot.accept("./DashApp", () => {
    setTimeout(render);
  });
}

render();
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};
// if (module.hot) {
//   module.hot.accept('./DashApp', () => {
//     const NextApp = require('./DashApp').default
//     ReactDOM.render( <React.StrictMode>
//           <NextApp />
//       </React.StrictMode>, rootEl)  // eslint-disable-line
//   })
// }else{
// ReactDOM.render(<DashApp />, document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
