import cuid from "cuid";
import React from "react";
import { useDispatch } from "react-redux";
import { ModalWrapper } from "../../../App/common/modals/ModalWrapper";
import { IPolicy } from "../../../App/common/typings";
import { addPackagePolicyInFirestore } from "../../../App/firestore/firestoreService";
import { closeModal } from "../../../App/redux/data/modals/modalReducer";
import { Alert, Form, Input } from "../../atoms";

export const AddPackagePolicyForm: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleSubmit = async () => {
    try {
      const values: IPolicy = await form.validateFields();
      setLoading(true);

      await addPackagePolicyInFirestore(props.packageId, {
        name: values.name,
        description: "",
        status: false,
        id: cuid(),
      });

      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form
        form={form}
        name="horizontal_login"
        layout="vertical"
        initialValues={props.hotelPackage || {}}
      >
        <Form.Item
          name="name"
          label="Policy Name"
          rules={[{ required: true, message: "Missing Policy Name" }]}
        >
          <Input placeholder="Policy Name" />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
