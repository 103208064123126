import {
  ICategory,
  ICategoryInput,
  IQueryConfigArgs,
} from "../../common/typings";
import { db, collectionWithArgs } from "./firestoreService";

const COLLECTION_CATEGORIES = "categories";

export function fetchCategoriesFromFirestore(
  args?: Array<IQueryConfigArgs | null>
) {
  const ref = db.collection(COLLECTION_CATEGORIES);
  return collectionWithArgs(ref, args || []);
}
export function listenToCategoryFromFirestore(id: string) {
  return db.collection(COLLECTION_CATEGORIES).doc(id);
}

export function addCategoryToFirestore(doc: ICategoryInput) {
  // const user = firebase.auth().currentUser;
  return db.collection(COLLECTION_CATEGORIES).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateCategoryInFirestore(docId: string, doc: ICategory) {
  return db.collection(COLLECTION_CATEGORIES).doc(docId).update(doc);
}

export async function deleteCategoryInFirestore(docId: string) {
  return db.collection(COLLECTION_CATEGORIES).doc(docId).delete();
}

export function activeToggleCategoryInFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_CATEGORIES).doc(docId).update({
    status,
  });
}
