import styled from "styled-components";

// theme is now fully typed
export const HotelDetailsWrapper = styled.div`
  .hotel-details-space {
    display: flex;
    .ant-space {
      &-item {
        flex: 1;
      }
    }
  }
`;
