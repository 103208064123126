export const CREATE_HOTEL = "CREATE_HOTEL";
export const UPDATE_HOTEL = "UPDATE_HOTEL";
export const DELETE_HOTEL = "DELETE_HOTEL";
export const FETCH_HOTELS = "FETCH_HOTELS";
export const LISTEN_TO_HOTEL_CHAT = "LISTEN_TO_HOTEL_CHAT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_HOTEL = "LISTEN_TO_SELECTED_HOTEL";
export const CLEAR_SELECTED_HOTEL = "CLEAR_SELECTED_HOTEL";
export const CLEAR_HOTELS = "CLEAR_HOTELS";
export const SET_FILTER = "SET_FILTER";
export const SET_START_DATE = "SET_START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
export const LISTEN_TO_HOTEL_IMAGES = "LISTEN_TO_HOTEL_IMAGES";
