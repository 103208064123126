/* eslint-disable no-console */
import { Result } from "antd";
import React from "react";
import styled from "styled-components";
import { IMatch } from "../../../App/common/typings";
import { urlStringToOBject } from "../../../App/common/util/util";
interface IMatchParams {
  params: string;
}

export const PackageBookingErrorWrapper = styled.div`
  .ant-result-subtitle {
    color: rgb(255 77 78);
    font-size: 22px;
    line-height: 1.6;
    text-align: center;
  }
`;
export interface IPackageBookingErrorProps {
  match: IMatch<IMatchParams>;
}
const PackageBookingError: React.FC<IPackageBookingErrorProps> = ({
  match,
}) => {
  const params = urlStringToOBject(match.params.params || "");
  return (
    <PackageBookingErrorWrapper>
      <Result
        status="error"
        title="Transition Failed"
        subTitle={params["reason-code-desc"]}
      />
    </PackageBookingErrorWrapper>
  );
};

export default PackageBookingError;
