import React from "react";
import { Link } from "react-router-dom";
import { LogoWrapper } from "./Logo.style";

interface IProps {
  isPasydy?: boolean;
}
export const Logo: React.FC<IProps> = ({ isPasydy }) => (
  <LogoWrapper>
    <Link
      to={
        isPasydy
          ? "/pasydy-packages/from=all&to=all&when=all&category=all"
          : "/"
      }
    >
      <img src="/assets/poupas_logo_w.svg" alt="" />
    </Link>
  </LogoWrapper>
);
