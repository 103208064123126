import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import cuid from "cuid";
import { Rate } from "antd";
import { find } from "lodash";
import { countriesOptions } from "../../../App/enums";
import { ModalWrapper } from "../../../App/common/modals/ModalWrapper";
import { Alert, Form, Input, Select } from "../../atoms";
import { IHotelInput } from "../../../App/common/typings";
import { closeModal } from "../../../App/redux/data/modals/modalReducer";
import {
  addHotelToFirestore,
  fetchCitiesFromFirestore,
} from "../../../App/firestore/firestoreService";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import { listenToCities } from "../../../App/redux/data/city/cityActions";

export interface IHotelData extends IHotelInput {
  countryId: string;
  cityId: string;
}

export const AddHotelForm: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { cities } = useSelector((state: any) => state.cities);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "filter",
          name: "countryCode",
          opr: "==",
          value: countryCode,
        },
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch, countryCode],
  });
  const handleSubmit = async () => {
    try {
      const values: IHotelData = await form.validateFields();
      setLoading(true);
      const city = find(cities, ["id", values.cityId]);
      const hotel = await addHotelToFirestore({
        name: values.name,
        starRating: values.starRating || 0,
        address: {
          id: cuid(),
          city,
          cityId: values.cityId,
          countryCode: values?.countryCode,
        },
        status: true,
        topHotel: false,
      });
      setLoading(false);
      dispatch(closeModal());
      history.push(`/admin/hotel/${hotel.id}`);
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Missing Name" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="starRating" label="Rating">
          <Rate />
        </Form.Item>
        <Form.Item
          label="Country"
          name="countryCode"
          rules={[{ required: true, message: "Missing Country" }]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={countriesOptions}
            onChange={(record: string) => setCountryCode(record)}
          ></Select>
        </Form.Item>
        <Form.Item
          label="City"
          name="cityId"
          rules={[{ required: true, message: "Missing City" }]}
        >
          <Select
            showSearch
            placeholder="Select a City"
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={cities.map(({ name, id }: any) => ({
              value: id,
              label: name,
            }))}
          ></Select>
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
