import React from "react";
import { AdminPage } from "../../../App/layout/AdminPage/AdminPage";
import { UserDetails } from "../../../components/organisms";

const user = {
  id: 1,
  firstName: "text",
  lastName: "text",
  phoneNumber: "text",
  email: "text",
  createDate: "text",
  status: true,
  lastLogin: "text",
};
const UserView: React.FC = () => (
  <AdminPage pageTitle="User Name">
    <UserDetails user={user} />
  </AdminPage>
);

export default UserView;
