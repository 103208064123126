import moment from "moment";
import React from "react";
import { IPassenger } from "../../../App/common/typings";
import { PassengersWrapper } from "./Passengers.style";
interface IPassengerProps {
  passengers: IPassenger[];
}
export const Passengers: React.FC<IPassengerProps> = ({ passengers }) => (
  <PassengersWrapper>
    <div className="passengers-body">
      <div className="passengers-container">
        <div className="passengers-label">Type</div>
        <div className="passengers-label">Prefix</div>
        <div className="passengers-label">First Name</div>
        <div className="passengers-label">Last Name</div>
        <div className="passengers-label">Date Of Birth</div>
      </div>
      {passengers.map((passenger, i) => (
        <div className="passengers-container" key={i}>
          <div className="passengers-field">{passenger.type}</div>
          <div className="passengers-field">{passenger.title}</div>
          <div className="passengers-field">{passenger.firstName}</div>
          <div className="passengers-field">{passenger.lastName}</div>
          <div className="passengers-field">
            {moment(passenger.dateOfBirth).format("DD/MM/YYYY")}
          </div>
        </div>
      ))}
    </div>
  </PassengersWrapper>
);
