import React from "react";
import { AdminPage } from "../../../App/layout/AdminPage/AdminPage";
import { UserList } from "../../../components/organisms";

const Users = () => (
  <AdminPage pageTitle="My Team">
    <UserList users={[]} />
  </AdminPage>
);

export default Users;
