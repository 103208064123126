import styled from "styled-components";
export const PriceBoxWrapper = styled.div`
  border: 1px solid #d5d8d9;
  width: 95px;
  height: 96px;
  .price-box {
    &-title {
      background-color: #0c3d71;
      color: #fff;
      font-size: 10px;
      line-height: 22px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-body {
      text-align: center;
      display: flex;
      flex-direction: column;
      padding: 5px 5px;
    }
    &-price {
      color: #5f6e87;
      font-size: 16px;
      font-family: "Avenir";
    }
    &-des {
      color: #0eb381;
      font-size: 10px;
    }
    &-select {
      background-color: #b3c1d0;
      color: #fff;
      text-align: center;
      width: 100%;
      border-radius: 0;
      font-size: 10px;
      height: 22px;
      padding: 0;
    }
  }
`;
