import styled from "styled-components";

// theme is now fully typed
export const DestinationsFieldWrapper = styled.div`
  .destinations-field {
    &-destination {
      display: grid;
      grid-template-columns: 1fr 50px;
      justify-content: center;
      align-items: center;
      & :only-child {
        grid-template-columns: 1fr;
      }
    }
    &-space {
      display: flex;
      .ant-space {
        &-item {
          flex: 1;
        }
      }
    }
  }
`;
