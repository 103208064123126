/* eslint-disable no-console */
import React from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { IBooking, ILuggage } from "../../../App/common/typings";
import { Form } from "../../atoms/Form/Form";
import { Cart, CartTotalBtn, ItemNotFound, Loader } from "../../molecules";
import {
  listenToBookingFromFirestore,
  updateBookingInFirestore,
} from "../../../App/firestore/firestoreService";
import { listenToSelectedBooking } from "../../../App/redux/data/booking/bookingActions";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { jccConvertAmount } from "../../../App/common/util/util";
import { Checkbox } from "../../atoms";
import { PackageBookingFrontEndWrapper } from "./PackageBookingFrontEnd.style";
import BookingContactInformation from "./BookingContactInformation";
import BookingPassenger from "./BookingPassenger";
import { BookingComment } from "./BookingComment";

export interface IPackageBookingFrontEndProps {
  bookingId: string;
}
const defaultLuggages: ILuggage = {
  numberOf10KGLuggage: 0,
  numberOf20KGLuggage: 0,
  price20KGLuggage: 0,
  price10KGLuggage: 0,
};
export const PackageBookingFrontEnd: React.FC<IPackageBookingFrontEndProps> = ({
  bookingId,
}) => {
  const dispatch = useDispatch();
  const formRef = React.useRef<any>();
  // const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  // const isMobile = useMediaQuery({ query: "(max-width: 500px)" });
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { loading, error } = useSelector((state: any) => state.async);
  const [showCart, setShowCart] = React.useState(true);
  const [form] = Form.useForm();

  // Pad the purchase amount with 0's so that the total length is 13 characters, i.e. 20.50 will become 0000000020.50
  // const purchaseAmtPadStart = purchaseAmt.padStart(13, "0");
  // Remove the dot (.) from the padded purchase amount(JCC will know from currency exponent which is sent along, not in the signature, how many digits to consider as decimal) //0000000020.50 will become 000000002050 (notice there is no dot)
  // const formattedPurchaseAmt = purchaseAmtPadStart.substring(0, 10).substring(11);
  const handelShowCart = () => {
    setShowCart((v) => !v);
  };
  const handleOnFinish = async () => {
    try {
      const values: IBooking = await form.validateFields();
      const passengers = values.passengers.map((passenger) => ({
        ...passenger,
        dateOfBirth: moment(passenger.dateOfBirth).format("YYYY-MM-DD"),
      }));
      await updateBookingInFirestore(bookingId, {
        ...selectedBooking,
        id: bookingId,
        paymentStatus: "IN_PROGRESS",
        comment: values.comment || "",
        customer: values.customer,
        passengers,
      });
      formRef.current.submit();
      // history.push(`/booking-confirm/${bookingId}`);
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: bookingId !== "new",
  });

  React.useEffect(() => form.resetFields);
  React.useEffect(() => {
    if (!isTabletOrMobile) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
  }, [isTabletOrMobile]);
  if (loading && !selectedBooking?.signature) {
    return <Loader color="success" title="Loading" />;
  }
  if (error) {
    return (
      <ItemNotFound
        redirectTo=""
        redirectBtnText=""
        itemType="Booking"
        itemId={bookingId}
      />
    );
  }
  console.log(selectedBooking?.travelPackage);
  return (
    <PackageBookingFrontEndWrapper>
      <Form form={form} layout="vertical" initialValues={selectedBooking}>
        <div className="package-view-frontend-body">
          <div className="package-view-frontend-left">
            {isTabletOrMobile && (
              <Cart
                hotels={selectedBooking?.packageHotels || []}
                rooms={selectedBooking?.rooms || []}
                loading={false}
                flights={selectedBooking?.flights || []}
                onShowCart={handelShowCart}
                isTabletOrMobile={isTabletOrMobile}
                luggages={selectedBooking?.luggages || defaultLuggages}
                noScrolling
              />
            )}
            <BookingContactInformation partnerId={selectedBooking?.partnerId} />
            <Form.List name="passengers">
              {(fields, values, ff) =>
                fields.map(({ key, name, ...restField }, index) => (
                  <BookingPassenger
                    name={name}
                    key={key}
                    departureDated={selectedBooking.travelPackage.departureDate}
                    type={selectedBooking.passengers[name]?.type || "ADULT"}
                    roomName={selectedBooking.passengers[name]?.roomName || ""}
                  />
                ))
              }
            </Form.List>
            <BookingComment />
            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("Should accept agreement")),
                },
              ]}
            >
              <Checkbox>
                <FormattedMessage
                  description="I have read the"
                  defaultMessage="I have read the"
                  id="zAlta4"
                />{" "}
                <Link to="/terms" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage
                    description="Terms and Conditions"
                    defaultMessage="Terms and Conditions"
                    id="T9dfsO"
                  />
                </Link>
              </Checkbox>
            </Form.Item>
            <CartTotalBtn
              submit={handleOnFinish}
              hotels={selectedBooking?.packageHotels || []}
              rooms={selectedBooking?.rooms || []}
              loading={loading}
              luggages={selectedBooking?.luggages}
            />
            <div style={{ height: 200 }}></div>
          </div>

          <div
            className={classNames("package-view-frontend-right", {
              hide: showCart,
            })}
          >
            {!isTabletOrMobile && (
              <Cart
                hotels={selectedBooking?.packageHotels || []}
                rooms={selectedBooking?.rooms || []}
                loading={false}
                flights={selectedBooking?.flights || []}
                onShowCart={handelShowCart}
                isTabletOrMobile={isTabletOrMobile}
                luggages={selectedBooking?.luggages || defaultLuggages}
              />
            )}
          </div>
        </div>
      </Form>

      <form
        method="post"
        name="paymentForm"
        id="paymentForm"
        ref={(ref) => (formRef.current = ref)}
        action="https://jccpg.jccsecure.com/EcomPayment/RedirectAuthLink"
      >
        <input type="hidden" name="Version" value="1.0.0" />
        <input type="hidden" name="MerID" value="0030398036" />
        <input type="hidden" name="AcqID" value="402971" />
        <input
          type="hidden"
          name="MerRespURL"
          value={`https://us-central1-poupas-9e426.cloudfunctions.net/jccResponse/${bookingId}`}
        />
        <input
          type="hidden"
          name="PurchaseAmt"
          value={jccConvertAmount(selectedBooking?.total || 0)}
        />
        <input type="hidden" name="PurchaseCurrency" value="978" />
        <input type="hidden" name="PurchaseCurrencyExponent" value="2" />
        <input type="hidden" name="OrderID" value={bookingId} />
        <input type="hidden" name="CaptureFlag" value="A" />
        <input
          type="hidden"
          name="Signature"
          value={selectedBooking?.signature || ""}
        />
        <input type="hidden" name="SignatureMethod" value="SHA1" />
      </form>
    </PackageBookingFrontEndWrapper>
  );
};
