import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "poupas-9e426.firebaseapp.com",
  projectId: "poupas-9e426",
  storageBucket: "poupas-9e426.appspot.com",
  messagingSenderId: "150574896787",
  appId: "1:150574896787:web:e7541dfe89feb11c331f66",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
export default firebase;
