import { IHotel } from "../../../common/typings";
import {
  CREATE_HOTEL,
  UPDATE_HOTEL,
  DELETE_HOTEL,
  FETCH_HOTELS,
  CLEAR_COMMENTS,
  LISTEN_TO_SELECTED_HOTEL,
  CLEAR_HOTELS,
  SET_FILTER,
  SET_START_DATE,
  RETAIN_STATE,
  LISTEN_TO_HOTEL_IMAGES,
  CLEAR_SELECTED_HOTEL,
} from "./hotelConstants";

const initialState = {
  hotels: [],
  images: [],
  moreHotels: true,
  selectedHotel: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function hotelReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_HOTEL:
      return {
        ...state,
        hotels: [...state.hotels, payload],
      };
    case UPDATE_HOTEL:
      return {
        ...state,
        hotels: [
          ...state.hotels.filter((evt: IHotel) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_HOTEL:
      return {
        ...state,
        hotels: [...state.hotels.filter((evt: IHotel) => evt.id !== payload)],
      };
    case FETCH_HOTELS:
      return {
        ...state,
        hotels: payload,
        moreHotels: payload.moreHotels,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_HOTEL_IMAGES:
      return {
        ...state,
        images: payload,
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_HOTEL:
      return {
        ...state,
        selectedHotel: payload,
      };
    case CLEAR_SELECTED_HOTEL:
      return {
        ...state,
        selectedHotel: null,
      };
    case CLEAR_HOTELS:
      return {
        ...state,
        hotels: [],
        moreHotels: true,
        lastVisible: null,
      };
    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        moreHotels: true,
        filter: payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        moreHotels: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
