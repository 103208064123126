import React from "react";
import { AdminPage } from "../../../App/layout/AdminPage/AdminPage";
import { ProviderList } from "../../../components/organisms";

const Providers: React.FC = () => (
  <AdminPage pageTitle="Provider List">
    <ProviderList providers={[]} />
  </AdminPage>
);

export default Providers;
