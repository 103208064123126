import { IProvider, IProviderInput } from "../../common/typings";
import { db } from "./firestoreService";

const COLLECTION_PROVIDERS = "providers";

export function fetchProvidersFromFirestore() {
  return db.collection(COLLECTION_PROVIDERS);
}
export function listenToProviderFromFirestore(id: string) {
  return db.collection(COLLECTION_PROVIDERS).doc(id);
}

export function addProviderToFirestore(doc: IProviderInput) {
  // const user = firebase.auth().currentUser;
  return db.collection(COLLECTION_PROVIDERS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateProviderInFirestore(docId: string, doc: IProvider) {
  return db.collection(COLLECTION_PROVIDERS).doc(docId).update(doc);
}

export async function deleteProviderInFirestore(docId: string) {
  return db.collection(COLLECTION_PROVIDERS).doc(docId).delete();
}

export function activeToggleProviderInFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_PROVIDERS).doc(docId).update({
    status,
  });
}
