/* eslint-disable no-console */
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from "../async/asyncReducer";
// import {
//   // fetchHotelsFromFirestore,
//   dataFromSnapshot,
// } from "../../../firestore/firestoreService";
import { IHotel, IImage } from "../../../common/typings";
import {
  CREATE_HOTEL,
  UPDATE_HOTEL,
  DELETE_HOTEL,
  FETCH_HOTELS,
  LISTEN_TO_SELECTED_HOTEL,
  LISTEN_TO_HOTEL_IMAGES,
  CLEAR_HOTELS,
  CLEAR_SELECTED_HOTEL,
} from "./hotelConstants";

// export function fetchHotels() {
//   return async function (dispatch: IDispatch<any>) {
//     dispatch(asyncActionStart());
//     try {
//       // const snapshot = await fetchHotelsFromFirestore(
//       //   filter,
//       //   startDate,
//       //   limit,
//       //   lastDocSnapshot
//       // ).get();
//       // const lastVisible = snapshot.docs[snapshot.docs.length - 1];
//       // const moreHotels = snapshot.docs.length >= limit;
//       // const hotels = snapshot.docs.map((doc) => dataFromSnapshot(doc));
//       // dispatch({
//       //   type: FETCH_HOTELS,
//       //   payload: { hotels, moreHotels, lastVisible },
//       // });
//       dispatch(asyncActionFinish());
//     } catch (error) {
//       dispatch(asyncActionError(error));
//     }
//   };
// }

export function listenToHotels(hotels: IHotel[]) {
  return {
    type: FETCH_HOTELS,
    payload: hotels,
  };
}

// export function setFilter(value) {
//   return function (dispatch) {
//     dispatch(clearHotels());
//     dispatch({ type: SET_FILTER, payload: value });
//   };
// }

// export function setStartDate(date) {
//   return function (dispatch) {
//     dispatch(clearHotels());
//     dispatch({ type: SET_START_DATE, payload: date });
//   };
// }

export function listenToSelectedHotel(hotel: IHotel) {
  return {
    type: LISTEN_TO_SELECTED_HOTEL,
    payload: hotel,
  };
}
export function listenToHotelImages(images: IImage[]) {
  return {
    type: LISTEN_TO_HOTEL_IMAGES,
    payload: images,
  };
}
export function clearSelectedHotel() {
  return {
    type: CLEAR_SELECTED_HOTEL,
  };
}

export function createHotel(hotel: IHotel) {
  return {
    type: CREATE_HOTEL,
    payload: hotel,
  };
}

export function updateHotel(hotel: IHotel) {
  return {
    type: UPDATE_HOTEL,
    payload: hotel,
  };
}

export function deleteHotel(hotelId: string) {
  return {
    type: DELETE_HOTEL,
    payload: hotelId,
  };
}

export function clearHotels() {
  return {
    type: CLEAR_HOTELS,
  };
}
