/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { PlusOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import React from "react";
import ImgCrop from "antd-img-crop";
import cuid from "cuid";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../atoms";
import { getFileExtension } from "../../../App/common/util/util";
// import { IImage, IImageGroup } from "../../../App/common/typings";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import {
  listenToHotelImagesFromFirestore,
  addImageToHotelImagesInFirestore,
  deleteImageFromHotelImagesInFirestore,
  uploadHotelImageToFirebaseStorage,
  deleteHotelImageFromFirebaseStorage,
} from "../../../App/firestore/firestoreService";
import { listenToHotelImages } from "../../../App/redux/data/hotel/hotelActions";
import { ItemNotFound, Loader } from "../../molecules";
function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export interface IHotelImagesGroupsProps {
  hotelId: string;
}
export const HotelImagesGroups: React.FC<IHotelImagesGroupsProps> = ({
  hotelId,
}) => {
  const { selectedHotel } = useSelector((state: any) => state.hotels);
  const { loading, error } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [photoUploadURL, setPhotoUploadURL] = React.useState("");

  userFirestoreCollection({
    query: () => listenToHotelImagesFromFirestore(hotelId),
    data: listenToHotelImages,
    deps: [dispatch, hotelId],
  });

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(Number(file.url.lastIndexOf("/")) + 1)
    );
  };
  // const handleChange =
  //   (key: string) =>
  //   ({ fileList }: any) => {
  //     // const fieldsImagesGroup = form.getFieldValue("imagesGroups") || [];
  //     // fieldsImagesGroup[key].images = fileList;
  //     // form.setFieldsValue({ imagesGroups: [...fieldsImagesGroup] });
  //   };
  const handleUploadImage = (file: any) => {
    const fileName = `${cuid()}.${getFileExtension(file.name)}`;
    const uploadTask = uploadHotelImageToFirebaseStorage(
      hotelId,
      file,
      fileName
    );
    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("upload is", progress);
      },
      (errorUpload) => {
        message.error(errorUpload.message);
        // console.log(errorUpload);
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async (downloadURL: string) => {
            setPhotoUploadURL(downloadURL);
            await addImageToHotelImagesInFirestore(hotelId, {
              name: fileName,
              url: downloadURL,
              uid: cuid(),
            });
            return true;
          })
          .catch((errorU) => {
            message.error(errorU);
            return false;
          });
      }
    );
    return;
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleDeleteImage = async (file: any) => {
    console.log(file);
    try {
      await deleteHotelImageFromFirebaseStorage(hotelId, file.name);
      await deleteImageFromHotelImagesInFirestore(hotelId, file.uid);

      message.success("delete Image successfully");
      return true;
    } catch (err: any) {
      message.success(err.message);
      return false;
    }
  };
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/admin/hotels"
        redirectBtnText="Back to Cities"
        itemType="Hotel"
        itemId={hotelId}
      />
    );
  }
  return (
    <div>
      <ImgCrop
        rotate
        aspect={1.6 / 1}
        // cropperProps={{ cropSize: { width: 1600, height: 1000 } }}
      >
        <Upload
          action={photoUploadURL}
          listType="picture-card"
          fileList={selectedHotel?.images || []}
          onPreview={handlePreview}
          // onChange={handleUploadImage(group.id)}
          beforeUpload={handleUploadImage}
          onRemove={handleDeleteImage}
        >
          {selectedHotel?.images?.length >= 8 ? null : uploadButton}
        </Upload>
      </ImgCrop>

      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};
