import styled from "styled-components";
export const TransferLegWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0px;
  font-family: "Avenir";
  gap: 30px;
  &:not(:last-child) {
    border-bottom: 1px solid #e9eef2;
  }
  .transfer-leg {
    &-duration {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 10px;
      gap: 5px;
      &-time {
        color: #313e55;
        font-size: 13px;
      }
      &-line {
        border-top: 1px dashed #229dff;
        flex: 1;
      }
    }
    &-port {
      display: flex;
      flex-direction: column;
      text-align: center;
      &-time {
        color: #22335d;
        font-size: 18px;
        font-weight: 900;
      }
      &-day {
        color: #22335d;
        font-size: 14px;
      }
      &-name {
        color: #84868b;
        font-size: 18px;
      }
    }
  }
`;
