import React from "react";
import { Form, Select } from "../../atoms";
interface ISelectOption {
  value: string | number;
  label: string;
}
export interface ISelectFieldProps {
  options: ISelectOption[];
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  showSearch?: boolean;
}

export const SelectField: React.FC<ISelectFieldProps> = ({
  options,
  label,
  rules,
  name,
  placeholder,
  style,
  mode,
  maxTagCount,
  initialValue,
  showSearch = true,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    initialValue={initialValue}
  >
    <Select
      showSearch={showSearch}
      style={style}
      placeholder={placeholder || label}
      optionFilterProp="label"
      filterOption={(input, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      options={options}
      mode={mode}
      maxTagCount={maxTagCount}
    ></Select>
  </Form.Item>
);
