import styled from "styled-components";
export const PackageBookingConfirmFrontendWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: 100%;
  .booking-confirm {
    &-body {
      max-width: 600px;
      margin: 0 auto;
    }
    &-image {
      img {
        width: 100%;
        height: 400px;
      }
    }
    &-booking {
      background-color: #fff;
      padding: 10px 20px;
      box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
    }
    &-total {
      background-color: #37a7cd;
      font-size: 21px;
      color: #fff;
      text-align: center;
      padding: 17px;
      span {
        font-weight: 900;
      }
    }
    &-message {
      &-title {
        font-weight: 900;
        margin-bottom: 10px;
      }
      &-des {
        margin-bottom: 15px;
      }
    }
  }
`;
