import styled from "styled-components";
// import {palette} from 'styled-theme'

export const ImageGalleryWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  .image-gallery-svg {
    height: 50px;
    width: 20px;
  }
`;
