import styled from "styled-components";
export const TermsPageWrapper = styled.div`
  height: 100%;
  overflow: auto;
  .termsPage {
    &-body {
      width: 840px;
      margin: 32px auto 40px;
    }
    &-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 40px;
    }
    &-subtitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 40px;
    }
    &-section {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 16px;
    }
    &-btn {
      width: 100%;
      height: 40px;
    }
  }
`;
