import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../atoms/Button/Button";
import { ImageGallery } from "../ImageGallery/ImageGallery";
import { HotelInfo } from "../HotelInfo/HotelInfo";
import { IPackageHotelPublic } from "../../../App/common/typings";
import { PriceBox } from "../PriceBox/PriceBox";
import { Space } from "../../atoms";
import { formatReadableAddress } from "../../../App/common/util/util";
import { PackageHotelItemWrapper } from "./PackageHotelItem.style";

export interface IPackageHotelItemProps {
  packageHotel: IPackageHotelPublic;
  selectHotel: (hotel: IPackageHotelPublic) => void;
  isSelectedHotel: boolean;
  showHotel: (hotel: IPackageHotelPublic) => void;
  isTabletOrMobile: boolean;
}

export const PackageHotelItem: React.FC<IPackageHotelItemProps> = ({
  packageHotel,
  selectHotel,
  isSelectedHotel,
  showHotel,
  isTabletOrMobile,
}) => {
  const { formatMessage } = useIntl();
  return (
    <PackageHotelItemWrapper>
      <div>
        <ImageGallery
          images={packageHotel.hotel?.images || []}
          showThumbnails={false}
          showPlayButton={false}
        />
      </div>
      <div className="package-hotel-item-body">
        <div className="package-hotel-item-hotel">
          <HotelInfo
            starRating={packageHotel.hotel.starRating}
            hotelName={
              <Link to="#" onClick={() => showHotel(packageHotel)}>
                {packageHotel.hotel.name}
              </Link>
            }
            address={formatReadableAddress(packageHotel.hotel?.address) || ""}
            imageUrl={packageHotel.hotel?.defaultImage?.url || ""}
            mealType={packageHotel.mealsType}
            moreDetails={() => showHotel(packageHotel)}
          />
          {!isTabletOrMobile && (
            <div className="package-hotel-item-select">
              <Button
                onClick={() => selectHotel(packageHotel)}
                className={
                  isSelectedHotel ? "package-hotel-item-is-selected" : ""
                }
              >
                {" "}
                {isSelectedHotel && <CheckOutlined />}
                <FormattedMessage
                  description="Select"
                  defaultMessage="Select"
                  id="du8694"
                />
              </Button>
            </div>
          )}
        </div>

        <Space className="package-hotel-item-prices" align="baseline">
          {packageHotel.singleRoomPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Single Room",
                defaultMessage: "Single Room",
                id: "wya+Gv",
              })}
              price={packageHotel.singleRoomPricePublic}
              descriptions={packageHotel.mealsType}
              currency="€"
            />
          )}
          <PriceBox
            title={formatMessage({
              description: "Double Room",
              defaultMessage: "Double Room",
              id: "SQOSAp",
            })}
            price={packageHotel.doubleRoomPricePublic}
            descriptions={packageHotel.mealsType}
            currency="€"
          />
          {packageHotel.tripleRoomPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Triple Room",
                defaultMessage: "Triple Room",
                id: "PuM+Ls",
              })}
              price={packageHotel.tripleRoomPricePublic}
              descriptions={packageHotel.mealsType}
              currency="€"
            />
          )}
          {packageHotel.quadrupleRoomPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Quadruple Room",
                defaultMessage: "Quadruple Room",
                id: "JVViU2",
              })}
              price={packageHotel.quadrupleRoomPricePublic}
              descriptions={packageHotel.mealsType}
              currency="€"
            />
          )}
          {packageHotel.firstChildPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "First Child",
                defaultMessage: "First Child",
                id: "1uxN4o",
              })}
              price={packageHotel.firstChildPricePublic}
              descriptions={packageHotel.mealsType}
              currency="€"
            />
          )}
          {packageHotel.secondChildPrice > 0 && (
            <PriceBox
              title={formatMessage({
                description: "Second Child",
                defaultMessage: "Second Child",
                id: "xQsO3f",
              })}
              price={packageHotel.secondChildPricePublic}
              descriptions={packageHotel.mealsType}
              currency="€"
            />
          )}
        </Space>
        {isTabletOrMobile && (
          <div className="package-hotel-item-select">
            <Button
              onClick={() => selectHotel(packageHotel)}
              className={
                isSelectedHotel ? "package-hotel-item-is-selected" : ""
              }
            >
              {" "}
              {isSelectedHotel && <CheckOutlined />}
              <FormattedMessage
                description="Select"
                defaultMessage="Select"
                id="du8694"
              />
            </Button>
          </div>
        )}
      </div>
    </PackageHotelItemWrapper>
  );
};
