import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { countriesOptions } from "../../../App/enums";
import { ModalWrapper } from "../../../App/common/modals/ModalWrapper";
import { Alert, Form, Input, Select } from "../../atoms";
import { ICity, ICityInput } from "../../../App/common/typings";
import { closeModal } from "../../../App/redux/data/modals/modalReducer";
import { addCityToFirestore } from "../../../App/firestore/firestoreService";

export interface ICityData extends ICity {
  countryId: string;
}

export const AddCityForm: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleSubmit = async () => {
    try {
      const values: ICityInput = await form.validateFields();
      setLoading(true);
      const city = await addCityToFirestore({
        name: values.name,
        code: values.code || "",
        countryCode: values.countryCode,
        status: true,
        description: "",
      });
      setLoading(false);
      dispatch(closeModal());
      history.push(`/admin/city/${city.id}`);
    } catch (errorInfo: any) {
      setLoading(false);
      setError(errorInfo.message);
    }
  };
  return (
    <ModalWrapper {...props} onSave={handleSubmit} loading={loading}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <Form.Item
          label="Country"
          name="countryCode"
          rules={[{ required: true, message: "Missing Country" }]}
        >
          <Select
            showSearch
            placeholder="Select a country"
            optionFilterProp="label"
            filterOption={(input, option: any) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            options={countriesOptions}
          ></Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Missing Name" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Code"
          name="code"
          rules={[{ required: true, message: "Missing Code" }]}
        >
          <Input placeholder="Code" max={4} />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
