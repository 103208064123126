/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Upload, Typography, message, Popconfirm } from "antd";
import React from "react";
import ImgCrop from "antd-img-crop";
import cuid from "cuid";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Collapse, Button } from "../../atoms";
import { getFileExtension } from "../../../App/common/util/util";
import { IImage, IImageGroup } from "../../../App/common/typings";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";
import { openModal } from "../../../App/redux/data/modals/modalReducer";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import {
  listenToCityImagesGroupsFromFirestore,
  addImageToCityImagesGroupInFirestore,
  deleteImageFromCityImagesGroupInFirestore,
  deleteCityGroupImageFromFirebaseStorage,
  uploadCityGroupImageToFirebaseStorage,
} from "../../../App/firestore/firestoreService";
import { listenToCityImagesGroups } from "../../../App/redux/data/city/cityActions";
import { ItemNotFound, Loader } from "../../molecules";
import {
  updateCityImagesGroupInFirestore,
  deleteCityImagesGroupInFirestore,
} from "../../../App/firestore/firestoreService/cityStore";
import { CityImagesGroupsWrapper } from "./City.style";
const { Panel } = Collapse;
const { Paragraph } = Typography;
function getBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export interface ICityImagesGroupsProps {
  cityId: string;
}
export const CityImagesGroups: React.FC<ICityImagesGroupsProps> = ({
  cityId,
}) => {
  const { imagesGroups } = useSelector((state: any) => state.cities);
  const { loading, error } = useSelector((state: any) => state.async);
  const dispatch = useDispatch();
  const [previewVisible, setPreviewVisible] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [photoUploadURL, setPhotoUploadURL] = React.useState("");

  userFirestoreCollection({
    query: () => listenToCityImagesGroupsFromFirestore(cityId),
    data: listenToCityImagesGroups,
    deps: [dispatch, cityId],
  });

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(Number(file.url.lastIndexOf("/")) + 1)
    );
  };
  // const handleChange =
  //   (key: string) =>
  //   ({ fileList }: any) => {
  //     // const fieldsImagesGroup = form.getFieldValue("imagesGroups") || [];
  //     // fieldsImagesGroup[key].images = fileList;
  //     // form.setFieldsValue({ imagesGroups: [...fieldsImagesGroup] });
  //   };
  const handleUploadImage = (groupId: string) => (file: any) => {
    const fileName = `${cuid()}.${getFileExtension(file.name)}`;
    const uploadTask = uploadCityGroupImageToFirebaseStorage(
      cityId,
      groupId,
      file,
      fileName
    );
    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        // const progress =
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log("upload is", progress);
      },
      (errorUpload) => {
        message.error(errorUpload.message);
        // console.log(errorUpload);
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async (downloadURL) => {
            setPhotoUploadURL(downloadURL);
            await addImageToCityImagesGroupInFirestore(cityId, groupId, {
              name: fileName,
              url: downloadURL,
              uid: cuid(),
            });
            return true;
          })
          .catch((errorU) => {
            message.error(errorU);
            return false;
          });
      }
    );
    return;
  };
  const handelAddNewGroup = () => {
    dispatch(
      openModal({
        modalType: "CityImageGroupForm",
        modalProps: { title: "Add City", cityId },
      })
    );
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleDeleteGroup =
    (groupId: string, images: IImage[]) => async (e: any) => {
      e.stopPropagation();
      try {
        images.map(async (image: IImage) => {
          await deleteCityGroupImageFromFirebaseStorage(
            cityId,
            groupId,
            image.name
          );
        });
        await deleteCityImagesGroupInFirestore(cityId, groupId);
        message.success("delete successfully");
      } catch (err: any) {
        message.success(err.message);
      }
    };
  const handleDeleteImage = (groupId: string) => async (file: any) => {
    try {
      await deleteCityGroupImageFromFirebaseStorage(cityId, groupId, file.name);
      await deleteImageFromCityImagesGroupInFirestore(
        cityId,
        groupId,
        file.name
      );

      message.success("delete Image successfully");
      return true;
    } catch (err: any) {
      message.success(err.message);
      return false;
    }
  };
  const genExtra = (groupId: string, images: IImage[]) => (
    <Popconfirm
      title="Are you sure you delete this group？"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      onConfirm={handleDeleteGroup(groupId, images)}
    >
      <DeleteOutlined />
    </Popconfirm>
  );
  const handleGroupNameChange = (groupId: string) => async (name: string) => {
    try {
      await updateCityImagesGroupInFirestore(cityId, groupId, name);
      message.success("Update successfully");
    } catch (e: any) {
      message.success(e.message);
    }
  };
  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/admin/cities"
        redirectBtnText="Back to Cities"
        itemType="City"
        itemId={cityId}
      />
    );
  }
  return (
    <CityImagesGroupsWrapper>
      <TableHeader>
        <Button
          type="primary"
          onClick={handelAddNewGroup}
          block
          icon={<PlusOutlined />}
        >
          Add Group
        </Button>
      </TableHeader>
      <Collapse defaultActiveKey={["1"]}>
        {imagesGroups?.map((group: IImageGroup) => (
          <Panel
            header={
              <Paragraph
                editable={{ onChange: handleGroupNameChange(group.id) }}
              >
                {group.name}
              </Paragraph>
            }
            key={group.id}
            extra={genExtra(group.id, group.images)}
          >
            <ImgCrop rotate key={group.id} aspect={1.6 / 1}>
              <Upload
                action={photoUploadURL}
                listType="picture-card"
                fileList={group.images}
                onPreview={handlePreview}
                // onChange={handleUploadImage(group.id)}
                beforeUpload={handleUploadImage(group.id)}
                onRemove={handleDeleteImage(group.id)}
              >
                {group.images.length >= 20 ? null : uploadButton}
              </Upload>
            </ImgCrop>
          </Panel>
        ))}
      </Collapse>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </CityImagesGroupsWrapper>
  );
};
