export const CREATE_BOOKING = "CREATE_BOOKING";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const LISTEN_TO_BOOKING_CHAT = "LISTEN_TO_BOOKING_CHAT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_BOOKING = "LISTEN_TO_SELECTED_BOOKING";
export const CLEAR_SELECTED_BOOKING = "CLEAR_SELECTED_BOOKING";
export const CLEAR_BOOKINGS = "CLEAR_BOOKINGS";
export const SET_FILTER = "SET_FILTER";
export const SET_START_DATE = "SET_START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
