/* eslint-disable no-console */
import React from "react";
import {
  //   convertFromRaw,
  convertToRaw,
  EditorState,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
interface IRichTextAreaProps {
  html: string;
  description: any;
}
export const RichTextArea: React.FC<IRichTextAreaProps> = ({
  html,
  description,
}) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const onEditorStateChange = (editor: any) => {
    setEditorState(editor);
    description.current = draftToHtml(convertToRaw(editor.getCurrentContent()));
  };
  React.useEffect(() => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const content = EditorState.createWithContent(contentState);
      setEditorState(content);
    }
  }, [html]);

  return (
    <Editor
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={onEditorStateChange}
      //   onUpdate={onUpdate}
    />
  );
};
