/* eslint-disable no-console */
import { isArray } from "lodash";
import { formatReadableCity } from "../../common/util/util";
import firebase from "../../config/firebase";
import {
  IImageGroupInput,
  ICity,
  ICityInput,
  IImage,
  IQueryConfigArgs,
  // IImageGroup,
} from "./../../common/typings";
import { db, dataFromSnapshot, collectionWithArgs } from "./firestoreService";

const COLLECTION_CITIES = "cities";
const COLLECTION_IMAGES_GROUPS = "imagesGroups";

// var vars = ['myVar1', 'myVar2', 'myVar3'];
// var ref = firebase.firestore().collection('myCol');

// vars.forEach(v => { ref = ref.where(v, '==', true) });

// ref.get()
//   .then(results => { ... })
//   .catch(err => { ... })

// export function fetchCitiesFromFirestore(
//   filter,
//   startDate,
//   limit,
//   orderBy,
//   lastDocSnapshot = null
// ) {
//   const user = firebase.auth().currentUser;
//   const citiesRef = db
//     .collection(COLLECTION_CITIES)
//     .orderBy(orderBy)
//     .startAfter(lastDocSnapshot)
//     .limit(limit);
//   switch (filter) {
//     case "isGoing":
//       return citiesRef
//         .where("attendeeIds", "array-contains", user.uid)
//         .where("date", ">=", startDate);
//     case "isHost":
//       return citiesRef
//         .where("hostUid", "==", user.uid)
//         .where("date", ">=", startDate);
//     default:
//       return citiesRef.where("date", ">=", startDate);
//   }
// }

// const pp = {
//   filter: [
//     {
//       field: "countyCode",
//       ops: {
//         ["=="]: "GR",
//       },
//     },
//   ],
// }
export function fetchCitiesFromFirestore(
  args?: Array<IQueryConfigArgs | null>
) {
  const ref = db.collection(COLLECTION_CITIES);
  return collectionWithArgs(ref, args || []);
}
export function listenToCityFromFirestore(id: string) {
  return db.collection(COLLECTION_CITIES).doc(id);
}

export function addCityToFirestore(doc: ICityInput) {
  // const user = firebase.auth().currentUser;
  return db.collection(COLLECTION_CITIES).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateCityInFirestore(docId: string, doc: ICity) {
  return db.collection(COLLECTION_CITIES).doc(docId).update(doc);
}

export async function deleteCityInFirestore(docId: string) {
  try {
    db.collection(COLLECTION_CITIES)
      .doc(docId)
      .collection(COLLECTION_IMAGES_GROUPS)
      .onSnapshot(
        (snapshot: any) => {
          snapshot.docs.map((doc: any) => {
            doc.data().images.map(async (image: IImage) => {
              await deleteCityGroupImageFromFirebaseStorage(
                docId,
                doc.id,
                image.name
              );
            });
          });
        },
        (error: any) => {
          throw error;
        }
      );

    return db.collection(COLLECTION_CITIES).doc(docId).delete();
  } catch (err) {
    throw err;
  }
}

export function activeToggleCityInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_CITIES).doc(docId).update({
    status,
  });
}
// images Groups

export function listenToCityImagesGroupsFromFirestore(id: string) {
  return db
    .collection(COLLECTION_CITIES)
    .doc(id)
    .collection(COLLECTION_IMAGES_GROUPS);
}

export function listenToCityImagesGroupFromFirestore(
  id: string,
  groupId: string
) {
  return db
    .collection(COLLECTION_CITIES)
    .doc(id)
    .collection(COLLECTION_IMAGES_GROUPS)
    .doc(groupId);
}

export function addCityImagesGroupToFirestore(
  id: string,
  doc: IImageGroupInput
) {
  return db
    .collection(COLLECTION_CITIES)
    .doc(id)
    .collection(COLLECTION_IMAGES_GROUPS)
    .add({
      ...doc,
    });
}

export function updateCityImagesGroupInFirestore(
  id: string,
  groupId: string,
  name: string
) {
  return db
    .collection(COLLECTION_CITIES)
    .doc(id)
    .collection(COLLECTION_IMAGES_GROUPS)
    .doc(groupId)
    .update({ name });
}

export function deleteCityImagesGroupInFirestore(
  docId: string,
  groupId: string
) {
  return db
    .collection(COLLECTION_CITIES)
    .doc(docId)
    .collection(COLLECTION_IMAGES_GROUPS)
    .doc(groupId)
    .delete();
}

export async function addImageToCityImagesGroupInFirestore(
  id: string,
  groupId: string,
  image: IImage
) {
  return await db
    .collection(COLLECTION_CITIES)
    .doc(id)
    .collection(COLLECTION_IMAGES_GROUPS)
    .doc(groupId)
    .update({ images: firebase.firestore.FieldValue.arrayUnion(image) });
}

export async function deleteImageFromCityImagesGroupInFirestore(
  id: string,
  groupId: string,
  imageId: string
) {
  try {
    const groupDoc = await db
      .collection(COLLECTION_CITIES)
      .doc(id)
      .collection(COLLECTION_IMAGES_GROUPS)
      .doc(groupId)
      .get();
    const doc = groupDoc.data();
    return db
      .collection(COLLECTION_CITIES)
      .doc(id)
      .collection(COLLECTION_IMAGES_GROUPS)
      .doc(groupId)
      .update({
        images: doc?.images.filter((image: IImage) => image.name !== imageId),
      });
  } catch (err) {
    throw err;
  }
}

export async function getImagesFromCityImagesGroupInFirestore(
  ids: string[],
  cb: any
) {
  if (!isArray(ids) || ids.length === 0) return;
  const colRf = db.collection(COLLECTION_CITIES);
  try {
    const images: any[] = [];
    colRf.where("id", "in", ids).onSnapshot(
      (snapshot: any) =>
        snapshot.docs.map((doc: any) => {
          const city = dataFromSnapshot(doc);
          return colRf
            .doc(doc.id)
            .collection(COLLECTION_IMAGES_GROUPS)
            .onSnapshot(
              (snapSub) =>
                snapSub.docs.map((docSub: any) => {
                  cb((v: any) => [
                    ...v,
                    {
                      ...dataFromSnapshot(docSub),
                      cityName: formatReadableCity(city),
                    },
                  ]);
                  return;
                }),
              (error: any) =>
                console.log("Error getting sub-collection documents", error)
            );
        }),

      (error: any) =>
        console.log("Error getting sub-collection documents", error)
    );
    console.log(images);
    return images;
  } catch (err) {
    throw err;
  }
}
export function uploadCityGroupImageToFirebaseStorage(
  cityId: string,
  groupId: string,
  file: any,
  filename: string
) {
  const storageRef = firebase.storage().ref();
  return storageRef
    .child(`cities/${cityId}/${groupId}/images/${filename}`)
    .put(file);
}

export function deleteCityGroupImageFromFirebaseStorage(
  cityId: string,
  groupId: string,
  filename: string
) {
  const storageRef = firebase.storage().ref();
  const photoRef = storageRef.child(
    `cities/${cityId}/${groupId}/images/${filename}`
  );
  return photoRef.delete();
}

export function activeToggleCityFrontEndFilterInFirestore(
  docId: string,
  showFrontEndFilter: boolean
) {
  return db.collection(COLLECTION_CITIES).doc(docId).update({
    showFrontEndFilter,
  });
}
// export async function deleteCityGroupFromFirebaseStorage(
//   cityId: string,
//   groupId: string
// ) {
//   const storageRef = firebase.storage().ref();
//   const photoRef = storageRef.child(`cities/${cityId}/${groupId}`);
//   return await photoRef
//     .listAll()
//     .then((result) => {
//       result.items.forEach((file: any) => {
//         file.delete();
//       });
//     })
//     .catch((error) => {
//       throw error;
//     });
// }
