import { findIndex } from "lodash";
import moment from "moment";
import { IPackage } from "../../../common/typings";
import {
  CREATE_PACKAGE,
  UPDATE_PACKAGE,
  DELETE_PACKAGE,
  FETCH_PACKAGES,
  CLEAR_COMMENTS,
  LISTEN_TO_SELECTED_PACKAGE,
  CLEAR_PACKAGES,
  SET_FILTER,
  SET_START_DATE,
  RETAIN_STATE,
  CLEAR_SELECTED_PACKAGE,
  LISTEN_TO_PACKAGE_FLIGHTS,
  LISTEN_TO_PACKAGE_DESTINATIONS,
  LISTEN_TO_PACKAGE_DESTINATIONS_IMAGES,
} from "./packageConstants";

const initialState = {
  packages: [],
  flights: [],
  destinations: [],
  morePackages: true,
  selectedPackage: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  imagesGroups: {},
};

export default function packageReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_PACKAGE:
      return {
        ...state,
        packages: [...state.packages, payload],
      };
    case UPDATE_PACKAGE:
      return {
        ...state,
        packages: [
          ...state.packages.filter((evt: IPackage) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_PACKAGE:
      return {
        ...state,
        packages: [
          ...state.packages.filter((evt: IPackage) => evt.id !== payload),
        ],
      };
    case FETCH_PACKAGES:
      return {
        ...state,
        packages: payload,
        morePackages: payload.morePackages,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_PACKAGE_FLIGHTS:
      return {
        ...state,
        flights: payload,
      };
    case LISTEN_TO_PACKAGE_DESTINATIONS:
      return {
        ...state,
        destinations: payload,
      };
    case LISTEN_TO_PACKAGE_DESTINATIONS_IMAGES:
      const { imagesGroups, destinationId } = payload;
      const index = findIndex(state.destinations, ["id", destinationId]);
      if (index !== -1) {
        imagesGroups[destinationId] = imagesGroups;
      }
      return {
        ...state,
        // imagesGroups[destinationId]:  imagesGroups
      };
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
      };
    case LISTEN_TO_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: {
          ...payload,
          departureDate: moment(payload?.departureDate),
          destinations:
            payload?.destinations?.map((destination: any) => ({
              ...destination,
              checkInDate: moment(destination.checkInDate.toDate()),
              checkOutDate: moment(destination.checkOutDate.toDate()),
            })) || [],
          flights:
            payload?.flights?.map((flight: any) => ({
              ...flight,
              departureDate: moment(flight.departureDate.toDate()),
              arrivalDate: moment(flight.arrivalDate.toDate()),
            })) || [],
          tours:
            payload?.tours?.map((tour: any) => ({
              ...tour,
              startDate: moment(tour.startDate),
            })) || [],
        },
      };
    case CLEAR_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: null,
      };
    case CLEAR_PACKAGES:
      return {
        ...state,
        packages: [],
        morePackages: true,
        lastVisible: null,
      };
    case SET_FILTER:
      return {
        ...state,
        retainState: false,
        morePackages: true,
        filter: payload,
      };
    case SET_START_DATE:
      return {
        ...state,
        retainState: false,
        morePackages: true,
        startDate: payload,
      };
    case RETAIN_STATE:
      return {
        ...state,
        retainState: true,
      };
    default:
      return state;
  }
}
