import styled from "styled-components";
export const HotelInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  .hotel-info {
    &-img {
      padding-right: 10px;
      img {
        height: 64px;
        width: 64px;
        border-radius: 4px;
      }
    }
    &-details {
      display: flex;
      flex-direction: column;
    }
    &-rate {
      font-size: 11px;
    }
    &-name {
      font-size: 16px;
      color: #005fc3;
      font-weight: 900;
      font-family: "avenir";
      &-meal {
        color: #0eb381;
        font-weight: normal;
      }
    }
    &-location {
      color: #313e55;
      font-size: 13px;
    }
    &-more-details {
      color: #005fc3;
      font-size: 12px;
      cursor: pointer;
    }
  }
`;
