/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { IMatch } from "../../../App/common/typings";
import { listenToPackageFromFirestore } from "../../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { listenToSelectedPackage } from "../../../App/redux/data/package/packageActions";
import { ItemNotFound, Loader } from "../../../components/molecules";
import { PackageViewFrontEnd } from "../../../components/organisms";

interface IMatchParams {
  id: string;
}
export interface IPackageViewFrontEndPageProps {
  match: IMatch<IMatchParams>;
}

const PackageViewFrontEndPage: React.FC<IPackageViewFrontEndPageProps> = ({
  match,
}) => {
  const packageId = match.params.id;
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const { loading, error } = useSelector((state: any) => state.async);

  userFirestoreDoc({
    query: () => listenToPackageFromFirestore(packageId),
    data: listenToSelectedPackage,
    deps: [packageId],
    shouldExecute: packageId !== "new",
  });

  if (loading) return <Loader color="success" title="Loading" />;
  if (error) {
    return (
      <ItemNotFound
        redirectTo="/"
        redirectBtnText="Back to Home Page"
        itemType="Package"
        itemId={"sd"}
      />
    );
  }
  return <PackageViewFrontEnd travelPackage={selectedPackage || {}} />;
};

export default PackageViewFrontEndPage;
