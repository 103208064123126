import React from "react";
import { useSelector } from "react-redux";
// import TestModal from "../../../features/sandbox/TestModal";
// import LoginForm from "../../../features/auth/LoginForm";
// import RegisterForm from "../../../features/auth/RegisterForm";
import { LoginForm } from "../../../components/organisms/Auth/LoginForm";
import { AddCityForm } from "../../../components/organisms/City/AddCityForm";
import { CityImageGroupForm } from "../../../components/organisms/City/CityImageGroupForm";
import { AddCategoryForm } from "../../../components/organisms/Category/AddCategoryForm";
import { AddHotelForm } from "../../../components/organisms/Hotel/AddHotelForm";
import {
  AddPackageForm,
  AddPackageHotelForm,
  AddPackagePolicyForm,
} from "../../../components/organisms";
export default function ModalManager() {
  const modalLookup: any = {
    // TestModal,
    LoginForm,
    AddCityForm,
    CityImageGroupForm,
    AddCategoryForm,
    AddHotelForm,
    AddPackageForm,
    AddPackagePolicyForm,
    AddPackageHotelForm,
    // RegisterForm,
  };
  const currentModal = useSelector((state: any) => state.modals);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
