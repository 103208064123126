/* eslint-disable no-console */
import React from "react";
import { Divider, notification } from "antd";
import { useSelector } from "react-redux";
import cuid from "cuid";
import { Form, Space } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { ITransfer } from "../../../App/common/typings";
import { updatePackageTransfersInFirestore } from "../../../App/firestore/firestoreService";
import { TransferField } from "../../molecules";

export interface IPackageTransferListProps {
  packageId: string;
}

export const PackageTransferList: React.FC<IPackageTransferListProps> = ({
  packageId,
}) => {
  const { selectedPackage } = useSelector((state: any) => state.packages);
  // const { loading, error } = useSelector((state: any) => state.async);
  const [form] = Form.useForm();
  const handleOnFinish = async () => {
    try {
      const values: { transfers: ITransfer[] } = await form.validateFields();
      const transfers =
        values.transfers?.map(
          (transfer: ITransfer, index): ITransfer => ({
            ...transfer,
            childPrice: transfer.childPrice || 0,
            id: transfer.id || cuid(),
          })
        ) || [];
      await updatePackageTransfersInFirestore(packageId, transfers);
      notification.success({
        message: "Update",
        description: "Package Update Successful",
      });
    } catch (errorInfo: any) {
      notification.error({
        message: "Update Failed",
        description: errorInfo.message,
      });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        selectedPackage?.transfers?.length === 0
          ? { ...selectedPackage, transfers: [{}] }
          : selectedPackage
      }
      onFinish={handleOnFinish}
      autoComplete="off"
      name={`PackageTransfer${selectedPackage?.id}`}
      key={`PackageTransfer${selectedPackage?.id}`}
    >
      <Divider>Transfers</Divider>
      <TransferField name="transfers" form={form} />
      <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Space>
    </Form>
  );
};
