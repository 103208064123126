/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { TermsPageWrapper } from "./TermsPage.style";

const TermsPage: React.FC = () => (
  <TermsPageWrapper>
    <div className="termsPage-body">
      <div className="termsPage-title">
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
        WEBSITE
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">1. Terms of website use:</div> This
        Terms (together with the documents referred to in it) tells you the
        terms of use on which you may make use of our website –
        www.poupas.com.cy (the “Website”). Use of the Website includes
        accessing, browsing, or registering to use our Website. We recommend
        that you print a copy of these Terms for future reference. By using our
        Website, you confirm that you accept these Terms and that you agree to
        comply with them.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">2. Other applicable terms</div>{" "}
        These Terms refer to the following additional terms, which also apply to
        your use of our site: <br /> i. Our Privacy Policy, which sets out the
        terms on which we process any personal data we collect from you, or that
        you provide to us. By using our site, you consent to such processing and
        you warrant that all data provided by you is accurate. <br /> ii. Our
        Cookie Policy, which sets out information about the cookies on our site.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">3. Information about us </div>
        This is the website POUPAS FOR HOLIDAYS LTD a company registered in
        Cyprus under registration number ΗΕ 367070 with its registered office
        Irakleous 27, Strovolos 2040, Nicosia, Cyprus.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">4.Changes to these terms </div>
        We may revise these terms of use at any time by amending this page.
        Please check this page from time to time to take notice of any changes
        we made, as they are binding on you.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">5. Changes to our site</div>
        We may update our Website from time to time, and may change the content
        at any time. However, please note that any of the content on our site
        may be out of date at any given time, and we are under no obligation to
        update it.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">
          6. Intellectual property rights{" "}
        </div>
        We are the owner or the licensee of all intellectual property rights in
        our Website, and in the material published on it. Those works are
        protected by copyright laws and treaties around the world. All such
        rights are reserved. You must not modify the paper or digital copies of
        any materials you have printed off or downloaded in any way, and you
        must not use any illustrations, photographs, video or audio sequences or
        any graphics separately from any accompanying text. Our status (and that
        of any identified contributors) as the authors of content on our site
        must always be acknowledged. You must not use any part of the content on
        our Website for commercial purposes. The trademarks, service marks, and
        logos (the "Trademarks") used and displayed on this Website are ours
        registered and unregistered Trademarks. Nothing on this Website should
        be construed as granting, by implication, estoppels, or otherwise, any
        license or right to use any Trademark displayed on the site, without the
        written permission of the Trademark owner. The Trademark(s) shall not be
        used in any way, including in advertising or publicity pertaining to
        distribution of materials on this Website, without prior, written
        permission.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">7. No reliance on information </div>
        Although we make reasonable efforts to update the information on our
        Website, we make no representations, warranties or guarantees, whether
        express or implied, that the content on our site is accurate, complete
        or up-to-date.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">8. Limitation of our liability</div>
        We will not be liable to any user for any loss or damage, whether in
        contract, tort (including negligence), breach of statutory duty, or
        otherwise, even if foreseeable, arising under or in connection with: i.
        use of our Website; or ii. use of or reliance on any content displayed
        on our Website. We assume no responsibility for the content of websites
        linked on our Website. Such links should not be interpreted as
        endorsement by us of those linked websites. We will not be liable for
        any loss or damage that may arise from your use of them.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle"> 9. Linking to our site</div>
        You may link to our Website, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists. We
        reserve the right to withdraw linking permission without notice.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle"> 10. Applicable law</div>
        These Terms, their subject matter and their formation (and any
        non-contractual disputes or claims) are governed by the law of the
        Republic of Cyprus, exclusive jurisdiction over any respective dispute
        being vested to the courts of Nicosia.
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">
          {" "}
          11. Amendments, cancellations and refunds
        </div>
        1. We go above and beyond our legal obligations. Even though local laws
        don’t require us to offer specific cancellation rights, we guarantee
        that we will honour our refunds policy if you cancel your Booking. 2.
        The following ‘Cancellation and Amendments’ terms apply to all Bookings
        apart from: Bookings with Dollar or Thrifty that were booked before 26
        January 2021 (please check your rental terms) Bookings that are labelled
        ‘non-refundable’ (you cannot amend a non-refundable Booking, and you
        won’t receive a refund if you cancel it).
      </div>
      <div className="termsPage-section">
        <div className="termsPage-subtitle">Contact us</div>
        To contact us, please send an email at{" "}
        <a href="mail:nfo@poupas.com.cy">info@poupas.com.cy.</a>
      </div>
    </div>
  </TermsPageWrapper>
);

export default TermsPage;
