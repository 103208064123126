import { Rate } from "antd";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { mealTypes } from "../../../App/enums";
import { HotelInfoWrapper } from "./HotelInfo.style";
export interface IHotelInfoProps {
  starRating: number;
  hotelName: string | ReactElement;
  address: string;
  imageUrl?: string;
  mealType: string;
  moreDetails?: () => void;
}
export const HotelInfo: React.FC<IHotelInfoProps> = ({
  starRating,
  hotelName,
  address,
  imageUrl,
  mealType,
  moreDetails,
}) => (
  <HotelInfoWrapper>
    {imageUrl && (
      <div className="hotel-info-img">
        <img src={imageUrl} alt="" />
      </div>
    )}
    <div className="hotel-info-details">
      <Rate className="hotel-info-rate" disabled defaultValue={starRating} />
      <span className="hotel-info-name">
        {hotelName}{" "}
        {mealType !== "NO_MEAL" && (
          <span className="hotel-info-name-meal">
            <FormattedMessage
              description="- with"
              defaultMessage="- with"
              id="MBqv1J"
            />{" "}
            {mealTypes[mealType]?.label}
          </span>
        )}
      </span>
      <span className="hotel-info-location">{address}</span>
      {moreDetails && (
        <span className="hotel-info-more-details" onClick={moreDetails}>
          <FormattedMessage
            description="Hotel details"
            defaultMessage=" Hotel details"
            id="XYpI91"
          />
        </span>
      )}
    </div>
  </HotelInfoWrapper>
);
