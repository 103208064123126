import React from "react";
import { IMatch } from "../../../App/common/typings";
import { PackageBookingConfirmFrontend } from "../../../components/organisms";
interface IMatchParams {
  id: string;
}
export interface IPackageBookingConfirmProps {
  match: IMatch<IMatchParams>;
}
const PackageBookingConfirmation: React.FC<IPackageBookingConfirmProps> = ({
  match,
}) => {
  const bookingId = match.params.id;
  return <PackageBookingConfirmFrontend bookingId={bookingId} />;
};

export default PackageBookingConfirmation;
