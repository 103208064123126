/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CityList } from "../../components/organisms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToCities } from "../../App/redux/data/city/cityActions";
import { userFirestoreCollection } from "../../App/hooks/userFirestoreCollection";
import {
  deleteCityInFirestore,
  fetchCitiesFromFirestore,
} from "../../App/firestore/firestoreService";
import { openModal } from "../../App/redux/data/modals/modalReducer";
import { IQueryConfigArgs } from "../../App/common/typings";
const defaultSorting: IQueryConfigArgs = {
  type: "sorting",
  name: "name",
  order: "asc",
  opr: "asc",
};
const Cities: React.FC = () => {
  const dataSou = useSelector((state: any) => state.cities);
  const loading = useSelector((state: any) => state.async);
  const [citySorting, setCitySorting] = React.useState<IQueryConfigArgs | null>(
    defaultSorting
  );
  const [searchCity, setSearchCity] = React.useState("");
  const [countryCode, setCityId] = React.useState("");
  const dispatch = useDispatch();

  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        citySorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: ">=",
              value: searchCity,
            }
          : null,
        citySorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: "<=",
              value: `${searchCity}\uf8ff`,
            }
          : null,
        {
          type: "filter",
          name: "countryCode",
          opr: "==",
          value: countryCode,
        },
        citySorting,
      ]),
    data: listenToCities,
    deps: [dispatch, citySorting, searchCity, countryCode],
  });
  const handelFilterCity = (value: string) => {
    if (value) {
      setCitySorting(defaultSorting);
      setCityId(value);
    } else {
      setCityId("");
    }
  };
  const handelSearchCity = (search: string) => {
    if (search !== "") {
      setCitySorting(defaultSorting);
    }
    setSearchCity(search);
  };
  const handelAddCity = () => {
    dispatch(
      openModal({ modalType: "AddCityForm", modalProps: { title: "Add City" } })
    );
  };
  const handelDeleteCity = async (id: string) => {
    await deleteCityInFirestore(id);
  };
  const handelSortingChange = (name: string, order: any) => {
    setCitySorting({
      type: "sorting",
      opr: order,
      name,
      order,
    });
  };
  return (
    <AdminPage pageTitle="Cities">
      <CityList
        loading={loading.loading}
        cities={dataSou.cities || []}
        filterCity={handelFilterCity}
        searchCity={handelSearchCity}
        addCity={handelAddCity}
        deleteCity={handelDeleteCity}
        sorting={handelSortingChange}
      />
    </AdminPage>
  );
};

export default Cities;
