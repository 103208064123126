/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React from "react";
import { useSelector } from "react-redux";
import { Collapse } from "../../atoms";
import { formatReadableCity } from "../../../App/common/util/util";
import { IDestination } from "../../../App/common/typings";
import { PackageHotelList } from "./PackageHotels";
const { Panel } = Collapse;

export interface IPackageDestinationsProps {
  packageId: string;
}
export const PackageDestinations: React.FC<IPackageDestinationsProps> = ({
  packageId,
}) => {
  const { selectedPackage } = useSelector((state: any) => state.packages);

  return (
    <div>
      <Collapse defaultActiveKey={["1"]}>
        {selectedPackage.destinations.map((destination: IDestination) => (
          <Panel
            header={formatReadableCity(destination.city)}
            key={destination.cityId}
          >
            <PackageHotelList
              packageId={packageId}
              cityId={destination.cityId}
              loading={false}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};
