import { Switch } from "antd";
import React from "react";
import { Button, Form, Input, Space } from "../../atoms";
export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password?: string;
  createDate?: string;
  status?: boolean;
  lastLogin?: string;
  uid?: string;
}
export interface IUserDetailsProps {
  user: IUser;
  addUser?: (data: IUser) => void;
  updateUser?: (id: number, data: IUser) => void;
  changePassword?: () => void;
}
export const UserDetails: React.FC<IUserDetailsProps> = ({ user }) => (
  <div>
    <Form>
      <Form.Item label="Active">
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked
          checked={user.status}
        />
      </Form.Item>
      <Form.Item label="First Name" initialValue={user.firstName}>
        <Input value={user.firstName} placeholder="First Name" />
      </Form.Item>
      <Form.Item label="Last Name" initialValue={user.lastName}>
        <Input value={user.lastName} />
      </Form.Item>
      <Form.Item label="Email" initialValue={user.email}>
        <Input value={user.email} />
      </Form.Item>
      <Form.Item label="Phone" initialValue={user.phoneNumber}>
        <Input value={user.phoneNumber} />
      </Form.Item>
      <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
        <Button type="primary">Close</Button>
        <Button type="primary">Save</Button>
      </Space>
    </Form>
  </div>
);
