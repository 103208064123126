import styled from "styled-components";
export const PackageActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-left: 2px dashed #c8c8c8;
  text-align: center;
  padding: 15px 20px;
  width: 200px;
  @media (max-width: 800px) {
    width: 100%;
    border-top: 2px dashed #c8c8c8;
    border-left: none;
    padding: 5px 20px;
  }
  .package-action {
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-text {
      color: #485b7c;
      font-size: 15px;
      font-weight: 300;
      @media (max-width: 800px) {
        flex: 1;
        text-align: left;
      }
    }
    &-btn {
      background-color: #1d3bf8;
      color: #fff;
      font-size: 18px;
      height: 48px;
      padding: 0px;
      border-radius: 4px;
    }
    &-price {
      color: #22335d;
      font-size: 24px;
      font-weight: 900;
    }
    &-price-box {
      display: flex;
      flex-direction: column;
    }
    &-space {
      gap: 25px;
      font-size: 18px;
      margin-top: 10px;
    }
  }
`;
