import styled from "styled-components";

// theme is now fully typed
export const FlightsFieldWrapper = styled.div`
  position: relative;
  .flights-field {
    &-leg {
      display: flex;
      overflow: auto;
      align-items: center;
      grid-gap: 10px;
    }
    &-space {
      display: grid;
      grid-template-columns: 150px 200px 150px 200px 150px 150px;
      .ant-space {
        &-item {
          flex: 1;
        }
      }
    }
    &-action {
      /* position: absolute;
      right: 30px;
      background-color: #fff;
      width: 30px; */
      padding: 0 30px;
    }
  }
`;
