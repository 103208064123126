import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router";
import { IProvider } from "../../../App/common/typings";
import { Button, Table } from "../../atoms";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";
export interface IProviderListProps {
  providers: IProvider[];
  searchProvider?: (search: string) => void;
  filterProvider?: () => void;
  addProvider?: () => void;
}

const columnsProviderList = [
  {
    title: "Company name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Main contact",
    dataIndex: "mainContact",
    key: "mainContact",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone number",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Last login",
    dataIndex: "lastLogin",
    key: "lastLogin",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
];
export const ProviderList: React.FC<IProviderListProps> = ({ providers }) => {
  const history = useHistory();
  const handelAdd = () => {
    history.push("/admin/provider/new");
  };
  return (
    <div>
      <TableHeader>
        <Button
          type="primary"
          onClick={handelAdd}
          block
          icon={<PlusOutlined />}
        >
          Add Provider
        </Button>
      </TableHeader>

      <Table
        columns={columnsProviderList}
        dataSource={providers}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};
