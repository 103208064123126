import { values } from "lodash";
import { ISelectOption } from "../common/typings";

interface IPartnerTypesMap {
  [key: string]: ISelectOption;
}
export const partnerTypes: IPartnerTypesMap = {
  PASYDY: {
    value: "PASYDY",
    label: "Pasydy",
  },
  POED: {
    value: "POED",
    label: "Poed",
  },
};

export const partnerTypeOptions = values(partnerTypes);
