/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PackageList } from "../../components/organisms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { listenToPackages } from "../../App/redux/data/package/packageActions";
import { userFirestoreCollection } from "../../App/hooks/userFirestoreCollection";
import {
  deletePackageInFirestore,
  duplicatePackageInFirestore,
  fetchCitiesFromFirestore,
  fetchPackagesFromFirestore,
} from "../../App/firestore/firestoreService";
import { openModal } from "../../App/redux/data/modals/modalReducer";
import { IQueryConfigArgs } from "../../App/common/typings";
import { listenToCities } from "../../App/redux/data/city/cityActions";

const defaultSorting: IQueryConfigArgs = {
  type: "sorting",
  name: "name",
  order: "asc",
  opr: "asc",
};
const Packages: React.FC = () => {
  const dataSou = useSelector((state: any) => state.packages);
  const loading = useSelector((state: any) => state.async);
  const [packageSorting, setPackageSorting] =
    React.useState<IQueryConfigArgs | null>(defaultSorting);
  const [searchPackage, setSearchPackage] = React.useState("");
  const [cityId, setCityId] = React.useState("");
  const dispatch = useDispatch();
  userFirestoreCollection({
    query: () =>
      fetchPackagesFromFirestore([
        packageSorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: ">=",
              value: searchPackage,
            }
          : null,
        packageSorting?.name === "name"
          ? {
              type: "filter",
              name: "name",
              opr: "<=",
              value: `${searchPackage}\uf8ff`,
            }
          : null,
        {
          type: "filter",
          name: "destinationIds",
          opr: "array-contains",
          value: cityId,
        },
        packageSorting,
      ]),
    data: (data: any) => listenToPackages(data),
    deps: [dispatch, packageSorting, searchPackage, cityId],
  });
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  const handelAddPackage = () => {
    dispatch(
      openModal({
        modalType: "AddPackageForm",
        modalProps: { title: "Add Package" },
      })
    );
  };
  const handelFilterCity = (value: string) => {
    if (value) {
      setPackageSorting(defaultSorting);
      setCityId(value);
    } else {
      setCityId("");
    }
  };
  const handelSearchPackage = (search: string) => {
    if (search !== "") {
      setPackageSorting(defaultSorting);
    }
    setSearchPackage(search);
  };
  const handelDeletePackage = async (id: string) => {
    await deletePackageInFirestore(id);
  };
  const handelDuplicatePackage = async (id: string) => {
    await duplicatePackageInFirestore(id);
  };
  const handelSortingChange = (name: string, order: any) => {
    if (name) {
      setPackageSorting({
        type: "sorting",
        opr: order,
        name,
        order,
      });
    }
  };
  return (
    <AdminPage pageTitle="Packages">
      <PackageList
        loading={loading.loading}
        packages={dataSou.packages || []}
        filterPackage={handelFilterCity}
        searchPackage={handelSearchPackage}
        addPackage={handelAddPackage}
        deletePackage={handelDeletePackage}
        sorting={handelSortingChange}
        duplicate={handelDuplicatePackage}
      />
    </AdminPage>
  );
};

export default Packages;
