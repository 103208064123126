import styled from "styled-components";
export const CheckboxGroupWrapper = styled.div`
  .checkbox-group {
    &-all {
      border-bottom: 1px solid #f8f8f8;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }
`;
