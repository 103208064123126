/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "../../components/atoms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";
import { IMatch } from "../../App/common/typings";
import { PackageImages } from "../../components/organisms/TravelPackage/PackageImages";
import {
  PackageDestinations,
  PackageDetails,
  PackageFlightList,
  PackagePolicies,
  PackageCruisesDetails,
  PackageTourList,
  PackageTransferList,
} from "../../components/organisms";

const { TabPane } = Tabs;

interface IMatchParams {
  id: string;
}
export interface IPackageViewProps {
  match: IMatch<IMatchParams>;
}

const PackageView: React.FC<IPackageViewProps> = ({ match }) => {
  const packageId = match.params.id;
  const { selectedPackage } = useSelector((state: any) => state.packages);
  const { serviceInclude, name } = selectedPackage || {
    serviceInclude: [],
    name: "",
  };
  return (
    <AdminPage pageTitle={name}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Overview" key="1">
          <PackageDetails key={packageId} packageId={packageId} />
        </TabPane>
        {serviceInclude.includes("FLIGHTS") && (
          <TabPane tab="Flights" key="Flights">
            <PackageFlightList packageId={packageId} />
          </TabPane>
        )}
        {serviceInclude.includes("HOTELS") && (
          <TabPane tab="Hotels" key="Hotels">
            <PackageDestinations packageId={packageId} />
          </TabPane>
        )}
        {serviceInclude.includes("CRUISES") && (
          <TabPane tab="Cruises" key="Cruises">
            <PackageCruisesDetails packageId={packageId} />
          </TabPane>
        )}
        {serviceInclude.includes("TRAINS") && (
          <TabPane tab="Trains" key="Trains">
            Trains
          </TabPane>
        )}
        {serviceInclude.includes("TOURS") && (
          <TabPane tab="Tours" key="Tours">
            <PackageTourList packageId={packageId} />
          </TabPane>
        )}
        {serviceInclude.includes("TRANSFERS") && (
          <TabPane tab="Transfers" key="Transfers">
            <PackageTransferList packageId={packageId} />
          </TabPane>
        )}

        <TabPane tab="Policy" key="Policy">
          <PackagePolicies packageId={packageId} />
        </TabPane>
        <TabPane tab="Images" key="Images">
          <PackageImages packageId={packageId}></PackageImages>
        </TabPane>
      </Tabs>
    </AdminPage>
  );
};

export default PackageView;
