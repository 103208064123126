import styled from "styled-components";
export const PersonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  .person {
    &-name {
      font-size: 13px;
      font-weight: 900;
    }
    &-email {
      color: #5f6e87;
      font-size: 12px;
      font-family: "Avenir";
    }
    &-phone {
      color: #5f6e87;
      font-size: 12px;
      font-family: "Avenir";
    }
  }
`;
