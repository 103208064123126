import styled from "styled-components";
export const PackageInfoWrapper = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  .package-info {
    &-header {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &-pasydy-logo {
      height: 64px;
    }
    &-title {
      color: #22335d;
      font-size: 18px;
      font-weight: 900;
      font-family: "Avenir";
      margin-bottom: 10px;
    }
    &-days-types {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &-days {
      background-color: #2ba5c1;
      font-size: 14px;
      padding: 5px 20px;
      border-radius: 25px;
    }
    &-dates {
      display: flex;
      gap: 50px;
      margin-bottom: 10px;
    }
    &-box {
      display: flex;
      flex-direction: column;
      &-title {
        color: #1e3260;
        font-size: 12px;
        font-weight: 900;
      }
      &-value {
        color: #485b7c;
        font-size: 15px;
        font-weight: 400;
      }
    }
    &-category {
      display: flex;
      margin: 10px 0px;
      flex-wrap: wrap;
      &-name {
        display: block;
        background-color: #229cff13;
        color: #118fe9;
        border: none;
      }
    }
  }
`;
