import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMatch } from "../../../App/common/typings";
import { listenToBookingFromFirestore } from "../../../App/firestore/firestoreService";
import { userFirestoreDoc } from "../../../App/hooks/useFirestoreDoc";
import { AdminPage } from "../../../App/layout/AdminPage/AdminPage";
import { listenToSelectedBooking } from "../../../App/redux/data/booking/bookingActions";
import { BookingDetails } from "../../../components/organisms";
interface IMatchParams {
  id: string;
}
export interface IBookingViewProps {
  match: IMatch<IMatchParams>;
}
const BookingView: React.FC<IBookingViewProps> = ({ match }) => {
  const bookingId = match.params.id;
  const dispatch = useDispatch();
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { loading, error } = useSelector((state: any) => state.async);
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: bookingId !== "new",
  });

  return (
    <AdminPage pageTitle={`Booking PFH-#${selectedBooking?.bookingId || "-"}`}>
      <BookingDetails
        booking={selectedBooking}
        loading={loading}
        error={error}
        bookingId={bookingId}
      />
    </AdminPage>
  );
};

export default BookingView;
