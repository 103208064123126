import styled from "styled-components";

// theme is now fully typed
export const AdminPageWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0px;
  display: flex;
  flex-direction: column;
  .admin-page {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    &-container {
      display: grid;
      grid-template-columns: auto 1fr;
      flex: 1;
      /* overflow: hidden; */
    }
    &-body {
      flex: 1;
      overflow: hidden;
      position: relative;
      &-wrapper {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0px;
      }

      &-content {
        overflow: auto;
        padding: 10px 30px;
        height: 100%;
        background-color: #fff;
      }
    }
    &-menu {
      width: 250px;
      background-color: #f8f8f8;
    }
  }
`;
