/* eslint-disable no-console */
import { CloseCircleOutlined } from "@ant-design/icons";
import { Slider } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { orderBy } from "lodash";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { ICategory, ICity, IHotel } from "../../../App/common/typings";
import { getYearMonthsOptions } from "../../../App/common/util/util";
import { Collapse, Form } from "../../atoms";
import { CheckboxGroup } from "../../molecules";
import { PackageFilterWrapper } from "./PackageListFrontEnd.style";
const { Panel } = Collapse;
const searchParams = new URLSearchParams({
  from: "all",
  to: "all",
  when: "all",
  category: "all",
  price: "0_5000",
});
const handelUrlChange = (
  name: string,
  value: CheckboxValueType[],
  url: string
) => {
  searchParams.set(name, `${value.join("_")}`);
  window.history.replaceState({}, "", `/${url}/${searchParams.toString()}`);
};
const marks = {
  0: "€0",
  5000: "€5000",
};
const departureCitiesOptions = [
  { label: "Λάρνακα", value: "LCA" },
  { label: "Πάφος", value: "PFO" },
];
export interface IPackageFilterProps {
  cities: ICity[];
  categories: ICategory[];
  isTabletOrMobile: boolean;
  showFilter: () => void;
  params: any;
  hotels: IHotel[];
  url: string;
}
const PackageFilter: React.FC<IPackageFilterProps> = ({
  cities,
  hotels,
  categories,
  isTabletOrMobile,
  showFilter,
  params,
  url,
}) => {
  const [priceRange, setPriceRange] = React.useState<[number, number]>([
    0, 5000,
  ]);
  const [departureCities, setDepartureCities] = React.useState<
    CheckboxValueType[]
  >([]);
  const [departureMonths, setDepartureMonths] = React.useState<
    CheckboxValueType[]
  >([]);
  const [destinations, setDestinations] = React.useState<CheckboxValueType[]>(
    []
  );
  const [selectedCategories, setSelectedCategories] = React.useState<
    CheckboxValueType[]
  >([]);
  const [selectedHotels, setSelectedHotels] = React.useState<
    CheckboxValueType[]
  >([]);
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const handelDepartureCitiesOnChange = (value: CheckboxValueType[]) => {
    handelUrlChange("from", value, url);
  };
  const handelDepartureMonthsOnChange = (value: CheckboxValueType[]) => {
    handelUrlChange("when", value, url);
  };
  const handelDestinationsChange = (value: CheckboxValueType[]) => {
    handelUrlChange("to", value, url);
  };
  const handelCategoriesChange = (value: CheckboxValueType[]) => {
    handelUrlChange("category", value, url);
  };
  const handelPriceRangeChange = (value: [number, number]) => {
    const [min, max] = value;
    handelUrlChange("price", [min, max], url);
  };
  const handelHotelsChange = (value: CheckboxValueType[]) => {
    handelUrlChange("hotels", value, url);
  };

  React.useEffect(() => {
    if (params.from) {
      setDepartureCities(params.from.split("_"));
      searchParams.set("from", params.from);
    }
    if (params.when) {
      setDepartureMonths(params.when.split("_"));
      searchParams.set("when", params.when);
    }
    if (params.category) {
      setSelectedCategories(params.category.split("_"));
      searchParams.set("category", params.category);
    }
    if (params.to) {
      setDestinations(params.to.split("_"));
      searchParams.set("to", params.to);
    }
    if (params.hotels) {
      setSelectedHotels(params.hotels.split("_"));
      searchParams.set("hotels", params.hotels);
    }
    params.price && setPriceRange(params.price.split("_"));
  }, []);
  React.useEffect(() => form.resetFields);
  return (
    <PackageFilterWrapper>
      <div className="package-filter-header">
        <span>
          <FormattedMessage id="MHa5hq" defaultMessage="Refine your search" />
        </span>
        {isTabletOrMobile && <CloseCircleOutlined onClick={showFilter} />}
      </div>
      <div className="package-filter-body">
        <Form form={form} layout="vertical" className="package-filter-form">
          <Collapse
            defaultActiveKey={["1", "5"]}
            expandIconPosition="right"
            bordered={false}
            className="package-filter-collapse"
          >
            <Panel
              header={formatMessage({
                id: "dM+p3/",
                defaultMessage: "From",
              })}
              key="1"
            >
              <Form.Item name="departure">
                <CheckboxGroup
                  plainOptions={departureCitiesOptions}
                  defaultCheckedList={departureCities}
                  onChange={handelDepartureCitiesOnChange}
                  checkAllName={formatMessage({
                    id: "7bDzLt",
                    defaultMessage: "From any where",
                  })}
                />
              </Form.Item>
            </Panel>
            <Panel
              header={formatMessage({
                id: "wc9wE6",
                defaultMessage: "Price Range",
              })}
              key="2"
            >
              <Form.Item name="priceRange" initialValue={priceRange}>
                <Slider
                  marks={marks}
                  range={{ draggableTrack: true }}
                  onAfterChange={handelPriceRangeChange}
                  max={5000}
                  step={10}
                  min={0}
                />
              </Form.Item>
            </Panel>
            <Panel
              header={formatMessage({
                id: "AxDOiG",
                defaultMessage: "Months",
              })}
              key="3"
            >
              <Form.Item name="month">
                <CheckboxGroup
                  plainOptions={getYearMonthsOptions()}
                  defaultCheckedList={departureMonths}
                  onChange={handelDepartureMonthsOnChange}
                  checkAllName={formatMessage({
                    id: "HXZ5wy",
                    defaultMessage: "Any Time",
                  })}
                />
              </Form.Item>
            </Panel>
            <Panel
              header={formatMessage({
                id: "VKb1MS",
                defaultMessage: "Categories",
              })}
              key="4"
            >
              <Form.Item name="categories">
                <CheckboxGroup
                  plainOptions={categories.map((category) => ({
                    value: category.code,
                    label: category.name,
                  }))}
                  defaultCheckedList={selectedCategories}
                  onChange={handelCategoriesChange}
                  checkAllName={formatMessage({
                    id: "ToO3/h",
                    defaultMessage: "Any",
                  })}
                />
              </Form.Item>
            </Panel>
            <Panel
              header={formatMessage({
                defaultMessage: "Top Hotels",
                id: "8FlWuc",
              })}
              key="41"
            >
              <Form.Item name="hotels">
                <CheckboxGroup
                  plainOptions={hotels.map((hotel) => ({
                    value: hotel.id,
                    label: hotel.name,
                  }))}
                  defaultCheckedList={selectedHotels}
                  onChange={handelHotelsChange}
                />
              </Form.Item>
            </Panel>
            <Panel
              header={formatMessage({
                id: "BzQPM+",
                defaultMessage: "Destination",
              })}
              key="5"
            >
              <Form.Item name="destinations">
                <CheckboxGroup
                  plainOptions={orderBy(cities, ["name"]).map((city) => ({
                    value: city.code,
                    label: city.name,
                  }))}
                  defaultCheckedList={destinations}
                  onChange={handelDestinationsChange}
                  checkAllName={formatMessage({
                    id: "gnXrq0",
                    defaultMessage: "To any where",
                  })}
                />
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </div>
    </PackageFilterWrapper>
  );
};

export default PackageFilter;
