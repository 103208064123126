/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import {
  HomePageWrapper,
  ThingsToDohWrapper,
} from "../HotelPage/HotelPage.style";

const loadScript = (callback: any) => {
  const existingScript = document.getElementById("vScript");
  const existingScript2 = document.getElementById("vScript2");
  const existingScript3 = document.getElementById("vScript3");
  if (!existingScript || !existingScript2 || !existingScript3) {
    const script = document.createElement("script");
    script.setAttribute("id", "vScript");
    script.async = true;
    script.src =
      "//partner.vtrcdn.com/js/console.js?v=release_WEB_3.3.0-20160323.007.c6bf61c5";
    document.head.appendChild(script);
    const script2 = document.createElement("script");
    script2.setAttribute("id", "vScript2");
    script2.async = true;
    script2.src =
      "//partner.vtrcdn.com/modules/widgets/js/initWidget.js?v=release_WEB_3.3.0-20160323.007.c6bf61c5";
    document.head.appendChild(script2);

    const script3 = document.createElement("script");
    script3.setAttribute("id", "vScript3");
    script3.async = true;
    script3.src =
      "//partner.vtrcdn.com/modules/widgets/js/pasWidget.js?v=release_WEB_3.3.0-20160323.007.c6bf61c5";
    document.head.appendChild(script3);
    script3.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

const ThingsToDoPage: React.FC = () => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    loadScript(() => {
      setLoaded(true);
    });
  });

  loaded &&
    // @ts-ignore
    window.initViatorWidgetDiv("8a3ae113", "viatorWidgetDivParams_8a3ae113");
  return (
    <HomePageWrapper>
      <div className="scroll-full">
        <div className="standard-home-container">
          <div className="standard-full">
            <div className="">
              <div className=" full-height keep-scroll">
                <div className="bgimg bg-hotel"></div>
                <div className="heading">
                  <div className="flights-search-header">
                    Save time & money
                    <br />
                    <span className="or-color">
                      You wont find it cheaper online or anywhere else!
                    </span>
                  </div>
                  <ThingsToDohWrapper id="things-todo-search">
                    <div
                      id="viatorWidgetDivParams_8a3ae113"
                      style={{ display: "none" }}
                    >
                      <div id="viatorWidgetDiv_8a3ae113_action">
                        https://www.partner.viator.com/widgets/pas.jspa
                      </div>
                      <div id="viatorWidgetDiv_8a3ae113_serverHost">
                        https://www.partner.viator.com
                      </div>
                      <div id="viatorWidgetDiv_8a3ae113_widgetID">8a3ae113</div>
                      <div id="viatorWidgetDiv_8a3ae113_setLocale">en</div>
                      <div id="viatorWidgetDiv_8a3ae113_PUID">17014</div>
                      <div id="viatorWidgetDiv_8a3ae113_title">
                        Find things to do
                      </div>
                      <div id="viatorWidgetDiv_8a3ae113_destinationID">0</div>
                      <div id="viatorWidgetDiv_8a3ae113_width">200</div>
                      <div id="viatorWidgetDiv_8a3ae113_btnColour">FFFFFF</div>
                      <div id="viatorWidgetDiv_8a3ae113_btnBGColour">
                        054D66
                      </div>
                      <div id="viatorWidgetDiv_8a3ae113_SUBPUID"></div>
                    </div>
                    <div id="viatorWidgetDiv_8a3ae113"></div>
                  </ThingsToDohWrapper>
                </div>
                <div className="space-footer"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <img src="/assets/gov_logos.jpg" alt="logos" />
        </div>
      </div>
    </HomePageWrapper>
  );
};

export default ThingsToDoPage;
