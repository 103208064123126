import styled from "styled-components";
export const PackageHotelItemWrapper = styled.div`
  display: grid;
  gap: 10px;
  margin-top: 24px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  padding: 15px 15px;
  grid-template-columns: 300px 1fr;
  .package-hotel-item {
    &-hotel {
      display: flex;
      gap: 25px;
      margin-bottom: 10px;
      padding: 0 10px;
    }
    &-body {
      width: 100%;
      overflow: hidden;
    }
    &-select {
      flex: 1;
      text-align: right;
      padding: 10px;
      button {
        background-color: #0027ca;
        color: #fff;
        width: 120px;
        height: 32px;
        font-size: 14px;
        @media (max-width: 1200px) {
          width: 100%;
          margin-top: 10px;
        }
      }
    }
    &-prices {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 10px;
    }
    &-is-selected {
      background-color: #00ca54 !important;
    }
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
    padding: 0;
  }
`;
