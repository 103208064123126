export const CREATE_PACKAGE = "CREATE_PACKAGE";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const LISTEN_TO_PACKAGE_CHAT = "LISTEN_TO_PACKAGE_CHAT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const LISTEN_TO_SELECTED_PACKAGE = "LISTEN_TO_SELECTED_PACKAGE";
export const CLEAR_SELECTED_PACKAGE = "CLEAR_SELECTED_PACKAGE";
export const CLEAR_PACKAGES = "CLEAR_PACKAGES";
export const SET_FILTER = "SET_FILTER";
export const SET_START_DATE = "SET_START_DATE";
export const RETAIN_STATE = "RETAIN_STATE";
export const LISTEN_TO_PACKAGE_FLIGHTS = "LISTEN_TO_PACKAGE_FLIGHTS";
export const LISTEN_TO_PACKAGE_DESTINATIONS = "LISTEN_TO_PACKAGE_DESTINATIONS";
export const LISTEN_TO_PACKAGE_DESTINATIONS_IMAGES =
  "LISTEN_TO_PACKAGE_DESTINATIONS_IMAGES";
