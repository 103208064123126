import React from "react";
import { Space } from "../../atoms";
import { TableHeaderWrapper } from "./TableHeader.style";
export interface ITableHeaderProps {
  children: React.ReactNode;
}
export const TableHeader: React.FC = ({ children }) => (
  <TableHeaderWrapper>
    <Space style={{ display: "flex" }} align="start">
      {children}
    </Space>
  </TableHeaderWrapper>
);
