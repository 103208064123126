/* eslint-disable no-console */
import React from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Popconfirm, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Space, Table } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { IHotel, IPackageHotel } from "../../../App/common/typings";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";
import { openModal } from "../../../App/redux/data/modals/modalReducer";
import {
  deletePackageHotelInFirestore,
  updatePackageHotelInFirestore,
} from "../../../App/firestore/firestoreService";
import { mealTypes } from "../../../App/enums";

export interface IPackageHotelListProps {
  packageId: string;
  cityId: string;
  loading: boolean;
}

export const PackageHotelList: React.FC<IPackageHotelListProps> = ({
  packageId,
  cityId,
  loading,
}) => {
  const dispatch = useDispatch();
  const { selectedPackage } = useSelector((state: any) => state.packages);

  const handelAddPackageHotel = () => {
    dispatch(
      openModal({
        modalType: "AddPackageHotelForm",
        modalProps: { title: "Add Hotel To Package", packageId, cityId },
      })
    );
  };
  const handelEditPackageHotel = (hotelPackage: IPackageHotel) => {
    dispatch(
      openModal({
        modalType: "AddPackageHotelForm",
        modalProps: {
          title: "Edit PackageHotel",
          packageId,
          cityId,
          hotelPackage,
          isEdit: true,
        },
      })
    );
  };
  const handelDeletePackageHotel = async (hotel: IPackageHotel) => {
    await deletePackageHotelInFirestore(packageId, hotel.id);
  };
  const handleHotelChangeStatus =
    (hotel: IPackageHotel) => async (checked: boolean) => {
      await updatePackageHotelInFirestore(packageId, {
        ...hotel,
        status: checked,
      });
    };
  const columnsPackageHotelList = [
    {
      title: "Hotel",
      dataIndex: "hotel",
      key: "hotel",
      render: (v: IHotel, record: IPackageHotel) => (
        <Link to="#" onClick={() => handelEditPackageHotel(record)}>
          {v.name || "-"}
        </Link>
      ),
    },
    {
      title: "Single",
      dataIndex: "singleRoomPrice",
      key: "singleRoomPrice",
      render: (v: number) => (v ? `€ ${v}` : "-"),
    },
    {
      title: "Double",
      dataIndex: "doubleRoomPrice",
      key: "doubleRoomPrice",
      render: (v: number) => (v ? `€ ${v}` : "-"),
    },
    {
      title: "Triple",
      dataIndex: "tripleRoomPrice",
      key: "tripleRoomPrice",
      render: (v: number) => (v ? `€ ${v}` : "-"),
    },
    {
      title: "Quadruple",
      dataIndex: "quadrupleRoomPrice",
      key: "quadrupleRoomPrice",
      render: (v: number) => (v ? `€ ${v}` : "-"),
    },
    {
      title: "Quadruple",
      dataIndex: "quadrupleRoomPrice",
      key: "quadrupleRoomPrice",
      render: (v: number) => (v ? `€ ${v}` : "-"),
    },
    {
      title: "First Child",
      dataIndex: "firstChildPrice",
      key: "firstChildPrice",
      render: (v: number) => (v ? `€ ${v}` : "-"),
    },
    {
      title: "Second  Child",
      dataIndex: "secondChildPrice",
      key: "secondChildPrice",
      render: (v: number) => (v ? `€ ${v}` : "-"),
    },
    {
      title: "Meals Type",
      dataIndex: "mealsType",
      key: "mealsType",
      render: (v: string) => mealTypes[v]?.label || "-",
    },
    {
      title: "Active",
      dataIndex: "status",
      key: "status",
      render: (v: boolean, record: IPackageHotel) => (
        <Switch checked={v} onChange={handleHotelChangeStatus(record)} />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (v: any, record: IPackageHotel) => (
        <Space size="middle">
          <Link to="#" onClick={() => handelEditPackageHotel(record)}>
            <EditOutlined />
          </Link>
          <Popconfirm
            title="Are you sure？"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handelDeletePackageHotel(record)}
          >
            <Button type="link">
              {" "}
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <TableHeader>
        <Button
          type="primary"
          onClick={handelAddPackageHotel}
          block
          icon={<PlusOutlined />}
        >
          Add Hotel
        </Button>
      </TableHeader>
      <Table
        scroll={{ x: 1300 }}
        columns={columnsPackageHotelList}
        dataSource={
          selectedPackage?.hotels?.filter(
            (hotel: IPackageHotel) => hotel.cityId === cityId
          ) || []
        }
        loading={loading}
        rowKey="id"
      />
    </div>
  );
};
