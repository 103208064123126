import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React from "react";
import { notification } from "antd";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
} from "../../atoms";
import { IPackage } from "../../../App/common/typings";
const { Option } = Select;
export interface ICabin {
  id: number;
  name: string;
  singleCabin: number;
  doubleCabin: number;
  TripleCabin: number;
  firstChild: number;
  secondChild: number;
}
export interface ICruise {
  id: number;
  packageId?: number;
  cruiseId?: number;
  departureDate?: string;
  arrivalDate?: string;
  sailingPort?: string;
  arrivalPort?: string;
  sortId?: number;
  cabins?: ICabin[];
}
export interface ICruisesDetailsProps {
  packageId: string;
}
export const PackageCruisesDetails: React.FC<ICruisesDetailsProps> = ({
  packageId,
}) => {
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    try {
      const values: IPackage = await form.validateFields();
      // update({
      //   cruises: values.cruises || [],
      //   cruiseLine: values.cruiseLine || "",
      //   cabins: values.cabins || [],
      // });
      notification.success({
        message: `update`,
        description: `va ${values}`,
        placement: "bottomLeft",
      });
    } catch (errorInfo) {
      notification.error({
        message: `Error`,
        description: `Field ${errorInfo}`,
        placement: "bottomLeft",
      });
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Cruise Line"
          name="cruiseLine"
          rules={[{ required: true, message: "Missing Cruise Line" }]}
        >
          <Input placeholder="Cruise Line" />
        </Form.Item>
        <Divider>Cruises</Divider>
        <Form.List name="cruises">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    label="Departure Date Time"
                    name={[name, "departureDateTime"]}
                    fieldKey={[name, "departureDateTime"]}
                    rules={[
                      {
                        required: true,
                        message: "Missing Departure Date Time",
                      },
                    ]}
                  >
                    <DatePicker showTime placeholder="Select Date and Time" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Sailing Port"
                    name={[name, "sailingPort"]}
                    fieldKey={[name, "sailingPort"]}
                    rules={[
                      { required: true, message: "Missing Sailing Port" },
                    ]}
                  >
                    <Input placeholder="Sailing Port" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Arrival Date Time"
                    name={[name, "ArrivalDateTime"]}
                    fieldKey={[name, "ArrivalDateTime"]}
                    rules={[
                      { required: true, message: "Missing Arrival Date Time" },
                    ]}
                  >
                    <DatePicker
                      showTime
                      placeholder="Select Arrival Date Time"
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Arrival Port"
                    name={[name, "ArrivalPort"]}
                    fieldKey={[name, "ArrivalPort"]}
                    rules={[
                      { required: true, message: "Missing Arrival Port" },
                    ]}
                  >
                    <Input placeholder="Arrival Port" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Cruise
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider>Cabins Prices</Divider>
        <Form.List name="cabins">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    label="Category"
                    name={[name, "Cabin Category"]}
                    fieldKey={[name, "Cabin Category"]}
                    rules={[
                      { required: true, message: "Missing Cabin Category" },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select a Cabin Category"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      // filterOption={(input, option) =>
                      //   // option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      <Option value="jack">Jack</Option>
                      <Option value="lucy">Lucy</Option>
                      <Option value="tom">Tom</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Single cabin"
                    name={[name, "Single cabin"]}
                    fieldKey={[name, "Single cabin"]}
                    rules={[
                      { required: true, message: "Missing Single cabin" },
                    ]}
                  >
                    <Input prefix="€" placeholder="Single cabin" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Double cabin"
                    name={[name, "Double cabin"]}
                    fieldKey={[name, "Double cabin"]}
                    rules={[
                      { required: true, message: "Missing Double cabin" },
                    ]}
                  >
                    <Input prefix="€" placeholder="Double cabin" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Triple cabin"
                    name={[name, "Triple cabin"]}
                    fieldKey={[name, "Triple cabin"]}
                    rules={[
                      { required: true, message: "Missing Triple cabin" },
                    ]}
                  >
                    <Input prefix="€" placeholder="Triple cabin" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="First Child"
                    name={[name, "First Child"]}
                    fieldKey={[name, "First Child"]}
                    rules={[{ required: true, message: "Missing First Child" }]}
                  >
                    <Input prefix="€" placeholder="First Child" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    label="Second Child"
                    name={[name, "Second Child"]}
                    fieldKey={[name, "Second Child"]}
                    rules={[
                      { required: true, message: "Missing Second Child" },
                    ]}
                  >
                    <Input prefix="€" placeholder="Second Child" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Cabin
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Space>
      </Form>
    </div>
  );
};
