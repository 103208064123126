import styled from "styled-components";
export const CartItemWrapper = styled.div`
  border-top: 1px solid #e9eef2;
  display: flex;
  position: relative;
  padding: 10px 0px;
  font-family: "Avenir";
  align-items: center;
  .cart-item {
    &-info {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    &-title {
      color: #485b7c;
      font-size: 15px;
      font-weight: 900;
    }
    &-passenger {
      color: #485b7c;
      font-size: 15px;
    }
    &-include {
      color: #4bac94;
      font-size: 14px;
    }
    &-price {
      color: #485b7c;
      font-size: 15px;
      font-weight: 900;
      /* margin-top: 25px; */
    }
    &-close {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 0;
    }
  }
`;
