import styled from "styled-components";

export const PassengersWrapper = styled.div`
  overflow: hidden;
  .passengers {
    &-splitter {
      border-bottom: 1px dashed #22335d;
      margin-bottom: 20px;
      padding-bottom: 30px;
    }
    &-body {
      width: 100%;
      overflow-x: auto;
      background-color: #ffffff;
    }
    &-container {
      display: grid;
      grid-template-columns: repeat(5, minmax(130px, 1fr));
      border-bottom: 1px solid #f0f2f5;
    }
    &-field {
      padding: 10px;
      p {
        margin: 0;
      }
    }
    &-label {
      padding: 10px;
      background-color: #f2f4f7;
      color: #346ff1;
    }
    &-no-data {
      margin: 0px;
      padding: 20px;
      text-align: center;
      background-color: #f0f2f5;
      font-size: 14px;
      color: #f32100;
      text-transform: uppercase;
    }
  }
`;
