import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dataFromSnapshot } from "../firestore/firestoreService";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../redux/data/async/asyncReducer";

export interface IUserFirestoreDoc {
  query?: string;
  docId: string;
  data?: (data: any) => void;
  deps?: any[];
}
export const userFirestoreDoc: any = ({
  query,
  data,
  deps,
  shouldExecute = true,
}: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!shouldExecute) return;
    dispatch(asyncActionStart());
    const unsubscribe = query().onSnapshot(
      (snapshot: any) => {
        dispatch(data(dataFromSnapshot(snapshot)));
        dispatch(asyncActionFinish());
      },
      (error: any) => dispatch(asyncActionError(error))
    );
    return () => {
      unsubscribe();
    };
  }, deps);
};
