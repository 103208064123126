/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { orderBy } from "lodash";
import BookingList from "../../../components/organisms/BookingList/BookingList";
import { AdminPage } from "../../../App/layout/AdminPage/AdminPage";
import { userFirestoreCollection } from "../../../App/hooks/userFirestoreCollection";
import {
  deleteBookingInFirestore,
  fetchBookingsFromFirestore,
  fetchCitiesFromFirestore,
} from "../../../App/firestore/firestoreService";
import { listenToBookings } from "../../../App/redux/data/booking/bookingActions";
import { IQueryConfigArgs } from "../../../App/common/typings";
import { IBooking } from "../../../../functions/src/email/types";
import { listenToCities } from "../../../App/redux/data/city/cityActions";
const defaultSorting: IQueryConfigArgs = {
  type: "filter",
  name: "paymentStatus",
  order: "desc",
  opr: "!=",
  value: "PENDING",
};
const Bookings: React.FC = () => {
  const history = useHistory();
  const { bookings } = useSelector((state: any) => state.bookings);
  const loading = useSelector((state: any) => state.async);
  const [packageSorting, setPackageSorting] = React.useState<{
    name: string;
    order: any;
  }>({ name: "bookingId", order: "desc" });
  const dispatch = useDispatch();

  userFirestoreCollection({
    query: () => fetchBookingsFromFirestore([defaultSorting]),
    data: listenToBookings,
    deps: [dispatch],
  });
  userFirestoreCollection({
    query: () =>
      fetchCitiesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCities,
    deps: [dispatch],
  });
  const handelFilterBooking = () => {
    console.log("filterCities");
  };
  const handelSearchBooking = () => {
    console.log("handelSearchCity");
  };
  const handelAddBooking = () => {
    history.push("/packages");
  };
  const handelDeleteBooking = async (id: string) => {
    await deleteBookingInFirestore(id);
  };
  const handelSortingChange = (name: string, order: any) => {
    setPackageSorting({
      order,
      name,
    });
  };
  return (
    <AdminPage pageTitle="Booking List">
      <BookingList
        loading={loading.loading}
        bookings={orderBy<IBooking[]>(
          bookings,
          [packageSorting.name],
          [packageSorting.order]
        )}
        filterBooking={handelFilterBooking}
        searchBooking={handelSearchBooking}
        addBooking={handelAddBooking}
        deleteBooking={handelDeleteBooking}
        sorting={handelSortingChange}
      ></BookingList>
    </AdminPage>
  );
};

export default Bookings;
