/* eslint-disable no-console */
import React from "react";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Popconfirm, Rate } from "antd";
import { Input, Select, Space, Table } from "../../atoms";
import { Button } from "../../atoms/Button/Button";
import { IAddress, ICity, IHotel } from "../../../App/common/typings";
import { TableHeader } from "../../molecules/TableHeader/TableHeader";
import { formatReadableCity } from "../../../App/common/util/util";

export interface IHotelListProps {
  hotels: IHotel[];
  loading: boolean;
  cities: ICity[];
  searchHotel: (search: string) => void;
  filterCity: (cityId: string) => void;
  addHotel: () => void;
  deleteHotel: (id: string) => void;
  sorting: (name: string, order: any) => void;
}

export const HotelList: React.FC<IHotelListProps> = ({
  hotels,
  addHotel,
  deleteHotel,
  loading,
  cities,
  searchHotel,
  filterCity,
  sorting,
}) => {
  const handelOnSearch = (value: string) => {
    searchHotel(value);
  };
  const columnsHotelList = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: true,
      sorter: true,
      render: (v: string, record: any) => (
        <Link to={`/admin/hotel/${record.id}`}>{v}</Link>
      ),
    },
    {
      title: "Rating",
      dataIndex: "starRating",
      key: "starRating",
      sorter: true,
      render: (v: number) => <Rate disabled defaultValue={v} />,
    },
    {
      title: "Country",
      dataIndex: "address",
      key: "address",
      render: (v: IAddress) => formatReadableCity(v.city),
    },
    {
      title: "Top Hotel",
      dataIndex: "topHotel",
      key: "topHotel",
      render: (v: boolean) => (v ? "Active" : "Not Active"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: boolean) => (v ? "Active" : "Not Active"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (v: any, record: IHotel) => (
        <Space size="middle">
          <Link to={`/admin/hotel/${record.id}`}>
            <EditOutlined /> Edit
          </Link>
          <Popconfirm
            title="Are you sure？"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => deleteHotel(record?.id || "0")}
          >
            <Button type="link">
              {" "}
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const handleTableChange = (pagination: any, filters: any, sort: any) => {
    if (sort.order === "descend") {
      sorting(sort.columnKey, "desc");
    }
    if (sort.order === "ascend") {
      sorting(sort.columnKey, "asc");
    }
  };
  return (
    <div>
      <TableHeader>
        <Button type="primary" onClick={addHotel} block icon={<PlusOutlined />}>
          Add Hotel
        </Button>
        <Input.Search
          placeholder="Search Hotel"
          onSearch={handelOnSearch}
          style={{ flex: 1 }}
          allowClear
        />
        <Select
          showSearch
          allowClear
          style={{ width: 200 }}
          placeholder="Select city"
          optionFilterProp="children"
          onChange={filterCity}
          filterOption={(input, option: any) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={cities.map(({ name, id }: any) => ({
            value: id,
            label: name,
          }))}
        />
      </TableHeader>
      <Table
        columns={columnsHotelList}
        dataSource={hotels}
        loading={loading}
        rowKey="id"
        scroll={{ x: 800, y: 500 }}
        onChange={handleTableChange}
      />
    </div>
  );
};
