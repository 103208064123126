import React from "react";
import { AdminPage } from "../../../App/layout/AdminPage/AdminPage";
import { ProviderDetails } from "../../../components/organisms";
const provider = {
  id: "1",
  companyName: "text",
  firstName: "text",
  lastName: "text",
  phoneNumber: "text",
  email: "text",
  website: "text",
  city: "text",
  country: "text",
  address: "text",
  createDate: "text",
  status: false,
  lastLogin: "text",
};
const ProviderView = () => (
  <AdminPage pageTitle="Provider Name">
    <ProviderDetails provider={provider} />
  </AdminPage>
);

export default ProviderView;
